import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoginPage from '../../components/login/LoginPage';
import * as authActions from '../../actions/authActions.js';

class LoginContainer extends Component {

  render() {
    const {
      changeValue,
      login,
      ...props
    } = this.props;
  
    const { email, password, redirectUrl } = this.props;

    return (
        <LoginPage
          {...props}
          onChange={changeValue}
          onLogin={() => {
            login(email, password).then( response => {
              this.props.history.push(redirectUrl);
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    error: state.auth.error,
    email: state.auth.email,
    password: state.auth.password,
    isTryingToLogin: state.auth.isTryingToLogin,
    isLoggedIn: state.auth.isLoggedIn,
    redirectUrl: state.auth.redirect
  };
}

function mapDispatchToProps(dispatch) {
  return {
      login: (email, password) => dispatch(authActions.login(email, password)),
      changeValue: (name,value) => dispatch(authActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(LoginContainer);