import React, { Component } from 'react';
import { connect } from 'react-redux';
import SessionPage from '../../components/dashboard/SessionsPage';
import * as sessionActions from '../../actions/sessionActions';

class SessionContainer extends Component {

  render() {
    const {
        fetchSessions,
        searchSessions,
        removeSession,
        changeValue,
      ...props
    } = this.props;
  
    return (
        <SessionPage
          {...props}
          onChange={changeValue}
          fetchSessions={(page) => 
            fetchSessions(page)
          }
          searchSessions={(query, page) =>
            searchSessions(query, page)
          }
          removeSession={(id) =>
            removeSession(id).then(response => {
              fetchSessions()
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: state.sessions.page,
    totalPages: state.sessions.totalPages,
    sessions: state.sessions.sessions,
    isFetching: state.sessions.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(sessionActions.changeValue(name, value)),
    fetchSessions: (page = 0) => dispatch(sessionActions.sessions(page)),
    searchSessions: (query, page = 0) => dispatch(sessionActions.search(query, page)),
    removeSession: (id) => dispatch(sessionActions.remove(id))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(SessionContainer);