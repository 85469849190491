import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { PulseLoader } from "react-spinners";
import { MAIN_COLOR } from "../../constants/constants";

import EmployeeDefaultReport from "./EmployeeDefaultReport";
import EmployeeDevelopReport from "./EmployeeDevelopReport";

import TeamDefaultReport from "./TeamDefaultReport";
import TeamDevelopReport from "./TeamDevelopReport";
import TeamDeviationReport from "./TeamDeviationReport";

class ReportPage extends Component {
    componentWillMount() {
        const { onFetch, match } = this.props;
        onFetch(match.params.code);
    }

    renderReportDoesNotExists() {
        const { t } = this.props;
        return (
            <div className="absolute-center">
                <h1 className="center">{t("report.doesNotExist")}</h1>
            </div>
        );
    }

    render() {
        const { isFetching, report } = this.props;
        if (isFetching) {
            if (report === null && !isFetching) {
                return this.renderReportDoesNotExists();
            }
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
        if (report === null) {
            return this.renderReportDoesNotExists();
        }

        switch (report.type) {
            case 0:
                switch (report.level) {
                    default:
                    case 0:
                        return <EmployeeDefaultReport {...this.props} />;
                    case 1:
                        return <TeamDefaultReport {...this.props} />;
                    case 2:
                    //return <DepartmentDefaultReport {...this.props} />
                    case 3:
                    //return <CompanyDefaultReport {...this.props} />
                }
                break;
            case 1:
                switch (report.level) {
                    default:
                    case 0:
                        return <EmployeeDevelopReport {...this.props} />;
                    case 1:
                        return <TeamDevelopReport {...this.props} />;
                    case 2:
                    //return <DepartmentDefaultReport {...this.props} />
                    case 3:
                    //return <CompanyDefaultReport {...this.props} />
                }
                break;
            case 2:
                switch (report.level) {
                    default:
                    case 0:
                    // return <EmployeeDefaultReport {...this.props} />
                    case 1:
                    // return <TeamDefaultReport {...this.props} />
                    case 2:
                    //return <DepartmentDefaultReport {...this.props} />
                    case 3:
                    //return <CompanyDefaultReport {...this.props} />
                }
                break;
            case 3:
                switch (report.level) {
                    default:
                    case 0:
                    // return <EmployeeDefaultReport {...this.props} />
                    case 1:
                    // return <TeamDefaultReport {...this.props} />
                    case 2:
                    //return <DepartmentDefaultReport {...this.props} />
                    case 3:
                    //return <CompanyDefaultReport {...this.props} />
                }
                break;
            case 4:
                switch (report.level) {
                    default:
                    case 0:
                    // return <EmployeeDefaultReport {...this.props} />
                    case 1:
                        return <TeamDeviationReport {...this.props} />;
                    case 2:
                    //return <DepartmentDefaultReport {...this.props} />
                    case 3:
                    //return <CompanyDefaultReport {...this.props} />
                }
                break;
        }
        return <EmployeeDefaultReport {...this.props} />;
    }
}
export default withTranslation("translation")(ReportPage);
