import * as types from '../constants/actionTypes.js';
import * as authApi from '../api/authApi';

/**
 * Login actions
 */
export const changeValue = (name, value) => ({
    type: types.LOGIN_FORM_CHANGE_VALUE,
    name,
    value
});

export const startLogin = () => ({
    type: types.LOGIN_FORM_LOGIN_STARTED
});

export const loginSuccess = (result) => ({
    type: types.LOGIN_FORM_LOGIN_SUCCESS,
    ...result
});

export const loginFailure = (error) => ({
    type: types.LOGIN_FORM_LOGIN_FAILURE,
    ...error
});

export const login = (email, password) => {
    return (dispatch) => {
        dispatch(startLogin());

        const promise = authApi.login(email, password)
        
        promise.then(result => {
            dispatch(loginSuccess(result))
        }).catch(error => {
            dispatch(loginFailure(error))
        });

        return promise;
    }
};

/**
 * Reset request actions
 */
export const startResetRequest = () => ({
    type: types.RESET_REQUEST_FORM_RESET_REQUEST_STARTED
});

export const resetRequestSuccess = (result) => ({
    type: types.RESET_REQUEST_FORM_RESET_REQUEST_SUCCESS,
    ...result
});

export const resetRequestFailure = (error) => ({
    type: types.RESET_REQUEST_FORM_RESET_REQUEST_FAILURE,
    ...error
});

export const resetRequest = (email) => {
    return (dispatch) => {
        dispatch(startResetRequest());

        const promise = authApi.resetRequest(email)
        
        promise.then(result => {
            dispatch(resetRequestSuccess(result))
        }).catch(error => {
            dispatch(resetRequestFailure(error))
        });

        return promise;
    }
};

/**
 * Recover actions
 */
export const startRecover = () => ({
    type: types.RECOVER_FORM_RECOVER_STARTED
});

export const recoverSuccess = (result) => ({
    type: types.RECOVER_FORM_RECOVER_SUCCESS,
    ...result
});

export const recoverFailure = (error) => ({
    type: types.RECOVER_FORM_RECOVER_FAILURE,
    ...error
});

export const recover = (key, password, repeatpassword) => {
    return (dispatch) => {
        dispatch(startRecover());

        const promise = authApi.recover(key, password, repeatpassword)
        
        promise.then(result => {
            dispatch(recoverSuccess(result))
        }).catch(error => {
            dispatch(recoverFailure(error))
        });

        return promise;
    }
};

/**
 * Logout actions
 */
export const startLogout = () => ({
    type: types.LOGOUT_STARTED
});

export const logoutSuccess = (result) => ({
    type: types.LOGOUT_SUCCESS,
    ...result
});

export const logoutFailure = (error) => ({
    type: types.LOGOUT_FAILURE,
    ...error
});

export const logout = () => {
    return (dispatch) => {
        dispatch(startLogout());

        const promise = authApi.logout()
        
        promise.then(result => {
            dispatch(logoutSuccess(result))
        }).catch(error => {
            dispatch(logoutFailure(error))
        });

        return promise;
    }
};