import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class About extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector('.banner');

        if (banner != null) {
            banner.style.transform = 'translateY(' + top / 5 + 'px)';
        }
    }

    render() {
        return (
            <div>
                <section className="banner" style={{ backgroundImage: 'url(/images/wij.jpg)' }}>
                    <div className="absolute-center">
                        <h1>Over ons</h1>
                        <h2></h2>
                    </div>
                </section>
                <section className="about">
                    <div className="container">
                        <p></p>
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation("translation")(About);