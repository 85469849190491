import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class How extends Component {
    state = {
        tab: window.screen.width < 560 ? "" : "quickscan",
    };

    componentDidMount() {
        window.scrollTo(0, 0);

        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector(".banner");

        if (banner != null) {
            banner.style.transform = "translateY(" + top / 5 + "px)";
        }
    }

    renderDataAnalysis() {
        const { t } = this.props;
        return (
            <section
                className={"tab-section gray " + (this.state.tab === "data-analysis" ? "active" : "")}
                id="data-analysis"
            >
                <h1 className="center bold">{t("how.dataAnalyse.header")}</h1>
                <div className="steps">
                    <div className="step">
                        <div className="header">
                            <h1>{t("how.dataAnalyse.step1.header")}</h1>
                            <img src="/images/email.jpg" alt=""></img>
                        </div>
                        <div className="content">
                            <h3>{t("how.dataAnalyse.step1.description")}</h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="header">
                            <h1>{t("how.dataAnalyse.step2.header")}</h1>
                            <img src="/images/report.jpg" alt=""></img>
                        </div>
                        <div className="content">
                            <h3>{t("how.dataAnalyse.step2.description")}</h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    renderQuickscan() {
        const { t } = this.props;
        return (
            <section
                className={"tab-section gray " + (this.state.tab === "quickscan" ? "active" : "")}
                id="quickscan"
            >
                <h1 className="center bold">{t("how.quickscan.header")}</h1>
                <div className="steps">
                    <div className="step">
                        <div className="header">
                            <h1>{t("how.quickscan.step1.header")}</h1>
                            <img src="/images/email.jpg" alt=""></img>
                        </div>
                        <div className="content">
                            <h3>{t("how.quickscan.step1.description")}</h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="header">
                            <h1>{t("how.quickscan.step2.header")}</h1>
                            <img src="/images/form.jpg" alt=""></img>
                        </div>
                        <div className="content">
                            <h3>{t("how.quickscan.step2.description")}</h3>
                        </div>
                    </div>
                    <div className="step">
                        <div className="header">
                            <h1>{t("how.quickscan.step3.header")}</h1>
                            <img src="/images/report.jpg" alt=""></img>
                        </div>
                        <div className="content">
                            <h3>{t("how.quickscan.step3.description")}</h3>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

    render() {
        const { t } = this.props;
        const isMobile = window.screen.width < 560;
        return (
            <div>
                <section className="banner" style={{ backgroundImage: "url(/images/product.jpg)" }}>
                    <div className="absolute-center">
                        <h1>{t("how.header")}</h1>
                        <h2>{t("how.description")}</h2>
                    </div>
                </section>
                <section className="how" id="how">
                    <div className="container">
                        <div className="tabs">
                            <div
                                className={"tab " + (this.state.tab === "quickscan" ? "active" : null)}
                                onClick={(event) => {
                                    this.setState({
                                        tab: this.state.tab === "quickscan" && isMobile ? null : "quickscan",
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("how.quickscan")}
                            </div>
                            {isMobile ? this.renderQuickscan() : null}
                            <div
                                className={"tab " + (this.state.tab === "data-analysis" ? "active" : "")}
                                onClick={(event) => {
                                    this.setState({
                                        tab:
                                            this.state.tab === "data-analysis" && isMobile
                                                ? null
                                                : "data-analysis",
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("how.dataAnalyse")}
                            </div>
                            {isMobile ? this.renderDataAnalysis() : null}
                        </div>
                        {!isMobile ? (
                            <div>
                                {this.renderQuickscan()}
                                {this.renderDataAnalysis()}
                            </div>
                        ) : null}
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation("translation")(How);
