import * as types from '../constants/actionTypes.js';
import * as scanApi from '../api/scanApi';

/**
 * Scan actions
 */
export const changeValue = (name, value) => ({
    type: types.SCAN_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.SCAN_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.SCAN_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.SCAN_FORM_ADD_FAILURE,
    ...error
});

export const add = (name = '', company = '' , position = '', type = 0, description = '', categories = []) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = scanApi.addScan(name, company, position, type,description, categories);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const sendExample = (scan, name, email, message) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = scanApi.sendExample(scan, name, email, message);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
}

export const update = (id = '', name = '', company = '' , position = '', type = 0, description = '', categories = []) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = scanApi.updateScan(id, name, company, position, type, description, categories);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const startAnswer = () => ({
    type: types.SCAN_ANSWER_START
});

export const answerSuccess = (result) => ({
    type: types.SCAN_ANSWER_SUCCESS,
    ...result
});

export const answerFailure = (error) => ({
    type: types.SCAN_ANSWER_FAILURE,
    ...error
});

export const answer = (session = '', question = '', answer = '', description = '') => {
    return (dispatch) => {
        dispatch(startAnswer());

        const promise = scanApi.saveAnswer(session, question, answer, description);
        
        promise.then(result => {
            dispatch(answerSuccess(result))
        }).catch(error => {
            dispatch(answerFailure(error))
        });

        return promise;
    }
}

export const startGet = () => ({
    type: types.SCAN_GET_START
});

export const getSuccess = (result) => ({
    type: types.SCAN_GET_SUCCESS,
    scan: result
});

export const getFailure = (error) => ({
    type: types.SCAN_GET_FAILURE,
    ...error
});

export const scan = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = scanApi.getScan(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}
export const scanWithSession = (id, session) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = scanApi.getScanWithSession(id, session);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });
        
        return promise;
    }
}

export const scansFromSession = (session) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = scanApi.getScansFromSession(session);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const fetchScanFromSession = () => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = scanApi.getScanFromSession();
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = scanApi.deleteScan(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const startScansGet = () => ({
    type: types.SCANS_GET_START
});

export const getScansSuccess = (result) => ({
    type: types.SCANS_GET_SUCCESS,
    scans: [...result]
});

export const getScansFailure = (error) => ({
    type: types.SCANS_GET_FAILURE,
    ...error
});

export const scans = (page = 0) => {
    return (dispatch) => {
        dispatch(startScansGet());

        const promise = scanApi.getScans(page + 1);
        
        promise.then(result => {
            dispatch(getScansSuccess(result))
        }).catch(error => {
            dispatch(getScansFailure(error))
        });

        return promise;
    }
}

export const search = (query = '', page = 0) => {
    return (dispatch) => {
        dispatch(startScansGet());

        const promise = scanApi.searchScans(query, page + 1);
        
        promise.then(result => {
            dispatch(getScansSuccess(result))
        }).catch(error => {
            dispatch(getScansFailure(error))
        });

        return promise;
    }
}