import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

export default class Article extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    slugify(string) {
        const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;";
        const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------";
        const p = new RegExp(a.split("").join("|"), "g");

        return string
            .toString()
            .toLowerCase()
            .replace(/\s+/g, "-")
            .replace(p, (c) => b.charAt(a.indexOf(c)))
            .replace(/&/g, "-and-")
            .replace(/[^\w\-]+/g, "")
            .replace(/\-\-+/g, "-")
            .replace(/^-+/, "")
            .replace(/-+$/, "");
    }

    render() {
        const { article } = this.props;

        if (article) {
            return (
                <Link to={"/artikel/" + article.id + "/" + this.slugify(article.title)} className="preview">
                    <div className="image">
                        <img
                            src={
                                article.images[0]
                                    ? process.env.REACT_APP_API_URL + article.images[0].url
                                    : "/images/wij.jpg"
                            }
                        />
                    </div>
                    <div className="body">
                        <h1>{article.title}</h1>
                        <p>{article.description}</p>
                        <div className="date">{moment(article.updatedAt.date).format("DD/MM/YYYY")}</div>
                    </div>
                </Link>
            );
        }
        return null;
    }
}
