import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddPositionPage from '../../../components/dashboard/new/AddPositionPage';
import * as positionActions from '../../../actions/positionActions';

class AddPositionContainer extends Component {

  render() {
    const {
      fetchPosition,
      changeValue,
      create,
      update,
      ...props
    } = this.props;
    
    const {company, name} = props;

    return (
        <AddPositionPage
          {...props}
          onChange={changeValue}
          onCreate={() => {
            create(company, name).then( response => {
              this.props.history.push('/company/'+company);
            })
          }}
          onUpdate={() => {
            update(this.props.match.params.positionId, company, name).then( response => {
              this.props.history.push('/company/'+company);
            })
          }}
          fetchPosition={() => {
            fetchPosition(this.props.match.params.positionId)
          }}

        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.positions.isFetching,
    position: state.positions.selected,
    company: state.positions.company,
    name: state.positions.name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchPosition: (id) => dispatch(positionActions.position(id)),
    create: (company, name) => dispatch(positionActions.add(company, name)),
    update: (id, company, name) => dispatch(positionActions.update(id, company, name)),
    changeValue: (name, value) => dispatch(positionActions.changeValue(name, value))

  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddPositionContainer);