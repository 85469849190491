import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import { HorizontalBar } from 'react-chartjs-2'
import { MAIN_COLOR }  from '../../constants/constants' 
import { PulseLoader } from 'react-spinners';
import _ from 'underscore';

class TeamDevelopReport extends Component {

	constructor(props){
		super(props);
		this.state = {
			hasLoaded: false,
			answers: [],
			tab: 'results'
		}
	}

	arrayToFractileArray(array){
		var newArray = [];
		for (var i = array.length - 1; i >= 0; i--) {
			newArray[i] = 1;
		}
		return newArray;
	}

	calculatePercentage(fractile, from){
		return fractile / from * 100
	}

	compareOrder(a, b) {
		return a.order - b.order;
	}
	
	truncate(str, num) { 
		return str.length > num ? str.slice(0, num) + "..." : str;
	}

	average(arr) {
		return arr.reduce( ( p, c ) => p + c, 0 ) / arr.length
	}

	componentWillMount() {
		if(!this.state.hasLoaded && this.props.report != null){
			let categories = [];

			this.props.report.parts.forEach((part) => {
				part.session.scans.forEach((scan) => {
					scan.categories.forEach((category) => {
						categories.push(category);
					});		
				});
			});

			categories = _.uniq(categories, (category) => {
				return category.id;
			});

			let one = [];
			let two = [];
			let three = [];
			let four = [];
			let five = [];
			let six = [];
			let seven = [];
	
			categories.forEach((category, index) => {
				let answers = [];
	
				one[index] = 0;
				two[index] = 0;
				three[index] = 0;
				four[index] = 0;
				five[index] = 0;
				six[index] = 0;
				seven[index] = 0;

				this.props.report.parts.forEach((part) => {
					part.session.scans.forEach((scan) => {
						let partCategory = _.find(scan.categories, (partCategory) => {
							return partCategory.name === category.name
						});
						if (partCategory === null) {
							answers.push(0);
							return;
						}
		
						let questionAnswers = [];
						partCategory.questions.forEach((question) => {
							questionAnswers.push(this.average(question.answers.map((answer) => {
								return parseInt(answer.answer)
							})));
						});
						answers.push(this.average(questionAnswers));
					});
				});

				answers.forEach((answer) => {
					if(!isNaN(answer)) {
						if(answer <= 1){
							one[index] = one[index] + 1;
						} else if (answer > 1 && answer <= 2){
							two[index] = two[index] + 1;
						} else if (answer > 2 && answer <= 3){
							three[index] = three[index] + 1;
						} else if (answer > 3 && answer <= 4){
							four[index] = four[index] + 1;
						} else if (answer > 4 && answer <= 5){
							five[index] = five[index] + 1;
						} else if (answer > 5 && answer <= 6){
							six[index] = six[index] + 1;
						} else if (answer > 6 && answer <= 7){
							seven[index] = seven[index] + 1;
						}
					}
				});
			});

			this.setState({
				categories: categories,
				one: one,
				two: two,
				three: three,
				four: four,
				five: five,
				six: six,
				seven: seven,
				hasLoaded: true
			});
		}	
	}
	
	renderCategory(category, poorLabel = "Voor verbetering vatbaar", averageLabel = "Voldoende vaardig", goodLabel = "Zeer vaardig"){
		
		let one = [];
		let two = [];
		let three = [];
		let four = [];
		let five = [];
		let six = [];
		let seven = [];

		let labels = [];
		let questionAnswers = [];

		_.sortBy(category.questions, (o) => o.order).forEach((question) => {
			labels.push(this.truncate(question.question, 200));
		});

		this.props.report.parts.forEach((part) => {
			part.session.scans.forEach((scan) => {
				let partCategory = _.find(scan.categories, (partCategory) => {
					return partCategory.name === category.name
				});				
				_.sortBy(partCategory.questions, (o) => o.order).forEach((question, index) => {
					one[index] = 0;
					two[index] = 0;
					three[index] = 0;
					four[index] = 0;
					five[index] = 0;
					six[index] = 0;
					seven[index] = 0;

					if(!questionAnswers[index]) questionAnswers[index] = [];
					
					questionAnswers[index].push(this.average(question.answers.map((answer) => {
						return parseInt(answer.answer)
					})));

				});
			});
		});

		questionAnswers.forEach((questionAnswers, index) => {
			questionAnswers.forEach((answer) => {
				if (!isNaN(answer)) {
					if(answer <= 1){
						one[index] = one[index] + 1;
					} else if (answer > 1 && answer <= 2){
						two[index] = two[index] + 1;
					} else if (answer > 2 && answer <= 3){
						three[index] = three[index] + 1;
					} else if (answer > 3 && answer <= 4){
						four[index] = four[index] + 1;
					} else if (answer > 4 && answer <= 5){
						five[index] = five[index] + 1;
					} else if (answer > 5 && answer <= 6){
						six[index] = six[index] + 1;
					} else if (answer > 6 && answer <= 7){
						seven[index] = seven[index] + 1;
					}
				}
			})
		});

        var datasets = [{
            label: "Een",
			backgroundColor: "#8b0000",
			borderColor: "#8b0000",
            data: one
        }, {
			label: "Twee",
			backgroundColor: '#f65300',
			borderColor: '#f65300',
            data: two
        }, {
			label: "Drie",
			backgroundColor: '#ffa02a',
			borderColor: '#ffa02a',
            data: three
        }, {
			label: "Vier",
			backgroundColor: '#f6d108',
			borderColor: '#f6d108',
            data: four
        }, {
			label: "Vijf",
			backgroundColor: '#94c13c',
			borderColor: '#94c13c',
            data: five
        }];

        var data = {
			labels: labels,
            datasets: datasets
		};
		
		var options = {
			responsive: true,
			maintainAspectRatio: false,
			aspectRatio: 0,
			scales: {
				yAxes: [{
					display: true,
					stacked: true,
					ticks: {
						suggestedMin: 0,
						beginAtZero: true,
						max: this.props.report.parts.length
					}
				}],
				xAxes: [{ 
					stacked: true,
					ticks: {
						autoSkip: false,
						maxRotation: 30,
						minRotation: 30
					}
				}]
			}
		};

		return (
			<div className="report-group" key={category.key}>
				<div className="input-container catagory">
					<div className="input-group">
						<div className="catagory-name">{category.name}</div>
					</div>
				</div>
                <div className="data-set-section">
                    <div className="chart">
                        <HorizontalBar data={data} options={options} />
                    </div>
                </div>
			</div>
		);
	}

	renderSummary(categories, poorLabel = "Voor verbetering vatbaar", averageLabel = "Voldoende vaardig", goodLabel = "Zeer vaardig"){ 
		let labels = [];	

		var datasets = [{
            label: "Een",
			backgroundColor: "#8b0000",
			borderColor: "#8b0000",
            data: this.state.one
        }, {
			label: "Twee",
			backgroundColor: '#f65300',
			borderColor: '#f65300',
            data: this.state.two
        }, {
			label: "Drie",
			backgroundColor: '#ffa02a',
			borderColor: '#ffa02a',
            data: this.state.three
        }, {
			label: "Vier",
			backgroundColor: '#f6d108',
			borderColor: '#f6d108',
            data: this.state.four
        }, {
			label: "Vijf",
			backgroundColor: '#94c13c',
			borderColor: '#94c13c',
            data: this.state.five
        }];

		categories.forEach((category) => {
			labels.push(this.truncate(category.name, 60));
		})

        var data = {
			labels: labels,
            datasets: datasets
		};
		
		var options = {
			responsive: true,
			maintainAspectRatio: false,
			aspectRatio: 0,
			scales: {
				yAxes: [{
					stacked: true,
					maxBarThickness: 40,
					ticks: {
						suggestedMin: 0,
						beginAtZero: true,
						max: this.props.report.parts.length
					}
				}],
				xAxes: [{ 
					stacked: true,
					maxBarThickness: 10,

					ticks: {
						autoSkip: false,
						maxRotation: 45,
						minRotation: 45
					}
				}]
			}
		}

		return (
			<div className="report-group">
				<div className="input-container catagory">
					<div className="input-group">
						<div className="catagory-name">Algemeen</div>
					</div>
				</div>
				<div className="data-set-section">
					<div className="chart">
						<HorizontalBar data={data} options={options} />
					</div>
				</div>
			</div>
		);
	}

	render(){
		const { t, isFetching, report, } = this.props;
		if(this.props.isFetching || !this.state.hasLoaded) {
			if (this.props.report === null && !this.props.isFetching) {
				return (
					<div className='absolute-center'>
						<h1 className="center">Deze rapportage bestaat niet</h1>
					</div>
				);
			}
			return (
				<div className='content-loader'>
				  <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
				</div> 
			);
		}

		if (this.props.report === null || this.props.report.parts.length < 1) {
			return (
				<div className='absolute-center'>
					<h1 className="center">Deze rapportage bestaat niet</h1>
				</div>
			);
		}

		return (
			<div className="container">
				<div className="box scan">
					<form method="post" onSubmit={(e) => this.handleSubmit()} >
						<h1 className="center">Rapportage</h1>
						<h1 className="center big">Overzicht data-analyse.</h1>
						<h2 className="center">Overzicht van het team.</h2>
						<div className="scope">
							<h3>Omvang van de data-analyse:</h3>
							<div className="item">
								<div className="name"><b>{_.filter(this.props.report.parts, (part) => {
									return part.session.employee.isLeader === 0 && part.session.completed
								}).length}</b> van de <b>{_.filter(this.props.report.parts, (part) => {
									return part.session.employee.isLeader === 0
								}).length}</b> medewerkers hebben de scan ingevuld.</div>
							</div>
						</div>
						<div className={"tab-section " + (this.state.tab === 'results' ? 'active' : '')} >
							{this.renderSummary(this.state.categories)}
							<div className="divider">
								<h1 className="center">Op gedragsniveau.</h1>
							</div>
							{this.state.categories.map((category, index) => {
								return this.renderCategory(category);
                            })}
						</div>
					</form>
				</div>
			</div>
		);
	}
}
export default withTranslation("translation")(TeamDevelopReport);