import React, { Component } from "react";
import { HorizontalBar } from "react-chartjs-2";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";
import _ from "underscore";
import { isArray } from "util";

export default class TeamDeviationReport extends Component {
    state = {
        hasLoaded: false,
        answers: [],
        tab: "results",
    };

    arrayToFractileArray(array) {
        var newArray = [];
        for (var i = array.length - 1; i >= 0; i--) {
            newArray[i] = 1;
        }
        return newArray;
    }

    calculatePercentage(fractile, from) {
        return (fractile / from) * 100;
    }

    compareOrder(a, b) {
        return a.order - b.order;
    }

    truncate(str, num) {
        return str.length > num ? str.slice(0, num) + "..." : str;
    }

    average(arr) {
        return arr.reduce((p, c) => p + c, 0) / arr.length;
    }

    componentWillMount() {
        if (!this.state.hasLoaded && this.props.report != null) {
            let categories = [];
            let sessions = [];

            let leadersCompleted = 0;
            let bothCompleted = 0;

            this.props.report.parts.forEach((part) => {
                sessions.push({ session: part.session.id, isLeader: part.session.employee.isLeader });

                part.session.scans.forEach((scan) => {
                    scan.categories.forEach((category) => {
                        categories.push(category);
                    });
                });
            });

            const leaders = _.filter(sessions, (session) => session.isLeader).map(
                (session) => session.session
            );

            this.props.report.parts.forEach((part) => {
                let questionCount = 0;
                let respondentSessionIds = [];
                part.session.scans.forEach((scan) => {
                    scan.categories.forEach((category) => {
                        category.questions.forEach((question) => {
                            questionCount += 1;
                            question.answers.forEach((answer) => {
                                respondentSessionIds.push(answer.respondentSession);
                            });
                        });
                    });
                    const lCompleted =
                        _.filter(
                            leaders,
                            (leader) =>
                                _.filter(respondentSessionIds, (session) => session === leader).length ==
                                questionCount
                        ).length === leaders.length;
                    if (lCompleted) {
                        leadersCompleted += 1;
                        if (part.session.completed) {
                            bothCompleted += 1;
                        }
                    }
                });
            });

            categories = _.uniq(categories, (category) => {
                return category.id;
            });

            let results = {
                employee: {
                    answers: [],
                    one: [],
                    two: [],
                    three: [],
                    four: [],
                    five: [],
                    six: [],
                    seven: [],
                },
                leader: {
                    answers: [],
                    one: [],
                    two: [],
                    three: [],
                    four: [],
                    five: [],
                    six: [],
                    seven: [],
                },
                difference: [],
            };

            categories.forEach((category, index) => {
                let employeesAnswers = [];
                let leadersAnswers = [];

                results.employee.answers[index] = [];
                results.employee.one[index] = 0;
                results.employee.two[index] = 0;
                results.employee.three[index] = 0;
                results.employee.four[index] = 0;
                results.employee.five[index] = 0;
                results.employee.six[index] = 0;
                results.employee.seven[index] = 0;

                results.leader.answers[index] = [];
                results.leader.one[index] = 0;
                results.leader.two[index] = 0;
                results.leader.three[index] = 0;
                results.leader.four[index] = 0;
                results.leader.five[index] = 0;
                results.leader.six[index] = 0;
                results.leader.seven[index] = 0;

                this.props.report.parts.forEach((part) => {
                    if (part.session.employee.isLeader === 0) {
                        part.session.scans.forEach((scan) => {
                            let partCategory = _.find(scan.categories, (partCategory) => {
                                return partCategory.name === category.name;
                            });
                            if (partCategory === null) {
                                employeesAnswers.push(0);
                                leadersAnswers.push(0);
                                return;
                            }

                            let employeeAnswers = [];
                            let leaderAnswers = [];

                            partCategory.questions.forEach((question) => {
                                let employeeAnswer = this.average(
                                    _.filter(question.answers, (answer) => {
                                        return !_.filter(sessions, (session) => session.isLeader === 1)
                                            .map((session) => session.session)
                                            .includes(answer.respondentSession);
                                    }).map((answer) => {
                                        return parseInt(answer.answer);
                                    })
                                );
                                let leaderAnswer = this.average(
                                    _.filter(question.answers, (answer) => {
                                        return _.filter(sessions, (session) => session.isLeader === 1)
                                            .map((session) => session.session)
                                            .includes(answer.respondentSession);
                                    }).map((answer) => {
                                        return parseInt(answer.answer);
                                    })
                                );

                                results.employee.answers[index].push(employeeAnswer);
                                results.leader.answers[index].push(leaderAnswer);

                                if (!isNaN(employeeAnswer) && !isNaN(leaderAnswer)) {
                                    employeeAnswers.push(employeeAnswer);

                                    leaderAnswers.push(leaderAnswer);
                                }
                            });
                            employeesAnswers.push(this.average(employeeAnswers));
                            leadersAnswers.push(this.average(leaderAnswers));
                        });
                    }
                });

                employeesAnswers.forEach((answer) => {
                    results.difference[index] = answer;
                    if (!isNaN(answer)) {
                        if (answer <= 1) {
                            results.employee.one[index] = results.employee.one[index] + 1;
                        } else if (answer > 1 && answer <= 2) {
                            results.employee.two[index] = results.employee.two[index] + 1;
                        } else if (answer > 2 && answer <= 3) {
                            results.employee.three[index] = results.employee.three[index] + 1;
                        } else if (answer > 3 && answer <= 4) {
                            results.employee.four[index] = results.employee.four[index] + 1;
                        } else if (answer > 4 && answer <= 5) {
                            results.employee.five[index] = results.employee.five[index] + 1;
                        } else if (answer > 5 && answer <= 6) {
                            results.employee.six[index] = results.employee.six[index] + 1;
                        } else if (answer > 6 && answer <= 7) {
                            results.employee.seven[index] = results.employee.seven[index] + 1;
                        }
                    }
                });

                leadersAnswers.forEach((answer) => {
                    results.difference[index] = results.difference[index] - answer;
                    if (!isNaN(answer)) {
                        if (answer <= 1) {
                            results.leader.one[index] = results.leader.one[index] + 1;
                        } else if (answer > 1 && answer <= 2) {
                            results.leader.two[index] = results.leader.two[index] + 1;
                        } else if (answer > 2 && answer <= 3) {
                            results.leader.three[index] = results.leader.three[index] + 1;
                        } else if (answer > 3 && answer <= 4) {
                            results.leader.four[index] = results.leader.four[index] + 1;
                        } else if (answer > 4 && answer <= 5) {
                            results.leader.five[index] = results.leader.five[index] + 1;
                        } else if (answer > 5 && answer <= 6) {
                            results.leader.six[index] = results.leader.six[index] + 1;
                        } else if (answer > 6 && answer <= 7) {
                            results.leader.seven[index] = results.leader.seven[index] + 1;
                        }
                    }
                });
            });

            this.setState({
                categories: categories,
                employee: results.employee,
                leader: results.leader,
                difference: results.difference,
                sessions: sessions,
                leadersCompleted: leadersCompleted,
                bothCompleted: bothCompleted,
                hasLoaded: true,
            });
        }
    }

    renderCategory(category) {
        let labels = [];
        let sessions = [];

        let results = {
            employee: {
                answers: [],
                one: [],
                two: [],
                three: [],
                four: [],
                five: [],
                six: [],
                seven: [],
            },
            leader: {
                answers: [],
                one: [],
                two: [],
                three: [],
                four: [],
                five: [],
                six: [],
                seven: [],
            },
            difference: [],
        };

        let employeesAnswers = [];
        let leadersAnswers = [];

        _.sortBy(category.questions, (o) => o.order).forEach((question) => {
            labels.push(this.truncate(question.question, 200));
        });
        this.props.report.parts.forEach((part) => {
            sessions.push({ session: part.session.id, isLeader: part.session.employee.isLeader });
        });
        this.props.report.parts.forEach((part) => {
            if (part.session.employee.isLeader === 0) {
                part.session.scans.forEach((scan) => {
                    let partCategory = _.find(scan.categories, (partCategory) => {
                        return partCategory.name === category.name;
                    });
                    _.sortBy(partCategory.questions, (o) => o.order).forEach((question, index) => {
                        if (!isArray(results.employee.answers[index])) {
                            results.employee.answers[index] = [];
                            results.employee.one[index] = 0;
                            results.employee.two[index] = 0;
                            results.employee.three[index] = 0;
                            results.employee.four[index] = 0;
                            results.employee.five[index] = 0;
                            results.employee.six[index] = 0;
                            results.employee.seven[index] = 0;

                            results.leader.answers[index] = [];
                            results.leader.one[index] = 0;
                            results.leader.two[index] = 0;
                            results.leader.three[index] = 0;
                            results.leader.four[index] = 0;
                            results.leader.five[index] = 0;
                            results.leader.six[index] = 0;
                            results.leader.seven[index] = 0;
                        }

                        if (!employeesAnswers[index]) employeesAnswers[index] = [];
                        if (!leadersAnswers[index]) leadersAnswers[index] = [];

                        let employeeAnswer = this.average(
                            _.filter(question.answers, (answer) => {
                                return !_.filter(sessions, (session) => session.isLeader === 1)
                                    .map((session) => session.session)
                                    .includes(answer.respondentSession);
                            }).map((answer) => {
                                return parseInt(answer.answer);
                            })
                        );

                        let leaderAnswer = this.average(
                            _.filter(question.answers, (answer) => {
                                return _.filter(sessions, (session) => session.isLeader === 1)
                                    .map((session) => session.session)
                                    .includes(answer.respondentSession);
                            }).map((answer) => {
                                return parseInt(answer.answer);
                            })
                        );

                        if (!isNaN(employeeAnswer) && !isNaN(leaderAnswer)) {
                            employeesAnswers[index].push(employeeAnswer);
                            results.employee.answers[index].push(employeeAnswer);

                            leadersAnswers[index].push(leaderAnswer);
                            results.leader.answers[index].push(leaderAnswer);
                        }
                    });
                });
            }
        });

        employeesAnswers.forEach((employeeAnswers, index) => {
            employeeAnswers.forEach((answer) => {
                if (!isNaN(answer)) {
                    results.difference[index] = results.difference[index] + answer / 2;

                    if (answer <= 1) {
                        results.employee.one[index] = results.employee.one[index] + 1;
                    } else if (answer > 1 && answer <= 2) {
                        results.employee.two[index] = results.employee.two[index] + 1;
                    } else if (answer > 2 && answer <= 3) {
                        results.employee.three[index] = results.employee.three[index] + 1;
                    } else if (answer > 3 && answer <= 4) {
                        results.employee.four[index] = results.employee.four[index] + 1;
                    } else if (answer > 4 && answer <= 5) {
                        results.employee.five[index] = results.employee.five[index] + 1;
                    } else if (answer > 5 && answer <= 6) {
                        results.employee.six[index] = results.employee.six[index] + 1;
                    } else if (answer > 6 && answer <= 7) {
                        results.employee.seven[index] = results.employee.seven[index] + 1;
                    }
                }
            });
        });

        leadersAnswers.forEach((leaderAnswers, index) => {
            leaderAnswers.forEach((answer) => {
                if (!isNaN(answer)) {
                    results.difference[index] = results.difference[index] - answer / 2;
                    if (answer <= 1) {
                        results.leader.one[index] = results.leader.one[index] + 1;
                    } else if (answer > 1 && answer <= 2) {
                        results.leader.two[index] = results.leader.two[index] + 1;
                    } else if (answer > 2 && answer <= 3) {
                        results.leader.three[index] = results.leader.three[index] + 1;
                    } else if (answer > 3 && answer <= 4) {
                        results.leader.four[index] = results.leader.four[index] + 1;
                    } else if (answer > 4 && answer <= 5) {
                        results.leader.five[index] = results.leader.five[index] + 1;
                    } else if (answer > 5 && answer <= 6) {
                        results.leader.six[index] = results.leader.six[index] + 1;
                    } else if (answer > 6 && answer <= 7) {
                        results.leader.seven[index] = results.leader.seven[index] + 1;
                    }
                }
            });
        });

        var employeesDataset = [
            {
                label: "Een",
                backgroundColor: "#8b0000",
                borderColor: "#8b0000",
                data: results.employee.one,
            },
            {
                label: "Twee",
                backgroundColor: "#f65300",
                borderColor: "#f65300",
                data: results.employee.two,
            },
            {
                label: "Drie",
                backgroundColor: "#ffa02a",
                borderColor: "#ffa02a",
                data: results.employee.three,
            },
            {
                label: "Vier",
                backgroundColor: "#f6d108",
                borderColor: "#f6d108",
                data: results.employee.four,
            },
            {
                label: "Vijf",
                backgroundColor: "#94c13c",
                borderColor: "#94c13c",
                data: results.employee.five,
            },
        ];

        var leadersDataset = [
            {
                label: "Een",
                backgroundColor: "#8b0000",
                borderColor: "#8b0000",
                data: results.leader.one,
            },
            {
                label: "Twee",
                backgroundColor: "#f65300",
                borderColor: "#f65300",
                data: results.leader.two,
            },
            {
                label: "Drie",
                backgroundColor: "#ffa02a",
                borderColor: "#ffa02a",
                data: results.leader.three,
            },
            {
                label: "Vier",
                backgroundColor: "#f6d108",
                borderColor: "#f6d108",
                data: results.leader.four,
            },
            {
                label: "Vijf",
                backgroundColor: "#94c13c",
                borderColor: "#94c13c",
                data: results.leader.five,
            },
        ];

        var employeesData = {
            labels: labels,
            datasets: employeesDataset,
        };

        var leadersData = {
            labels: labels,
            datasets: leadersDataset,
        };

        var options = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 0,
            scales: {
                yAxes: [
                    {
                        display: true,
                        stacked: true,
                        ticks: {
                            suggestedMin: 0,
                            beginAtZero: true,
                            max: _.filter(
                                this.props.report.parts,
                                (part) => part.session.employee.isLeader === 0
                            ).length,
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                        ticks: {
                            autoSkip: false,
                            maxRotation: 30,
                            minRotation: 30,
                        },
                    },
                ],
            },
        };

        return (
            <div className="report-group" key={category.key}>
                <div className="input-container catagory">
                    <div className="input-group">
                        <div className="catagory-name">{category.name}</div>
                    </div>
                </div>
                <div className="data-set-section">
                    <h2 className="center">Medewerkers</h2>
                    <h3 className="center">
                        Verdeling van hoe medewerkers zich gescoord hebben op "{category.name}" in; "Zeer
                        vaardig", "Voldoende vaardig" en "Voor verbetering vatbaar"
                    </h3>
                    <div className="input-container">
                        <div className="chart">
                            <HorizontalBar data={employeesData} options={options} />
                        </div>
                    </div>
                </div>
                <div className="data-set-section">
                    <h2 className="center">Leidinggevende</h2>
                    <h3 className="center">
                        Verdeling van hoe de leidinggevende de medewerkers gescoord heeft op "{category.name}"
                        in; "Zeer vaardig", "Voldoende vaardig" en "Voor verbetering vatbaar"
                    </h3>
                    <div className="input-container">
                        <div className="chart">
                            <HorizontalBar data={leadersData} options={options} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderSummary(
        categories,
        poorLabel = "Voor verbetering vatbaar",
        alrightLabel = "Voldoende vaardig",
        goodLabel = "Zeer vaardig"
    ) {
        let labels = [];
        var employeesDataset = [
            {
                label: "Een",
                backgroundColor: "#8b0000",
                borderColor: "#8b0000",
                data: this.state.employee.one,
            },
            {
                label: "Twee",
                backgroundColor: "#f65300",
                borderColor: "#f65300",
                data: this.state.employee.two,
            },
            {
                label: "Drie",
                backgroundColor: "#ffa02a",
                borderColor: "#ffa02a",
                data: this.state.employee.three,
            },
            {
                label: "Vier",
                backgroundColor: "#f6d108",
                borderColor: "#f6d108",
                data: this.state.employee.four,
            },
            {
                label: "Vijf",
                backgroundColor: "#94c13c",
                borderColor: "#94c13c",
                data: this.state.employee.five,
            },
        ];

        var leadersDataset = [
            {
                label: "Een",
                backgroundColor: "#8b0000",
                borderColor: "#8b0000",
                data: this.state.leader.one,
            },
            {
                label: "Twee",
                backgroundColor: "#f65300",
                borderColor: "#f65300",
                data: this.state.leader.two,
            },
            {
                label: "Drie",
                backgroundColor: "#ffa02a",
                borderColor: "#ffa02a",
                data: this.state.leader.three,
            },
            {
                label: "Vier",
                backgroundColor: "#f6d108",
                borderColor: "#f6d108",
                data: this.state.leader.four,
            },
            {
                label: "Vijf",
                backgroundColor: "#94c13c",
                borderColor: "#94c13c",
                data: this.state.leader.five,
            },
        ];

        categories.forEach((category) => {
            labels.push(this.truncate(category.name, 60));
        });

        let employeeData = {
            labels: labels,
            datasets: employeesDataset,
        };

        let leaderData = {
            labels: labels,
            datasets: leadersDataset,
        };

        let options = {
            responsive: true,
            maintainAspectRatio: false,
            aspectRatio: 0,
            scales: {
                yAxes: [
                    {
                        stacked: true,
                        maxBarThickness: 40,
                        ticks: {
                            suggestedMin: 0,
                            beginAtZero: true,
                            max: _.filter(this.props.report.parts, (part) => {
                                return part.session.employee.isLeader === 0;
                            }).length,
                        },
                    },
                ],
            },
        };
        return (
            <div className="report-group">
                <div className="input-container catagory">
                    <div className="input-group">
                        <div className="catagory-name">Algemeen</div>
                    </div>
                </div>
                <div className="data-set-section">
                    <h2 className="center">Medewerkers</h2>
                    <h3 className="center">
                        Verdeling van hoe medewerkers zich gescoord hebben in; "Zeer vaardig", "Voldoende
                        vaardig" en "Voor verbetering vatbaar"
                    </h3>
                    <div className="chart main">
                        <HorizontalBar data={employeeData} options={options} />
                    </div>
                </div>
                <div className="data-set-section">
                    <h2 className="center">Leidinggevende</h2>
                    <h3 className="center">
                        Verdeling van hoe de leidinggevende de medewerkers gescoord heeft in; "Zeer vaardig",
                        "Voldoende vaardig" en "Voor verbetering vatbaar"
                    </h3>
                    <div className="chart main">
                        <HorizontalBar data={leaderData} options={options} />
                    </div>
                </div>
            </div>
        );
    }

    renderDifference(label, answers) {
        return (
            <div className="input-container report" key={label}>
                <div className="input-group four">
                    <div className="question">{label}</div>
                </div>
                <div className="input-group three">
                    {answers.map((answer, index) => {
                        var percentage = this.calculatePercentage(answer, 5);
                        var style = { width: percentage + "%" };
                        return (
                            <div
                                key={answer + index}
                                className={"line " + (index === 0 ? "employee" : "leader")}
                                style={style}
                            ></div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.isFetching || !this.state.hasLoaded) {
            if (this.props.report === null && !this.props.isFetching) {
                return (
                    <div className="absolute-center">
                        <h1 className="center">Deze rapportage bestaat niet</h1>
                    </div>
                );
            }
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }

        if (this.props.report === null || this.props.report.parts.length < 1) {
            return (
                <div className="absolute-center">
                    <h1 className="center">Deze rapportage bestaat niet</h1>
                </div>
            );
        }

        return (
            <div className="container">
                <div className="box scan">
                    <form method="post" onSubmit={(e) => this.handleSubmit()}>
                        <h1 className="center">Rapportage</h1>
                        <h1 className="center big">Overzicht data-analyse.</h1>
                        <h2 className="center">
                            Overzicht van verschillen tussen medewerkers en leidinggevende.
                        </h2>
                        <div className="scope">
                            <h3>Omvang van de data-analyse:</h3>
                            <div className="item">
                                <div className="name">
                                    <b>
                                        {
                                            _.filter(this.props.report.parts, (part) => {
                                                return (
                                                    part.session.employee.isLeader === 0 &&
                                                    part.session.completed
                                                );
                                            }).length
                                        }
                                    </b>{" "}
                                    van de{" "}
                                    <b>
                                        {
                                            _.filter(this.props.report.parts, (part) => {
                                                return part.session.employee.isLeader === 0;
                                            }).length
                                        }
                                    </b>{" "}
                                    medewekers hebben de scan ingevuld.
                                </div>
                            </div>
                            <div className="item">
                                <div className="name">
                                    <b>{this.state.leadersCompleted}</b> scans heeft de leidinggevende
                                    ingevuld.
                                </div>
                            </div>
                            <div className="item">
                                <div className="name">
                                    <b>{this.state.bothCompleted}</b> scans zijn zowel door de leidinggevende
                                    als de medewerker ingevuld.
                                </div>
                            </div>
                            <p>
                                In dit overzicht wordt, per competentie en op gedragsniveau, vergeleken hoe de
                                medewerkers van het team zichzelf inschatten met hoe de leidinggevende de
                                deelnemers van het team inschat.
                            </p>
                            <p>
                                De vergelijking is gebaseerd op de input van medewerkers en de leidinggevende,
                                als beide de scan hebben ingevuld. In de scan is een 7-puntsschaal gehanteerd
                            </p>
                        </div>
                        <div className={"tab-section " + (this.state.tab === "results" ? "active" : "")}>
                            <div className="divider">
                                <h1 className="center">Op competentieniveau.</h1>
                                <h3 className="center">
                                    De onderstaande weergave is een samenvoeging van de gedragingen per
                                    competentie.
                                </h3>
                            </div>
                            {this.renderSummary(this.state.categories)}
                            <div className="divider">
                                <h1 className="center">Op gedragsniveau.</h1>
                                <h3 className="center">
                                    De onderstaande weergave is de weergave op gedragsniveau per competentie
                                </h3>
                            </div>
                            {this.state.categories.map((category) => {
                                return this.renderCategory(category);
                            })}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
