import * as types from '../constants/actionTypes.js';
import { CACHE_DATA } from '../constants/constants.js';
import * as cache from '../utils/cache.js';
import moment from 'moment';

const logoutState = {
    isFetching: false,
    error: '',

    //User
    hasSession: false,
    sessionToken: '',
    firstname: '',
    lastname: '',
    token: '',
    birthday: new Date(),
    education: { value: '' },
    supervisorEmail: '',
    isSupervisor: false,

    //Admin
    sessions: [],
    session: null,
    scans: [],
    page: 0,
    totalPages: 0,
    selected: null,
    company: null,
    team: null,
    type: null,
    instruction: '',
    startDate: new Date(),
    endDate: new Date(),
    positions: [],
};

const initialState = (cache.read(CACHE_DATA) || {}).sessions || logoutState;

export default function sessions(state = initialState, action) {
    let cacheData = cache.read(CACHE_DATA) || {};

    switch (action.type) {
        case types.SESSION_FORM_CHANGE_VALUE:
            state = {
                ...state,
                error: '',
                [action.name]: action.value,
            };
            cacheData.sessions = state;
            cache.write(CACHE_DATA, cacheData);
            return state;
        case types.SESSION_FORM_ADD_START:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case (types.SESSION_FORM_ADD_FAILURE, types.SESSION_FORM_ADD_SUCCESS):
            state = {
                ...state,
                isFetching: false,
            };
            return state;
        case types.SESSION_FROM_SESSION_STARTED:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case types.SESSION_FORM_SESSION_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                hasSession: true,
                sessionToken: action[0].token,
                isSupervisor: action[1].isLeader,
                session: action[2]
            };
            cacheData.sessions = state;
            cache.write(CACHE_DATA, cacheData);
            return state;
        case types.SESSION_FORM_SESSION_FAILURE:
            state = {
                ...state,
                isFetching: false,
                hasSession: false,
                error:
                    action.message === 'This token does not exist'
                        ? 'Deze sessie bestaat niet.'
                        : 'Deze sessie verlopen.',
            };
            return state;
        case types.SESSION_FORM_SESSION_FAILURE_SILENT:
            state = {
                ...state,
                isFetching: false,
                hasSession: false,
            };
            return state;
        case types.SESSION_GET_START:
            state = {
                ...state,
                isFetching: true,
                selected: null,
            };
            return state;
        case types.SESSION_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                selected: action.session,
            };
            return state;
        case types.SESSIONS_GET_START:
            state = {
                ...state,
                isFetching: true,
                sessions: [],
            };
            return state;
        case types.SESSIONS_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                sessions: action.sessions,
                totalPages: action.sessions.length > 0 ? action.sessions[0].totalResults / 20 : 0,
            };
            return state;
        case types.SESSION_STOP_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                hasSession: false,
            };
            cache.clear(CACHE_DATA);
            return logoutState;
        default:
            return state;
    }
}
