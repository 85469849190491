import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function getEmployees() {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/users")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function saveEmployees(users) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/save/users")
            .set("Accept", "application/json")
            .field("users", JSON.stringify(users))
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
