import React, { Component } from 'react';
import { connect } from 'react-redux';
import DashboardPage from '../../components/dashboard/DashboardPage';
import * as sessionActions from '../../actions/sessionActions';

class DashboardContainer extends Component {

  render() {
    const {

      ...props
    } = this.props;
  
    return (
        <DashboardPage
          {...props}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {

  };
}

function mapDispatchToProps(dispatch) {
  return {

  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(DashboardContainer);