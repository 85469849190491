import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function login(email = "", password = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/login")
            .set("Accept", "application/json")
            .withCredentials()
            .field("username", email)
            .field("password", password)
            .end(function (err, res) {
                if (err || !res.ok || !JSON.parse(res.text).logged_in) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function resetRequest(email = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/forgot/" + email)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function recover(key = "", password = "", repeatpassword = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/recover")
            .set("Accept", "application/json")
            .withCredentials()
            .field("key", key)
            .field("password", password)
            .field("repeatpassword", repeatpassword)
            .end(function (err, res) {
                if (err || !res.ok || !JSON.parse(res.text).logged_in) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function isLoggedIn() {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/isLoggedIn")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok || !JSON.parse(res.text).logged_in) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function logout() {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/logout")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
