import React, { Component } from "react";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import $ from "jquery";
import jQuery from "jquery";
import { NavLink, Link } from "react-router-dom";

class Header extends Component {
    transformSvgToInline() {
        $('img[src$=".svg"]').each(function () {
            var $img = jQuery(this);
            var imgURL = $img.attr("src");
            var attributes = $img.prop("attributes");

            $.get(
                imgURL,
                function (data) {
                    var $svg = jQuery(data).find("svg");

                    $svg = $svg.removeAttr("xmlns:a");

                    $.each(attributes, function () {
                        $svg.attr(this.name, this.value);
                    });

                    $img.replaceWith($svg);
                },
                "xml"
            );
        });
    }

    componentDidUpdate() {
        this.transformSvgToInline();
    }

    componentDidMount() {
        this.transformSvgToInline();
        $('nav a[href^="/' + window.location.pathname.split("/")[1] + '"]').addClass("active");

        this.handleScroll(this);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            header = document.querySelector("header");

        if (top > 0) {
            if (!header.classList.contains("fixed")) {
                header.classList.add("fixed");
            }
        } else {
            header.classList.remove("fixed");
        }
    }

    render() {
        const { t, match, isLoggedIn, hasSession } = this.props;
        var dashboardLinks = ["/dashboard", "/scans", "/companies", "/employees", "/teams", "/logout"];
        var transparentLinks = [
            "/contact",
            "/over-ons",
            "/producten",
            "/frequent-asked-questions",
            "/hoe-het-werkt",
            "/wat-het-kost",
            "/wat-wij-bieden",
            "/artikel",
        ];
        var classes = "";

        if (transparentLinks.some((link) => match.path.includes(link)) || match.path === "/") {
            classes += "transparent";
        }

        const menuButton = () => {
            var tabbar = document.getElementById("tabbar"),
                header = document.querySelector("header"),
                menuButton = document.querySelector(".menu-button");
            if (tabbar.classList.contains("active")) {
                tabbar.classList.remove("active");
                header.classList.remove("active");
                menuButton.classList.remove("active");
            } else {
                tabbar.classList.add("active");
                header.classList.add("active");
                menuButton.classList.add("active");
            }
        };

        if (isLoggedIn || dashboardLinks.includes(match.path)) {
            return (
                <div>
                    <header>
                        <div className="container">
                            <Link to="/">
                                <div className="logo">
                                    <img src="/images/logo.svg" />
                                </div>
                            </Link>
                            <div className="menu-button" onClick={menuButton}>
                                <img className="closed" src="/images/menu.svg" />
                                <img className="open" src="/images/cross.svg" />
                            </div>
                            <div className="tabbar" id="tabbar">
                                <ul>
                                    <NavLink activeClassName="active" to="/sessions">
                                        <li>{t("header.sessions")}</li>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/reports">
                                        <li>{t("header.reports")}</li>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/scans">
                                        <li>{t("header.scans")}</li>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/companies">
                                        <li>{t("header.companies")}</li>
                                    </NavLink>
                                    <NavLink activeClassName="active" to="/employees">
                                        <li>{t("header.employees")}</li>
                                    </NavLink>
                                    <li className="more">{t("header.more")}</li>
                                    <ul className="dropdown">
                                        <NavLink activeClassName="active" to="/articles">
                                            <li>{t("header.articles")}</li>
                                        </NavLink>
                                        <NavLink activeClassName="active" to="/logout">
                                            <li>{t("header.logout")}</li>
                                        </NavLink>
                                    </ul>
                                </ul>
                            </div>
                        </div>
                    </header>
                </div>
            );
        } else if (hasSession) {
            return (
                <div>
                    <header className={classes}>
                        <div className="container">
                            <Link to="/">
                                <div className="logo">
                                    <img src="/images/logo.svg" />
                                </div>
                            </Link>
                            <div className="menu-button" onClick={menuButton}>
                                <img src="/images/menu.svg" />
                            </div>
                            <div className="tabbar" id="tabbar">
                                <ul>
                                    <NavLink activeClassName="active" to="/instruction">
                                        <li>{t("header.instruction")}</li>
                                    </NavLink>
                                    <NavLink activeClassName="active" className="white" to="/contact">
                                        <li>{t("header.contact")}</li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                    </header>
                </div>
            );
        } else if (
            document.location.pathname.includes("report") ||
            document.location.pathname.includes("data-analysis") ||
            document.location.pathname.includes("start/")
        ) {
            return (
                <div>
                    <header>
                        <div className="container">
                            <Link to="/">
                                <div className="logo">
                                    <img src="/images/logo.svg" />
                                </div>
                            </Link>
                            <div className="tabbar website">
                                <ul>
                                    <NavLink activeClassName="" to="/">
                                        <li>{t("header.back")}</li>
                                    </NavLink>
                                </ul>
                            </div>
                        </div>
                    </header>
                </div>
            );
        }

        return (
            <div>
                <header className={classes}>
                    <div className="container">
                        <Link to="/">
                            <div className="logo">
                                <img src="/images/logo.svg" />
                            </div>
                        </Link>
                        <div className="menu-button" onClick={menuButton}>
                            <img className="closed" src="/images/menu.svg" />
                            <img className="open" src="/images/cross.svg" />
                        </div>
                        <div className="tabbar website" id="tabbar">
                            <ul>
                                <NavLink activeClassName="active" to="/producten">
                                    <li>{t("header.products")}</li>
                                </NavLink>
                                <NavLink activeClassName="active" to="/hoe-het-werkt">
                                    <li>{t("header.howItWorks")}</li>
                                </NavLink>
                                <NavLink activeClassName="active" to="/wat-het-kost">
                                    <li>{t("header.prices")}</li>
                                </NavLink>

                                <NavLink activeClassName="active" to="/frequent-asked-questions">
                                    <li>{t("header.faq")}</li>
                                </NavLink>
                                <NavLink activeClassName="active" to="/contact">
                                    <li>{t("header.contact")}</li>
                                </NavLink>
                                <NavLink activeClassName="active" to="/demo">
                                    <li className="focus">{t("header.requestDemo")}</li>
                                </NavLink>
                            </ul>
                        </div>
                    </div>
                </header>
            </div>
        );
    }
}
function mapStateToProps(state, ownProps) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        hasSession: state.sessions.hasSession,
        ...ownProps,
    };
}

function mapDispatchToProps() {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(Header));
