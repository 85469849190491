import React from 'react';
import { Link } from 'react-router-dom';

export default class ForgotRequestSuccessPage extends React.Component {

	constructor(props){
		super(props);
	}

	render(){
        
		return (
			<div className="container">
				<div className="box success absolute-center">
					<form method="post">
						<h1 className="center big">Succes!</h1>
						<h2 className="center">Wachtwoord reset is aangevraagd. U zult een e-mail ontvangen met daarin de reset link.</h2>
					</form>
				</div>
			</div>
		)
	}
} 