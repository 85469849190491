import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddTeamPage from '../../../components/dashboard/new/AddTeamPage';
import * as teamActions from '../../../actions/teamActions';

class AddTeamContainer extends Component {

  render() {
    const {
      fetchTeam,
      changeValue,
      create,
      update,
      ...props
    } = this.props;

    return (
        <AddTeamPage
          {...props}
          onChange={changeValue}
          onCreate={(members) => {
            create(this.props.name ,this.props.company, members).then( response => {
              this.props.history.push('/company/' + this.props.company);
            });
          }}
          onUpdate={(members) => {
            update(this.props.match.params.teamId, this.props.name ,this.props.company, members).then( response => {
              this.props.history.push('/company/' + this.props.company);
            });
          }}
          fetchTeam={() => 
            fetchTeam(this.props.match.params.teamId)
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.teams.isFetching,
    team: state.teams.selected,
    name: state.teams.name,
    company: state.teams.company,
    members: state.teams.members
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchTeam: (id) => dispatch(teamActions.team(id)),
    create: (name, company, members) => dispatch(teamActions.add(name, company, members)),
    update: (id, name, company, members) => dispatch(teamActions.update(id, name, company, members)),
    changeValue: (name, value) => dispatch(teamActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddTeamContainer);