import React, { Component } from 'react';
import { connect } from 'react-redux';
import Home from '../../components/home/Home';
import * as blogActions from '../../actions/blogActions';

class HomeContainer extends Component {

  render() {
    const {
        fetchArticles,
        ...props
    } = this.props;

    return (
        <Home
          {...props}
          fetchArticles={(page) => 
            fetchArticles(page)
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        page: state.blogs.page,
        totalPages: state.blogs.totalPages,
        articles: state.blogs.articles,
        isFetching: state.blogs.isFetching
  } ;
}

function mapDispatchToProps(dispatch) {
    return {
        fetchArticles: (page) => dispatch(blogActions.articles(page))
    }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(HomeContainer);