import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportsPage from '../../components/dashboard/ReportsPage';
import * as reportActions from '../../actions/reportActions';

class ReportsContainer extends Component {

  render() {
    const {
      changeValue,
        fetchReports,
        searchReports,
        removeReport,
      ...props
    } = this.props;
  
    return (
        <ReportsPage
          {...props}
          onChange={changeValue}
          fetchReports={(page) => 
            fetchReports(page)
          }
          searchReports={(query, page) =>
            searchReports(query, page)
          }
          removeReport={(id) =>
            removeReport(id).then(response => {
              fetchReports()
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: state.reports.page,
    totalPages: state.reports.totalPages,
    reports: state.reports.reports,
    isFetching: state.reports.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(reportActions.changeValue(name, value)),
    fetchReports: (page) => dispatch(reportActions.reports(page)),
    searchReports: (query, page) => dispatch(reportActions.search(query, page)),
    removeReport: (id) => dispatch(reportActions.remove(id))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ReportsContainer);