import React, { Component } from 'react';
import { connect } from 'react-redux';
import SuccessPage from '../../components/scan/SuccessPage';
import * as authActions from '../../actions/authActions';

class SuccessContainer extends Component {

  render() {
    const {
      changeValue,
      session,
      ...props
    } = this.props;
  
    const { email, password } = props;
    return (
        <SuccessPage
          {...props}
          onChange={changeValue}
          onStop={() => 
            session(email, password).then(response => {
              this.props.history.push('/dashboard')
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    error: state.auth.error,
    isSupervisor: state.sessions.isSupervisor
  };
}

function mapDispatchToProps(dispatch) {
  return {
      session: (email, password) => dispatch(authActions.login(email, password)),
      changeValue: (name,value) => dispatch(authActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(SuccessContainer);