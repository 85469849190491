import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import moment from "moment";

import Pager from "../ui/Pager";
import { MAIN_COLOR } from "../../constants/constants";

class SessionsPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            query: "",
        };

        this.typingCounter = null;
    }

    componentDidMount() {
        const { page } = this.props;
        this.load(page);
    }

    load(page = 0, query = "") {
        const { onChange, searchSessions, fetchSessions } = this.props;
        onChange("page", page);
        if (query != "") {
            searchSessions(query, page);
        } else {
            fetchSessions(page);
        }
    }

    renderSessions() {
        const { t, isFetching, sessions } = this.props;
        if (!isFetching) {
            if (sessions.length > 0) {
                return (
                    <div>
                        {sessions.map((session) => (
                            <li key={session.id}>
                                <div className="flex-container">
                                    <div className="three">{session.name === null ? "" : session.name}</div>
                                    <div className="four">
                                        {session.employee === null ? "" : session.employee.email}
                                    </div>
                                    <div className="three">
                                        {session.scans.length < 1 ? (
                                            "No scan"
                                        ) : session.scans.length > 1 ? (
                                            session.scans.length + " scans"
                                        ) : (
                                            <Link to={"/edit/scan/" + session.scans[0].id}>
                                                {session.scans[0].id}
                                            </Link>
                                        )}
                                    </div>
                                    <div className="two">
                                        {moment(session.startDate.date).format("D-M-Y")}
                                    </div>
                                    <div className="two">
                                        {moment(session.experationDate.date).format("D-M-Y")}
                                    </div>
                                    <div className="one">
                                        <Link to={"/edit/session/" + session.id}>{t("edit")}</Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={(event) => {
                                            if (session.canBeDeleted) {
                                                this.props.removeSession(session.id);
                                            }
                                        }}
                                    >
                                        <a>{session.canBeDeleted ? t("delete") : ""}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("sessions.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t, sessions, page } = this.props;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">{t("sessions.header")}</h1>
                    <Link to="/new/session">
                        <button className="new">{t("session.new")}</button>
                    </Link>
                    <div className="filter">
                        <div className="search">
                            <input
                                type="text"
                                placeholder={t("form.label.search")}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.setState({
                                        query: value,
                                    });
                                    this.load(this.state.query === "" ? 0 : page, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="three">{t("session.name")}</div>
                                    <div className="four">{t("session.email")}</div>
                                    <div className="three">{t("session.scan")}</div>
                                    <div className="two">{t("session.startDate")}</div>
                                    <div className="two">{t("session.endDate")}</div>
                                    <div className="one"></div>
                                    <div className="one"></div>
                                </div>
                            </li>
                            {this.renderSessions()}
                        </ul>
                    </div>
                </div>
                <Pager
                    {...this.props}
                    items={sessions}
                    onReload={(page) => {
                        this.load(page, this.state.query);
                    }}
                />
            </div>
        );
    }
}
export default withTranslation("translation")(SessionsPage);
