import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScansPage from '../../components/dashboard/ScansPage';
import * as scanActions from '../../actions/scanActions';

class ScansContainer extends Component {

  render() {
    const {
      changeValue,
      fetchScans,
      searchScans,
      deleteScan,
      ...props
    } = this.props;
  
    return (
        <ScansPage
          {...props}
          onChange={changeValue}
          fetchScans={(page) => 
            fetchScans(page)
          }
          searchScans={(query, page) => 
            searchScans(query, page)
          }
          deleteScan={(id) => 
            deleteScan(id).then( response => {
              this.props.history.push('/scans');
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: state.scans.page,
    totalPages: state.scans.totalPages,
    scans: state.scans.scans,
    isFetching: state.scans.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(scanActions.changeValue(name, value)),
    deleteScan: (id) => dispatch(scanActions.remove(id)),
    fetchScans: (page) => dispatch(scanActions.scans(page)), 
    searchScans: (query, page) => dispatch(scanActions.search(query, page))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ScansContainer);