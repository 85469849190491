import React, { Component } from "react";
import Rating from "react-rating";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";
import { withTranslation } from "react-i18next";

class ScanPage extends Component {
    state = {
        categories: [],
        step: 0,
        hasLoaded: false,
    };

    compareOrder(a, b) {
        return a.order - b.order;
    }

    hasCompletedCategory(category) {
        let completed = true;
        for (var i = 0; i < category.questions.length; i++) {
            if (category.questions[i].answer === null) {
                completed = false;
            }
        }
        return completed;
    }

    handlePrevious(e) {
        e.preventDefault();
        this.setState({
            step: this.state.step - 1,
        });
        window.scrollTo(0, 0);
    }

    handleNext(e) {
        e.preventDefault();
        if (this.state.step >= this.state.categories.length - 1) {
            this.props.history.push("/success");
        } else {
            this.setState({
                step: this.state.step + 1,
            });
            window.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        const { isSupervisor, onSessionFetch, onFetchScansFromSession, session } = this.props;
        if (isSupervisor) {
            onFetchScansFromSession(session.id);
        } else {
            onSessionFetch();
        }
    }

    componentDidUpdate() {
        if (!this.state.hasLoaded && this.props.scans && this.props.scans.length > 0) {
            var newCategories = [];
            this.props.scans.sort(this.compareOrder);
            for (var k = 0; k < this.props.scans.length; k++) {
                let scan = this.props.scans[k];
                scan.categories.sort(this.compareOrder);
                for (var i = 0; i < scan.categories.length; i++) {
                    var newQuestions = [];
                    scan.categories[i].questions.sort(this.compareOrder);
                    for (var j = 0; j < scan.categories[i].questions.length; j++) {
                        newQuestions.push({
                            id: scan.categories[i].questions[j].id,
                            question: scan.categories[i].questions[j].question,
                            answer: scan.categories[i].questions[j].answer,
                            type: scan.type,
                        });
                    }
                    newCategories.push({
                        id: scan.categories[i].id,
                        name: scan.categories[i].name,
                        description: scan.categories[i].description,
                        questions: newQuestions,
                        type: scan.type,
                    });
                }
            }

            this.setState({
                categories: newCategories,
                hasLoaded: true,
            });
        }
    }

    categoryDescription(categoryDescription) {
        if (categoryDescription !== "") {
            return <div className="description">{categoryDescription}</div>;
        }
    }
    category(category, index) {
        let categoryIndex = index;
        const { t } = this.props;
        return (
            <div className={"category-tab " + (this.state.step === index ? "active" : "")}>
                <div className="input-container catagory">
                    <div className="input-group two">
                        <div className="catagory-name">{category.name}</div>
                    </div>
                    {category.type === 0 ? (
                        <div className="input-group input-container three">
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.needsAttention")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.startIsMade")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.good")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.moreOnLevel")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.veryGood")}
                            </div>
                        </div>
                    ) : (
                        <div className="input-group input-container three">
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.needsAttention")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.startIsMade")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.canImprove")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.good")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.moreOnLevel")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.moreThanGood")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 7}%` }}>
                                {t("scan.veryGood")}
                            </div>
                        </div>
                    )}
                </div>
                {this.categoryDescription(category.description)}
                {category.questions.map((question, index) => {
                    return this.question(question, index, category, categoryIndex);
                })}
            </div>
        );
    }

    question(question, index, category, categoryIndex) {
        let scale = question.type === 0 ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6, 7];

        return (
            <div className="input-container">
                <div className="input-group two">
                    <div className="question">{question.question}</div>
                </div>
                <div className="input-group three">
                    <Rating
                        className="rating"
                        stop={scale.length}
                        initialRating={question.answer !== null ? question.answer.answer : 0}
                        emptySymbol={scale.map((n) => (
                            <span className="rating-text empty">{}</span>
                        ))}
                        fullSymbol={scale.map((n) => (
                            <span className="rating-text active">{}</span>
                        ))}
                        onChange={(event) => {
                            let answer = {
                                question: question.id,
                                answer: event,
                                description: "",
                            };
                            question.answer = answer;

                            const newCategories = this.state.categories.map((cat, cidx) => {
                                if (categoryIndex !== cidx) return cat;
                                cat.questions = cat.questions.map((que, qidx) => {
                                    if (index !== qidx) return que;
                                    return question;
                                });
                                return cat;
                            });

                            this.setState({
                                categories: newCategories,
                            });

                            this.props.answer(
                                this.props.isSupervisor ? this.props.session.code : "",
                                question.id,
                                event,
                                ""
                            );
                        }}
                    />
                </div>
            </div>
        );
    }

    renderSteps() {
        const { t } = this.props;
        const { step, categories } = this.state;
        var stepString = `${t("scan.step")} `;

        // Remove step string when items are over 10 long
        if (categories.length > 10) {
            stepString = "";
        }

        if (categories.length > 1) {
            return (
                <div className="progress">
                    {categories.map((category, index) => {
                        var classes = step === index ? " active" : "";
                        classes += step >= index ? " past" : "";
                        classes += this.hasCompletedCategory(category) ? " filled" : "";
                        return (
                            <div
                                className={"step " + classes}
                                onClick={(e) => {
                                    this.setState({
                                        step: index,
                                    });
                                }}
                            >
                                {stepString}
                                {index + 1}.
                            </div>
                        );
                    })}
                </div>
            );
        }
    }

    renderPrevious() {
        const { t } = this.props;
        const { step } = this.state;
        if (step > 0) {
            return (
                <div className="previous standalone">
                    <p type="button" onClick={(e) => this.handlePrevious(e)} className="small">
                        {t("form.previous")}
                    </p>
                </div>
            );
        }
    }

    renderNext() {
        const { t } = this.props;
        const { step, categories } = this.state;
        if (step < categories.length - 1) {
            return (
                <div className="submit">
                    <input type="submit" value={t("form.next")} onClick={(e) => this.handleNext(e)} />
                </div>
            );
        }
        return (
            <div className="submit">
                <input type="submit" value={t("form.finish")} onClick={(e) => this.handleNext(e)} />
            </div>
        );
    }

    render() {
        const { t, isFetching, scans } = this.props;
        const { categories } = this.state;
        if (!isFetching && scans) {
            return (
                <div className="container">
                    <div className="box scan">
                        <form method="post">
                            <h1 className="center big">{t("scan.header")}</h1>
                            <h2 className="center">{t("scan.description")}</h2>
                            {this.renderSteps()}
                            {categories.map((category, index) => this.category(category, index))}
                            <div className="input-container margin-top">
                                <div className="input-group one right">
                                    {this.renderPrevious()}
                                    {this.renderNext()}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        }
        return (
            <div className="content-loader">
                <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
            </div>
        );
    }
}
export default withTranslation("translation")(ScanPage);
