import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions.js';
import ForgotResetPage from '../../components/forgot/ForgotResetPage';

class ForgotResetContainer extends Component {

  render() {
    const {
      changeValue,
      recover,
      ...props
    } = this.props;
  
    const { repeatpassword, password, token } = props;

    return (
        <ForgotResetPage
          {...props}
          onChange={changeValue}
          onRecover={() => {
            recover(token, password, repeatpassword).then( response => {
              this.props.history.push('/login');
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    error: state.auth.error,
    password: state.auth.password,
    repeatpassword: state.auth.repeatpassword,
    token: state.auth.token
  };
}

function mapDispatchToProps(dispatch) {
  return {
    recover: (key, password, repeatpassword) => dispatch(authActions.recover(key, password, repeatpassword)),
    changeValue: (name,value) => dispatch(authActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ForgotResetContainer);