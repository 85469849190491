import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

import * as companyApi from "../../../api/companyApi";
import * as scanApi from "../../../api/scanApi";

class AddSessionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: null,
            endDate: null,
            focused: null,
            hasLoaded: false,
            positions: [],
            defaultCompanies: [],
            defaultTeams: [],
            defaultPositions: [],

            editorState: EditorState.createEmpty(),
        };
        this.selectedCompany = null;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.match.params.id != null) {
            this.props.onUpdate(this.state.positions);
        } else {
            this.props.onCreate(this.state.positions);
        }
    }

    getPositionsAndScans(company) {
        companyApi.getPositions(company).then((response) => {
            var positions = {};
            for (var i = 0; i < response.length; i++) {
                var position = response[i];
                scanApi.getScansWithCompanyAndPosition(company, position.id).then((scan) => {
                    var values = [];
                    if (scan.length < 1) {
                        return;
                    }
                    var scanPosition = scan[0].position;
                    for (var j = 0; j < scan.length; j++) {
                        values.push({
                            value: scan[j].id,
                            label: scan[j].name,
                        });
                    }
                    var scans = { [scanPosition]: values };
                    positions = { ...positions, ...scans };
                    this.setState({
                        defaultPositions: positions,
                    });
                });
            }
            this.props.onChange("positions", response);
        });
    }

    componentWillMount() {
        this.props.onChange("name", "");
        this.props.onChange("company", null);
        this.props.onChange("team", null);
        this.props.onChange("type", null);
        this.props.onChange("instruction", "");
        this.props.onChange("positions", []);
    }

    componentDidMount() {
        var _this = this;
        this.refs.selectCompany.loadOptions("", function (values) {
            _this.setState({
                defaultCompanies: values,
            });
            this.selectedCompany = null;
        });
    }

    render() {
        const { t, onChange } = this.props;

        if (this.props.isFetchingSession) {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }

        let typeOptions = [
            { value: 0, label: "Normale sessie" },
            { value: 1, label: "Training sessie" },
        ];

        return (
            <div className="container">
                <div className="box dash">
                    <form onSubmit={(e) => this.handleSubmit(e)}>
                        <h1 className="center">{t()}</h1>
                        <div className="input-container">
                            <div className="input-group one">
                                <input
                                    type="text"
                                    placeholder={t("form.label.name")}
                                    value={this.props.name}
                                    onChange={(event) => {
                                        const { name, value } = event.target;
                                        onChange("name", value, event);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-group one">
                                <Editor
                                    placeholder={t("form.label.instruction")}
                                    editorState={this.state.editorState}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="editor"
                                    editorClassName="editor-input"
                                    onContentStateChange={(newState) => {
                                        this.setState({
                                            contentState: newState,
                                        });
                                    }}
                                    onEditorStateChange={(newState) => {
                                        onChange("instruction", stateToHTML(newState.getCurrentContent()));

                                        this.setState({
                                            editorState: newState,
                                        });
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-group one">
                                <AsyncSelect
                                    ref="selectCompany"
                                    placeholder={t("form.label.selectCompany")}
                                    value={this.props.company}
                                    defaultOptions={this.state.defaultCompanies}
                                    autoload={true}
                                    onChange={(event) => {
                                        const { value } = event;
                                        this.selectedCompany = value;
                                        this.getPositionsAndScans(this.selectedCompany);
                                        var _this = this;
                                        this.refs.selectTeam.loadOptions("", function (values) {
                                            _this.setState({
                                                defaultTeams: values,
                                            });
                                        });
                                        onChange("team", null);
                                        onChange("company", event, event);
                                    }}
                                    loadOptions={(inputValue, callback) => {
                                        if (inputValue === "") {
                                            companyApi.getCompanies().then((response) => {
                                                var values = [];
                                                for (var i = 0; i < response.length; i++) {
                                                    values.push({
                                                        value: response[i].id,
                                                        label: response[i].name,
                                                    });
                                                }
                                                callback(values);
                                            });
                                        } else {
                                            companyApi.searchCompanies(inputValue).then((response) => {
                                                var values = [];
                                                for (var i = 0; i < response.length; i++) {
                                                    values.push({
                                                        value: response[i].id,
                                                        label: response[i].name,
                                                    });
                                                }
                                                callback(values);
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className="input-group one">
                                <AsyncSelect
                                    ref="selectTeam"
                                    placeholder={t("form.label.selectCompany")}
                                    isDisabled={this.props.company === null}
                                    value={this.props.team}
                                    defaultOptions={this.state.defaultTeams}
                                    onChange={(event) => {
                                        onChange("team", event, event);
                                    }}
                                    loadOptions={(inputValue, callback) => {
                                        if (inputValue === "") {
                                            companyApi.getTeams(this.selectedCompany).then((response) => {
                                                var values = [];
                                                for (var i = 0; i < response.length; i++) {
                                                    values.push({
                                                        value: response[i].id,
                                                        label: response[i].name,
                                                        team: response[i],
                                                    });
                                                }
                                                callback(values);
                                            });
                                        } else {
                                            companyApi
                                                .searchTeams(this.props.company.value, inputValue)
                                                .then((response) => {
                                                    var values = [];
                                                    for (var i = 0; i < response.length; i++) {
                                                        values.push({
                                                            value: response[i].id,
                                                            label: response[i].name,
                                                            team: response[i],
                                                        });
                                                    }
                                                    callback(values);
                                                });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-group one datepicker">
                                <DateRangePicker
                                    startDatePlaceholderText={t("form.label.startDate")}
                                    endDatePlaceholderText={t("form.label.endDate")}
                                    startDate={this.state.startDate}
                                    startDateId="startDate"
                                    endDate={this.state.endDate}
                                    endDateId="endDate"
                                    onDatesChange={({ startDate, endDate }) => {
                                        this.setState({ startDate, endDate });
                                        onChange("startDate", startDate);
                                        onChange("endDate", endDate);
                                    }}
                                    focusedInput={this.state.focusedInput}
                                    onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                                    hideKeyboardShortcutsPanel={true}
                                    small={false}
                                    block={false}
                                    noBorder={true}
                                    showDefaultInputIcon={false}
                                    displayFormat="DD/M/Y"
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-group one">
                                <Select
                                    placeholder="Selecteer het type rapport"
                                    onChange={(event) => {
                                        onChange("type", event, event);
                                        this.setState({
                                            type: event.value,
                                        });
                                    }}
                                    options={typeOptions}
                                />
                            </div>
                        </div>
                        {this.props.positions.map((position) => (
                            <div key={position.id}>
                                <div className="input-container positionScanMapper">
                                    <div className="input-group one">
                                        <h3>{position.name}</h3>
                                    </div>
                                    <div className="input-group one">
                                        <AsyncSelect
                                            isMulti
                                            ref={"selectScan" + position.id}
                                            placeholder={t("form.label.selectScan")}
                                            defaultOptions={this.state.defaultPositions[position.id]}
                                            onChange={(event) => {
                                                const value = event.map((e) => e.value);
                                                var newPosition = { [position.id]: value };
                                                var newPositions = {
                                                    ...this.state.positions,
                                                    ...newPosition,
                                                };
                                                this.setState({
                                                    positions: newPositions,
                                                });
                                            }}
                                            loadOptions={(inputValue, callback) => {
                                                if (inputValue === "") {
                                                    scanApi
                                                        .getScansWithCompanyAndPosition(
                                                            this.props.company.value,
                                                            position.id
                                                        )
                                                        .then((response) => {
                                                            var values = [];
                                                            for (var i = 0; i < response.length; i++) {
                                                                values.push({
                                                                    value: response[i].id,
                                                                    label: response[i].name,
                                                                });
                                                            }
                                                            callback(values);
                                                        });
                                                } else {
                                                    scanApi
                                                        .searchScansWithCompanyAndPosition(
                                                            this.props.company.value,
                                                            position.id,
                                                            inputValue
                                                        )
                                                        .then((response) => {
                                                            var values = [];
                                                            for (var i = 0; i < response.length; i++) {
                                                                values.push({
                                                                    value: response[i].id,
                                                                    label: response[i].name,
                                                                });
                                                            }
                                                            callback(values);
                                                        });
                                                }
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="input-container margin-top">
                            <div className="input-group one right">
                                <div className="submit">
                                    <input type="submit" value={t("form.save")} />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(AddSessionPage);
