import React, { Component } from 'react';
import { connect } from 'react-redux';
import ArticlesPage from '../../components/dashboard/ArticlesPage';
import * as blogActions from '../../actions/blogActions';

class ArticlesContainer extends Component {

  render() {
    const {
      changeValue,
      fetchArticles,
      searchArticles,
      deleteArticle,
      ...props
    } = this.props;
  
    return (
        <ArticlesPage
          {...props}
          onChange={changeValue}
          fetchArticles={(page) => 
            fetchArticles(page)
          }
          searchArticles={(query, page) => 
            searchArticles(query, page)
          }
          deleteArticle={(id) => {
            deleteArticle(id).then((event) => {
              fetchArticles(0)
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: state.blogs.page,
    totalPages: state.blogs.totalPages,
    articles: state.blogs.articles,
    isFetching: state.blogs.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(blogActions.changeValue(name, value)),
    deleteArticle: (id) => dispatch(blogActions.remove(id)),
    fetchArticles: (page) => dispatch(blogActions.articles(page)),
    searchArticles: (query, page) => dispatch(blogActions.search(query, page))
  };
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ArticlesContainer);