import React, { Component } from 'react';
import { MAIN_COLOR }  from '../../constants/constants' 
import { PulseLoader } from 'react-spinners';

export default class StopPage extends Component {

	componentDidMount(){
        const { onStop } = this.props;
        onStop();
    }

	render(){
        return (
            <div className='loader'>
                <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
            </div> 
        ); 
	}
} 