import React from 'react';

export default class Users extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      members: [{ 
        firstname: '',
        lastname: '',
        email: ''
      }]
    }
  }

  handleSubmit(e) {
    const {members} = this.state;
  }

  handleMemberChange(e, idx, newMember) {
    const newmembers = this.state.members.map((member, sidx) => {
      if (idx !== sidx) return member;
      return newMember;
    });
    this.setState({ members: newmembers });
  }

  handleRemoveMember(e, idx){
    this.setState({ members: this.state.members.filter((s, sidx) => idx !== sidx) });
  }

  handleAddMember(){
    this.setState({ members: this.state.members.concat([{         
        firstname: '',
        lastname: '',
        email: '' 
    }])});
  }
  render() {
    return (
      <div className="container">
        <div className="box scan">
          <form onSubmit={(e) => this.handleSubmit(e)}>
            <h1 className="center big">Team leden.</h1>
            <h2 className="center">Voeg alle teamleden toe</h2>
            {this.state.members.map((member, idx) => (
              <div className="input-container">
                <div className="input-group three">
                  <input type="text" placeholder={`Voornaam`} value={member.firstname} onChange={(e) => this.handleMemberChange(e, idx, member.firstname = e.target.value)} />
                </div>
                <div className="input-group three">
                  <input type="text" placeholder={`Achternaam`} value={member.firstname} onChange={(e) => this.handleMemberChange(e, idx, member.lastname = e.target.value)} />
                </div>
                <div className="input-group three">
                  <input type="text" placeholder={`E-mail`} value={member.firstname} onChange={(e) => this.handleMemberChange(e, idx, member.email = e.target.value)} />
                </div>
                <div className="input-group one">
                  <button type="button" onClick={(e) => this.handleRemoveMember(e, idx)} className="delete"></button>
                </div>
              </div>
            ))}

            <div className="input-container margin-top">
              <div className="input-group one">
                <div className="add">
                  <button type="button" onClick={(e) => this.handleAddMember(e)} className="small">Add member</button>
                </div>
              </div>
              <div className="input-group one right">
                <div className="submit">
                  <input type="submit" value="Inzenden" onClick={(e) => this.onSubmit(e)} />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}