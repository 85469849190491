import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function report(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/report/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                let json = JSON.parse(res.text);
                if (err || !res.ok || (json.success != undefined && !json.success)) {
                    reject(json);
                } else {
                    resolve(json);
                }
            });
    });
}

export function reports(page = 1) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/reports/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchReports(query = "", page = 1) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/reports/search/" + query + "/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addReport(
    name = "",
    emails = [],
    type = 0,
    level = 0,
    startDate = new Date(),
    endDate = new Date(),
    company,
    department,
    team,
    employee
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/report")
            .set("Accept", "application/json")
            .field("name", name)
            .field("emails", JSON.stringify(emails))
            .field("type", type)
            .field("level", level)
            .field("startDate", new Date(startDate).toISOString())
            .field("endDate", new Date(endDate).toISOString())
            .field("company", company)
            .field("department", department)
            .field("team", team)
            .field("employee", employee)
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function deleteReport(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/report/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}