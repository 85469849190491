import React, { Component } from "react";
import { withTranslation } from "react-i18next";

export default class Dashboard extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">Dashboard.</h1>
                </div>
            </div>
        );
    }
}
