import React from 'react';
import { Link } from 'react-router-dom';

export default class What extends React.Component {

	constructor(props){
		super(props);
	}

	componentDidMount(){
		window.scrollTo(0, 0);
		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		const top  = window.pageYOffset || document.documentElement.scrollTop,
		banner = document.querySelector(".banner");

		if (banner != null) {
			banner.style.transform = "translateY(" + top / 5 + "px)";
		}
	}
	
	render(){
		return (
			<div>
				<section className="banner what-banner" style={{backgroundImage: "url(/images/what.jpg)"}}>
					<div className="absolute-center">
						<h1>Wat wij bieden.</h1>
						<h2>Zie uw talent of minder sterken punten en ontwikkel verder.</h2>
					</div>
				</section>
				<section className="what">
					<div className="container">
						<h2 className="center">Onze resultaten die wij behalen met behulp van onze diensten.</h2>
						<div className="numbered">
							<div className="number">
								<h1 className="tag">1</h1>
								<h2>Vertalen competenties</h2>
								<h1>Concrete competenties.</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
							<div className="number">
								<h1 className="tag">2</h1>
								<h2>Quickscan.</h2>
								<h1>Eigen inzicht.</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
							<div className="number">
								<h1 className="tag">3</h1>
								<h2>Workshop "goed gesprek"</h2>
								<h1>Goed gesprek.</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
							<div className="number">
								<h1 className="tag">4</h1>
								<h2>Data-analyse.</h2>
								<h1>Inzicht.</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
							<div className="number">
								<h1 className="tag">5</h1>
								<h2>Implementeer inzicht & goed gesprek.</h2>
								<h1>Ontwikkeling</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
							<div className="number">
								<h1 className="tag">6</h1>
								<h2>Herhaal.</h2>
								<h1>Continue ontwikkling.</h1>
								<p>Blijf bijstellen en ontwikkelen.</p>
							</div>
						</div>
					</div>
				</section>
				<section className="cta white">
					<div className="container">
						<h1>Iets voor u of uw organisatie?</h1>
						<p>Bekijk onze diensten waarmee wij deze resultaten bieden.</p>
						<Link to="/diensten"><div className="button"><button>Diensten</button></div></Link> 
					</div>
				</section>
			</div>

		)
	}
}