import React, { Component } from 'react';
import { connect } from 'react-redux';
import StartPage from '../../components/scan/StartPage';

import * as sessionActions from '../../actions/sessionActions';

class StartContainer extends Component {

  render() {
    const {
      changeValue,
      session,
      try_session,
      ...props
    } = this.props;
  
    const { token, firstname, lastname, birthday, education, supervisorEmail } = props;

    return (
        <StartPage
          {...props}
          onChange={changeValue}
          onTry={(token) => 
            try_session(token).then(response => {
              if(response.success){
                this.props.history.push('/instruction')
              }
            })
          }
          onStart={() => 
            session(token, firstname, lastname, birthday, education.value, supervisorEmail).then(response => {
              if(response.success){
                this.props.history.push('/instruction')
              }
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.sessions.isFetching,
    token: state.sessions.token,
    firstname: state.sessions.firstname,
    lastname: state.sessions.lastname,
    birthday: state.sessions.birthday,
    education: state.sessions.education,
    supervisorEmail: state.sessions.supervisorEmail,
    error: state.sessions.error
  };
}

function mapDispatchToProps(dispatch) {
  return {
      session: (token, firstname, lastname, birthday, education, supervisorEmail) => dispatch(sessionActions.start(token, firstname, lastname, birthday, education, supervisorEmail)),
      try_session: (token) => dispatch(sessionActions.tryStart(token)),
      changeValue: (name,value) => dispatch(sessionActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(StartContainer);