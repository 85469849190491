import * as types from '../constants/actionTypes.js';
import * as reportApi from '../api/reportApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.REPORT_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.REPORT_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.REPORT_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.REPORT_FORM_ADD_FAILURE,
    ...error
});

export const add = (name, emails, type, level, startDate, endDate, company, department, team, employee) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = reportApi.addReport(name, emails, type, level, startDate, endDate, company, department, team, employee);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = reportApi.deleteReport(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const startGet = () => ({
    type: types.REPORT_GET_START
});

export const getSuccess = (result) => ({
    type: types.REPORT_GET_SUCCESS,
    report: result
});

export const getFailure = (error) => ({
    type: types.REPORT_GET_FAILURE,
    ...error
});

export const report = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = reportApi.report(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const startReportsGet = () => ({
    type: types.REPORTS_GET_START
});

export const getReportsSuccess = (result) => ({
    type: types.REPORTS_GET_SUCCESS,
    reports: [...result]
});

export const getReportsFailure = (error) => ({
    type: types.REPORTS_GET_FAILURE,
    ...error
});

export const reports = (page = 0) => {
    return (dispatch) => {
        dispatch(startReportsGet());

        const promise = reportApi.reports(page + 1);
        
        promise.then(result => {
            dispatch(getReportsSuccess(result))
        }).catch(error => {
            dispatch(getReportsFailure(error))
        });

        return promise;
    }
}

export const search = (query = '', page = 0) => {
    return (dispatch) => {
        dispatch(startReportsGet());

        const promise = reportApi.searchReports(query, page + 1);
        
        promise.then(result => {
            dispatch(getReportsSuccess(result))
        }).catch(error => {
            dispatch(getReportsFailure(error))
        });

        return promise;
    }
}