import React, { Component } from "react";
import { connect } from "react-redux";
import InstructionPage from "../../components/scan/InstructionPage";

class InstructionContainer extends Component {
    render() {
        const { ...props } = this.props;

        return <InstructionPage {...props} />;
    }
}

function mapStateToProps(state, ownProps) {
    return {
        isSupervisor: state.sessions.isSupervisor,
        isFetching: state.scans.isFetching,
        session: state.sessions.session,
        ...ownProps,
    };
}

export default connect(mapStateToProps)(InstructionContainer);
