import React, { Component } from 'react';
import { connect } from 'react-redux';
import Article from '../../components/blog/Article';
import * as blogActions from '../../actions/blogActions';

class ArticleContainer extends Component {

  render() {
    const {
      fetchArticle,
        ...props
    } = this.props;

    return (
        <Article
          {...props}
          fetchArticle={(id) => 
            fetchArticle(id).then( response => {
              
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        isFetching: state.blogs.isFetching,
        article: state.blogs.article
  } ;
}

function mapDispatchToProps(dispatch) {
    return {
      fetchArticle: (id) => dispatch(blogActions.article(id)),
    }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ArticleContainer);