import React, { Component } from 'react';
import { connect } from 'react-redux';
import TeamPage from '../../components/scan/TeamPage';
import * as sessionActions from '../../actions/sessionActions';

class TeamContainer extends Component {

  render() {
    const {
      changeValue,
      fetchSessions,
        ...props
    } = this.props;
  

    return (
        <TeamPage
          {...props}
          onChange={changeValue}
          fetchSessions={() => 
            fetchSessions()
          } 
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isSupervisor: state.sessions.isSupervisor,
    isFetching: state.sessions.isFetching,
    sessions: state.sessions.sessions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSessions: () => dispatch(sessionActions.teamSessions()),
    changeValue: (name, value) => dispatch(sessionActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(TeamContainer);