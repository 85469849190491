import React, { Component } from "react";

export default class Privacy extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div>
                <section className="document">
                    <div className="container">
                        <h1 className="center">Privacy statement.</h1>
                        Actional, gevestigd aan Professor Dondersstraat 65, 5017 HH TILBURG, is
                        verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze
                        privacyverklaring.
                        <h2>Contactgegevens:</h2>
                        https://actional.nl Professor Dondersstraat 65, 5017 HH TILBURG +31 6 49 7777 284
                        Thomas Kolmans is de Functionaris Gegevensbescherming van Actional hij is te bereiken
                        via info@actional.nl Persoonsgegevens die wij verwerken Actional verwerkt uw
                        persoonsgegevens doordat u gebruik maakt van onze diensten en/of omdat u deze zelf aan
                        ons verstrekt. Hieronder vindt u een overzicht van de persoonsgegevens die wij
                        verwerken:
                        <ul>
                            <li>Voor- en achternaam</li>
                            <li>Geboortedatum</li>
                            <li>E-mailadres</li>
                            <li>IP-adres</li>
                            <li>Internetbrowser en apparaat type</li>
                        </ul>
                        <h2>Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
                        Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over
                        websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders
                        of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden
                        ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om
                        zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke
                        toestemming. Als u er van overtuigd bent dat wij zonder die toestemming persoonlijke
                        gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via
                        thomas.kolmans@Actional.nl, dan verwijderen wij deze informatie.
                        <h2>Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
                        Actional verwerkt uw persoonsgegevens voor de volgende doelen: - Om goederen en
                        diensten bij u af te leveren
                        <h2>Geautomatiseerde besluitvorming</h2>
                        Actional neemt wel op basis van geautomatiseerde verwerkingen besluiten over zaken die
                        (aanzienlijke) gevolgen kunnen hebben voor personen. Het gaat hier om besluiten die
                        worden genomen door computerprogramma's of -systemen, zonder dat daar een mens
                        (bijvoorbeeld een medewerker van Actional) tussen zit.
                        <h2>Hoe lang we persoonsgegevens bewaren</h2>
                        Actional bewaart uw persoonsgegevens niet langer dan strikt nodig is om de doelen te
                        realiseren waarvoor uw gegevens worden verzameld. Wij hanteren de volgende
                        bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: (Categorie)
                        persoonsgegevens > Bewaartermijn > Reden Personalia > Bewaartermijn > Reden Adres >
                        Bewaartermijn > Reden Enzovoort > Bewaartermijn > Reden
                        <h2>Delen van persoonsgegevens met derden</h2>
                        Actional verstrekt uitsluitend aan derden en alleen als dit nodig is voor de
                        uitvoering van onze overeenkomst met u of om te voldoen aan een wettelijke
                        verplichting.
                        <h2>Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
                        Actional gebruikt functionele, analytische en tracking cookies. Een cookie is een
                        klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen in de
                        browser van uw computer, tablet of smartphone. Actional gebruikt cookies met een puur
                        technische functionaliteit. Deze zorgen ervoor dat de website naar behoren werkt en
                        dat bijvoorbeeld uw voorkeursinstellingen onthouden worden. Deze cookies worden ook
                        gebruikt om de website goed te laten werken en deze te kunnen optimaliseren. Daarnaast
                        plaatsen we cookies die uw surfgedrag bijhouden zodat we op maat gemaakte content en
                        advertenties kunnen aanbieden. Bij uw eerste bezoek aan onze website hebben wij u al
                        geïnformeerd over deze cookies en toestemming gevraagd voor het plaatsen ervan. U kunt
                        zich afmelden voor cookies door uw internetbrowser zo in te stellen dat deze geen
                        cookies meer opslaat. Daarnaast kunt u ook alle informatie die eerder is opgeslagen
                        via de instellingen van uw browser verwijderen. Zie voor een toelichting:
                        https://veiliginternetten.nl/themes/situatie/cookies-wat-zijn-het-en-wat-doe-ik-ermee/
                        Op deze website worden ook cookies geplaatst door derden. Dit zijn bijvoorbeeld
                        adverteerders en/of de sociale media-bedrijven. Hieronder een overzicht: Cookie:
                        Googly Analytics Naam: _utma Functie: Analytische cookie die websitebezoek meet
                        Bewaartermijn: 2 jaar
                        <h2>Gegevens inzien, aanpassen of verwijderen</h2>U heeft het recht om uw
                        persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heeft u het
                        recht om uw eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar
                        te maken tegen de verwerking van uw persoonsgegevens door Actional en heeft u het
                        recht op gegevensoverdraagbaarheid. Dat betekent dat u bij ons een verzoek kunt
                        indienen om de persoonsgegevens die wij van u beschikken in een computerbestand naar u
                        of een ander, door u genoemde organisatie, te sturen. U kunt een verzoek tot inzage,
                        correctie, verwijdering, gegevensoverdraging van uw persoonsgegevens of verzoek tot
                        intrekking van uw toestemming of bezwaar op de verwerking van uw persoonsgegevens
                        sturen naar thomas.kolmans@Actional.nl. Om er zeker van te zijn dat het verzoek tot
                        inzage door u is gedaan, vragen wij u een kopie van uw identiteitsbewijs met het
                        verzoek mee te sturen. Maak in deze kopie uw pasfoto, MRZ (machine readable zone, de
                        strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN)
                        zwart. Dit ter bescherming van uw privacy. We reageren zo snel mogelijk, maar binnen
                        vier weken, op uw verzoek. Actional wil u er tevens op wijzen dat u de mogelijkheid
                        heeft om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit
                        Persoonsgegevens. Dat kan via de volgende link:
                        https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons
                        <h2>Hoe wij persoonsgegevens beveiligen</h2>
                        Actional neemt de bescherming van uw gegevens serieus en neemt passende maatregelen om
                        misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde
                        wijziging tegen te gaan. Als u de indruk heeft dat uw gegevens niet goed beveiligd
                        zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice
                        of via thomas.kolmans@Actional.nl. Actional heeft de volgende maatregelen genomen om
                        uw persoonsgegevens te beveiligen:
                        <ul>
                            <li>
                                TLS (voorheen SSL) Wij versturen uw gegevens via een beveiligde
                                internetverbinding. Dit kunt u zien aan de adresbalk 'https' en het hangslotje
                                in de adresbalk.
                            </li>
                            <li>
                                DKIM, SPF en DMARC zijn drie internetstandaarden die wij gebruiken om te
                                voorkomen dat u uit onze naam e-mails ontvangt die virussen bevatten, spam
                                zijn of bedoelt zijn om persoonlijke (inlog)gegevens te bemachtigen.
                            </li>
                        </ul>
                    </div>
                </section>
            </div>
        );
    }
}
