import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReportPage from '../../components/report/ReportPage';
import * as reportActions from '../../actions/reportActions.js';
import * as scanActions from '../../actions/scanActions.js';

class ReportContainer extends Component {

  render() {
    const {
      fetchReport,
      saveAnswer,
      ...props
    } = this.props;

    return <ReportPage
          {...props}
          onFetch={(token) => {
            fetchReport(token)
          }}
          answer={(session, question, answer, description) => {
            saveAnswer(session, question, answer, description)
          }}
        />
  }
}

function mapStateToProps(state, ownProps) {
    return {
        isFetching: state.reports.isFetching,
        report: state.reports.selected
    };
}

function mapDispatchToProps(dispatch) {
    return {
      fetchReport: (token) => { dispatch(reportActions.report(token)) },
      saveAnswer: (session, question, answer, description) => dispatch(scanActions.answer(session, question, answer, description))
    }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ReportContainer);