import React, { Component } from 'react';
import { connect } from 'react-redux';
import StopPage from '../../components/scan/StopPage';
import * as sessionActions from '../../actions/sessionActions.js';

class StopContainer extends Component {

  render() {
    const {
      stop,
      ...props
    } = this.props;

    return (
        <StopPage
          {...props}
          onStop={() => 
            stop().then( response => {
              this.props.history.push('/')
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        error: state.auth.error
  } ;
}

function mapDispatchToProps(dispatch) {
    return {
        stop: () => dispatch(sessionActions.stop()),
    }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(StopContainer);