import * as types from '../constants/actionTypes.js';
import * as blogApi from '../api/blogApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.BLOG_FORM_CHANGE_VALUE,
    name,
    value
});

export const startArticleAdd = () => ({
    type: types.ARTICLE_FORM_ADD_START
});

export const addArticleSuccess = (result) => ({
    type: types.ARTICLE_FORM_ADD_SUCCESS,
    ...result
});

export const addArticleFailure = (error) => ({
    type: types.ARTICLE_FORM_ADD_FAILURE,
    ...error
});

export const add = (title, author, description, active, activeFrom, images, body) => {
    return (dispatch) => {
        dispatch(startArticleAdd());

        const promise = blogApi.addArticle(title, author, description, active, activeFrom, images, body);
        
        promise.then(result => {
            dispatch(addArticleSuccess(result))
        }).catch(error => {
            dispatch(addArticleFailure(error))
        });

        return promise
    }
};

export const update = (id, title, author, description, active, activeFrom, images, body) => {
    return (dispatch) => {
        dispatch(startArticleAdd());

        const promise = blogApi.updateArticle(id, title, author, description, active, activeFrom, images, body);

        promise.then(result => {
            dispatch(addArticleSuccess(result))
        }).catch(error => {
            dispatch(addArticleFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startArticleAdd());

        const promise = blogApi.deleteArticle(id);
        
        promise.then(result => {
            dispatch(addArticleSuccess(result))
        }).catch(error => {
            dispatch(addArticleFailure(error))
        });

        return promise;
    }
};

export const startArticleGet = () => ({
    type: types.ARTICLE_GET_START
});

export const getArticleSuccess = (result) => ({
    type: types.ARTICLE_GET_SUCCESS,
    article: result.article
});

export const getArticleFailure = (error) => ({
    type: types.ARTICLE_GET_FAILURE,
    ...error
});

export const article = (id) => {
    return (dispatch) => {
        dispatch(startArticleGet());

        const promise = blogApi.getArticle(id);
        
        promise.then(result => {
            dispatch(getArticleSuccess(result))
        }).catch(error => {
            dispatch(getArticleFailure(error))
        });

        return promise;
    }
}

export const startArticlesGet = () => ({
    type: types.ARTICLES_GET_START
});

export const getArticlesSuccess = (result) => ({
    type: types.ARTICLES_GET_SUCCESS,
    articles: [...result.articles]
});

export const getArticlesFailure = (error) => ({
    type: types.ARTICLES_GET_FAILURE,
    ...error
});

export const articles = (page= 0) => {
    return (dispatch) => {
        dispatch(startArticlesGet());

        const promise = blogApi.getArticles(page + 1);
        
        promise.then(result => {
            dispatch(getArticlesSuccess(result))
        }).catch(error => {
            dispatch(getArticlesFailure(error))
        });

        return promise;
    }
}

export const search = (query = '', page) => {
    return (dispatch) => {
        dispatch(startArticlesGet());

        const promise = blogApi.searchArticles(query, page + 1);
        
        promise.then(result => {
            dispatch(getArticlesSuccess(result))
        }).catch(error => {
            dispatch(getArticlesFailure(error))
        });

        return promise;
    }
}