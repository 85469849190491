import * as types from '../constants/actionTypes.js';
import * as companyApi from '../api/companyApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.COMPANY_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.COMPANY_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.COMPANY_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.COMPANY_FORM_ADD_FAILURE,
    ...error
});

export const add = (name, location, description, contactperson, email, phonenumber, actions) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.addCompany(name, location, description, contactperson, email, phonenumber, actions);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const update = (id, name, location, description, contactperson, email, phonenumber, actions) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.updateCompany(id, name, location, description, contactperson, email, phonenumber, actions);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.deleteCompany(id);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const startGet = () => ({
    type: types.COMPANY_GET_START
});

export const getSuccess = (result) => ({
    type: types.COMPANY_GET_SUCCESS,
    company: result
});

export const getFailure = (error) => ({
    type: types.COMPANY_GET_FAILURE,
    ...error
});

export const company = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = companyApi.getCompany(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const startCompaniesGet = () => ({
    type: types.COMPANIES_GET_START
});

export const getCompaniesSuccess = (result) => ({
    type: types.COMPANIES_GET_SUCCESS,
    companies: [...result]
});

export const getCompaniesFailure = (error) => ({
    type: types.COMPANIES_GET_FAILURE,
    ...error
});

export const companies = (page= 0) => {
    return (dispatch) => {
        dispatch(startCompaniesGet());

        const promise = companyApi.getCompanies(page + 1);
        
        promise.then(result => {
            dispatch(getCompaniesSuccess(result))
        }).catch(error => {
            dispatch(getCompaniesFailure(error))
        });

        return promise;
    }
}

export const search = (query = '', page) => {
    return (dispatch) => {
        dispatch(startCompaniesGet());

        const promise = companyApi.searchCompanies(query, page + 1);
        
        promise.then(result => {
            dispatch(getCompaniesSuccess(result))
        }).catch(error => {
            dispatch(getCompaniesFailure(error))
        });

        return promise;
    }
}