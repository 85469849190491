import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link, Redirect } from "react-router-dom";
import parse from 'html-react-parser';

import moment from "moment";

class InstructionPage extends Component {
    state = {
        submitted: false,
    };

    onSubmit(e) {
        e.preventDefault();
        this.setState({ submitted: true });
    }

    render() {
        const { t, session, isSupervisor } = this.props;
        if (this.state.submitted) {
            if (isSupervisor) {
                return <Redirect to="/team" />;
            } else {
                return <Redirect to="/scan" />;
            }
        }
        return (
            <div className="container">
                <div className="box instruction">
                    <h1 className="center big">{t("instruction.header")}</h1>
                    <h2 className="center">{t("instruction.description")}</h2>
                    <div dangerouslySetInnerHTML={{ __html: parse(session.instruction) }}></div>
                    <p>{t("instruction.deadline", {deadline: moment(session.experationDate.date).format("DD MMM")})}
                    </p>
                    <h2>{t("instruction.privacy.header")}</h2>
                    <p>{t("instruction.privacy.description")}
                    </p>
                    <form method="post" onSubmit={(e) => this.onNext(e)}>
                        <div className="input-container margin-top">
                            <div className="input-group one right">
                                <div className="submit">
                                    <input
                                        type="submit"
                                        value={t("form.yesIUnderstood")}
                                        onClick={(e) => this.onSubmit(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(InstructionPage);
