import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import * as contactApi from "../../api/contactApi";

class Contact extends Component {
    constructor() {
        super();
        this.state = {
            message: "",
            error: "",
            isSending: false,
        };
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
            isSending: true,
        });

        var values = [];
        var inputs = document.getElementById("contact").elements;
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].type != "submit") {
                if (inputs[i].value === undefined || inputs[i].value === "") {
                    this.setState({
                        isSending: false,
                        error: inputs[i].placeholder.replace("*", "") + " is verplicht om in te vullen",
                    });
                    return;
                } else {
                    values.push(inputs[i].value);
                }
            }
        }

        contactApi
            .contact(...values)
            .then((response) => {
                this.setState({
                    isSending: false,
                    error: "",
                    message: "Dankuwel voor uw bericht.",
                });
            })
            .catch((error) => {
                this.setState({
                    isSending: false,
                    error: "Sorry, er is iets mis gegaan.",
                });
            });
    }

    isEmail(value) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }

    onEmailChange(e) {}

    onRequiredFieldChange(e) {}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderContact() {
        const { t } = this.props;
        if (this.state.isSending) {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={"#ffffff"} size={25} />
                </div>
            );
        }
        return (
            <form method="post" id="contact" onSubmit={(e) => this.onSubmit(e)}>
                <h1>{t("contact.sendMessage")}</h1>
                <div className="input-container">
                    <div className="input-group one">
                        <input type="text" name="firstname" placeholder={`${t("form.label.firstName")}*`} />
                    </div>
                    <div className="input-group one">
                        <input type="text" name="lastname" placeholder={`${t("form.label.lastName")}*`} />
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-group one">
                        <input type="text" name="email" placeholder={`${t("form.label.email")}*`} />
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-group one">
                        <textarea name="message" placeholder={`${t("form.label.message")}*`}></textarea>
                    </div>
                </div>
                <div className="input-container">
                    <div className="message error">{this.state.error}</div>
                </div>
                <div className="input-container">
                    <div className="message">{this.state.message}</div>
                </div>
                <div className="input-group right">
                    <div className="submit">
                        <input type="submit" value={t("form.send")} />
                    </div>
                </div>
            </form>
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <div className="contact">
                    <div className="container">
                        <div className="flex-container">
                            <div className="one half align-center">
                                <h1>{t("contact.info.header")}</h1>
                                <h3>
                                    {t("contact.info.description")}
                                </h3>
                                <ul>
                                    <li>
                                        {t("contact.info.email")} <a href="mailto:info@actional.nl">info@actional.nl</a>
                                    </li>
                                    <li>
                                        {t("contact.info.phone")} <a href="tel:+31 (0) 6 49 777 284">+31 (0) 6 49 777 284</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="one half">{this.renderContact()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(Contact);
