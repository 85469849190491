import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import superagent from 'superagent';
import moment from 'moment';
import { CookieBanner } from '@palmabit/react-cookie-law';

import configureStore from './store/configureStore.js';
import i18n from "./i18n"

/* Header and Footer */
import Header from './components/header/Header.js';
import Footer from './components/footer/Footer.js';

/* General pages */
import Home from './containers/home/HomeContainer';
import What from './components/what/What.js';
import About from './components/about/About.js';
import How from './components/how/How';
import Price from './components/price/Price';
import Product from './components/product/Product';
import FAQ from './components/faq/FAQPage.js';

/* Admin pages */
import LoginContainer from './containers/login/LoginContainer.js';
import ForgotContainer from './containers/forgot/ForgotContainer';
import ForgotRequestSuccess from './components/forgot/ForgotRequestSuccessPage';
import ForgotResetContainer from './containers/forgot/ForgotResetContainer';
import LogoutContainer from './containers/logout/LogoutContainer.js';
import Dashboard from './containers/dashboard/DashboardContainer';
import Sessions from './containers/dashboard/SessionContainer';
import Reports from './containers/dashboard/ReportsContainer';
import Scans from './containers/dashboard/ScansContainer';
import Companies from './containers/dashboard/CompaniesContainer';
import Company from './containers/dashboard/CompanyContainer';
import Employees from './containers/dashboard/EmployeesContainer';
import AddScan from './containers/dashboard/new/AddScanContainer';
import AddCompany from './containers/dashboard/new/AddCompanyContainer';
import AddSession from './containers/dashboard/new/AddSessionContainer';
import EditSession from './containers/dashboard/edit/EditSessionContainer';
import AddReport from './containers/dashboard/new/AddReportContainer';
import ScanExample from './containers/dashboard/ScanExampleContainer';
import AddTeam from './containers/dashboard/new/AddTeamContainer';
import AddPosition from './containers/dashboard/new/AddPositionContainer';
import AddDepartment from './containers/dashboard/new/AddDepartmentContainer';
import Articles from './containers/dashboard/ArticlesContainer';
import AddArticleContainer from './containers/dashboard/new/AddArticleContainer';

/* Report pages */
import ReportContainer from './containers/report/ReportContainer.js';
import Users from './components/users/Users.js';

/* Scan pages */
import Start from './containers/scan/StartContainer.js';
import Scan from './containers/scan/ScanContainer.js';
import Team from './containers/scan/TeamContainer.js';
import Instruction from './containers/scan/InstructionContainer.js';
import Success from './containers/scan/SuccessContainer.js';
import Stop from './containers/scan/StopContainer';

/* Information pages */
import DemoRequest from './components/demo/DemoRequest';
import Contact from './components/contact/Contact';
import NotFound from './components/notfound/NotFound';
import Privacy from './components/home/Privacy.js';

/* Blog */
import ArticleContainer from './containers/blog/ArticleContainer';

/* Actions */
import * as sessionActions from './actions/sessionActions.js';
import * as authActions from './actions/authActions.js';

const store = configureStore();
window.store = store;
window.superagent = superagent.agent();

class Quickscan extends Component{

	constructor(props){
		super(props);
		moment.locale('nl');
	}

	unloadListener(){
		if(store.getState().sessions.hasSession){
			store.dispatch(sessionActions.stop());
			store.dispatch(sessionActions.stopSuccess([]));
		}
		return undefined;
	}

	componentDidMount() {
		window.addEventListener("beforeunload", (e) => {
			e.preventDefault();
			return this.unloadListener();
		});
	}

	render(){
		const DefaultRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={(props) => (
				store.getState().auth.isLoggedIn
				? <Redirect to='/sessions' />
				: store.getState().sessions.hasSession ? <Redirect to='/instruction' /> : <Component {...props} />
			)} />
		);

		const DashboardRoute = ({ component: Component, ...rest }) => {
			return 	(
				<Route {...rest} render={(props) => {
					if (store.getState().auth.isLoggedIn) {
						return <Component {...props} />
					}
					store.dispatch(authActions.changeValue('redirect', props.match.url));
					return <Redirect to='/login' />
				}} />
			);
		}; 

		const SessionRoute = ({ component: Component, ...rest }) => (
			<Route {...rest} render={(props) => (
				store.getState().sessions.hasSession
				? <Component {...props} />
				: <Redirect to='/' />
			)} />
		);

		const PageShell = Page => { 
			return props =>
				<div className="page">
					<Header {...props} />
						<Page {...props}  />
					<Footer {...props} />
				</div>
		};

		const cookies = () => (
			<CookieBanner
				message="Actional maakt gebruik van cookies om de site te vergemakkelijken en te laten functioneren."
				policyLink="/privacy"
				necessaryOptionText="Benodigd"
				preferencesOptionText="Voorkeuren"
				statisticsOptionText="Statistieken"
				marketingOptionText="Marketing"
				privacyPolicyLinkText="Bekijk ons privacy statement"
				acceptButtonText="Accepteren"
				styles = {{
					container: {},
					dialog: {
						position: 'fixed',
						bottom: 0,
						left: 0,
						right: 0,
						zIndex: 100000,
						backgroundColor: 'white',
						padding: '60px 0px',
						boxShadow: '0px -3px 8px -2px rgba(0,0,0,0.15)'
					},
					button: {},
					policy: {
						marginTop: '20px',
						display: 'block'
					},
					message: {
						fontFamily: 'Poppins, sans-serif',
						fontSize: '1.2em'
					}

				}}
				onAccept = {() => {}}
				onAcceptPreferences = {() => {}}
				onAcceptStatistics = {() => {}}
				onAcceptMarketing = {() => {}}
			/>
		);

		return (
			<Provider store={store}>
				<BrowserRouter>
					<Route render={({ location }) => (
						<div className="background">
							{cookies()}
							<Switch location={location}>
								<DefaultRoute exact path="/" component={PageShell(Home)} />

								<DefaultRoute exact path="/login" component={PageShell(LoginContainer)} />
								<DefaultRoute exact path="/reset" component={PageShell(ForgotContainer)} />
								<DefaultRoute exact path="/reset/success" component={PageShell(ForgotRequestSuccess)} />
								<DefaultRoute exact path="/reset/:key" component={PageShell(ForgotResetContainer)} />

								<DashboardRoute exact path="/dashboard" component={PageShell(Dashboard)} />
								<DashboardRoute exact path="/sessions" component={PageShell(Sessions)} />
								<DashboardRoute exact path="/new/session" component={PageShell(AddSession)} />
								<DashboardRoute exact path="/edit/session/:id" component={PageShell(EditSession)} />
								<DashboardRoute exact path="/reports" component={PageShell(Reports)} />
								<DashboardRoute exact path="/new/report" component={PageShell(AddReport)} />
								<DashboardRoute exact path="/edit/report/:id" component={PageShell(AddReport)} />
								<DashboardRoute exact path="/companies" component={PageShell(Companies)} />
								<DashboardRoute exact path="/company/:id" component={PageShell(Company)} />
								<DashboardRoute exact path="/company/:id/new/position" component={PageShell(AddPosition)} />
								<DashboardRoute exact path="/company/:id/edit/position/:positionId" component={PageShell(AddPosition)} />
								<DashboardRoute exact path="/company/:id/new/department" component={PageShell(AddDepartment)} />
								<DashboardRoute exact path="/company/:id/edit/department/:departmentId" component={PageShell(AddDepartment)} />
								<DashboardRoute exact path="/company/:id/new/team" component={PageShell(AddTeam)} />
								<DashboardRoute exact path="/company/:id/edit/team/:teamId" component={PageShell(AddTeam)} />
								<DashboardRoute exact path="/new/company" component={PageShell(AddCompany)} />
								<DashboardRoute exact path="/edit/company/:id" component={PageShell(AddCompany)} />
								<DashboardRoute exact path="/scans" component={PageShell(Scans)} />
								<DashboardRoute exact path="/new/scan" component={PageShell(AddScan)} />
								<DashboardRoute exact path="/edit/scan/:id" component={PageShell(AddScan)} />
								<DashboardRoute exact path="/scan/:id/example" component={PageShell(ScanExample)} />
								<DashboardRoute exact path="/employees" component={PageShell(Employees)} />
								<DashboardRoute exact path="/articles" component={PageShell(Articles)} />
								<DashboardRoute exact path="/new/article" component={PageShell(AddArticleContainer)} />
								<DashboardRoute exact path="/edit/article/:id" component={PageShell(AddArticleContainer)} />
								<DashboardRoute exact path="/logout" component={PageShell(LogoutContainer)} />

								<DefaultRoute exact path="/start/:code" component={PageShell(Start)} />
								<SessionRoute exact path="/instruction" component={PageShell(Instruction)} />
								<SessionRoute exact path="/team" component={PageShell(Team)} />
								<SessionRoute exact path="/scan" component={PageShell(Scan)} />
								<SessionRoute exact path="/success" component={PageShell(Success)} />
								<SessionRoute exact path="/stop" component={PageShell(Stop)} />

								<DefaultRoute exact path="/report/:code" component={PageShell(ReportContainer)} />
								<DefaultRoute exact path="/data-analysis/:code" component={PageShell(ReportContainer)} />
								<DefaultRoute exact path="/users/:code" component={PageShell(Users)} />

								<Route exact path='/frequent-asked-questions' component={PageShell(FAQ)} />
								<Route exact path='/hoe-het-werkt' component={PageShell(How)} />
								<Route exact path='/wat-het-kost' component={PageShell(Price)} />
								<Route exact path='/producten' component={PageShell(Product)} />
								<Route exact path='/over-ons' component={PageShell(About)} />
								<Route exact path='/wat-wij-bieden' component={PageShell(What)} />
								<Route exact path='/contact' component={PageShell(Contact)} />
								<Route exact path='/demo' component={PageShell(DemoRequest)} />
								<Route exact path='/artikel/:id/:slug' component={PageShell(ArticleContainer)} />

								<Route exact path="/privacy" component={PageShell(Privacy)} />

								<Route path='*' component={PageShell(NotFound)} />
							</Switch>
						</div>
					)}/>
				</BrowserRouter>
			</Provider>
 		);
	}
	
}

// Polyfill for 'includes' usage.
if (!Array.prototype.includes) {
	Object.defineProperty(Array.prototype, "includes", {
	  enumerable: false,
	  value: function(obj) {
		  var newArr = this.filter(function(el) {
			return el === obj;
		  });
		  return newArr.length > 0;
		}
	});
}

// Render application
ReactDOM.render(<Quickscan />, document.getElementById("quickscan"));
