import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    departments: [],
    page: 0,
    totalPages: 0,
    selected: null,
    company: null,
    name: '',
};

export default function departments(state = initialState, action) {
    switch (action.type) {
        case types.DEPARTMENT_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.DEPARTMENT_GET_START:
            state = {
                ...state,
                selected: null,
                isFetching: true,
            };
            return state;
        case types.DEPARTMENT_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                selected: action.department,
            };
            return state;
        case types.DEPARTMENTS_GET_START:
            state = {
                ...state,
                isFetching: true,
                departments: [],
            };
            return state;
        case types.DEPARTMENTS_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                departments: action.departments,
            };
            return state;
        default:
            return state;
    }
}
