import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";
import { Link } from "react-router-dom";
class CompanyPage extends Component {
    state = {
        tab: "teams",
        hasLoaded: false,
    };

    fetchData() {
        const { tab, match, fetchTeams, fetchDepartments, fetchPositions } = this.props;
        const { hasLoaded } = this.state;
        if (!hasLoaded) {
            switch(tab) {
                default:
                case "teams":
                    fetchTeams(match.params.id);
                break;
                case "departments": 
                fetchDepartments(match.params.id);
                break;
                case "positions": 
                fetchPositions(match.params.id);
            }
            this.setState({
                hasLoaded: true,
            });
        }
    }

    componentDidMount() {
        const { companyIsFetching, fetchCompany, match, company } = this.props;
        fetchCompany(match.params.id);
        if (!companyIsFetching && company != null) {
            this.fetchData();
        }
    }

    componentDidUpdate() {
        this.fetchData();
    }

    _renderTeams() {
        const { t, teamsIsFetching, teams, company, deleteTeam } = this.props;
        if (!teamsIsFetching) {
            if (teams.length > 0) {
                return (
                    <div>
                        {teams.map((team) => (
                            <li>
                                <div className="flex-container">
                                    <div className="three">{team.name}</div>
                                    <div className="one">
                                        <Link to={"/company/" + company.id + "/edit/team/" + team.id}>
                                            {t("edit")}
                                        </Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={() => {
                                            if (team.canBeDeleted) {
                                                deleteTeam(team.id);
                                            }
                                        }}
                                    >
                                        <a>{team.canBeDeleted ? t("delete") : ""}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("teams.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    _renderDepartments() {
        const { t, departmentsIsFetching, departments, company, deletePosition } = this.props;
        if (!departmentsIsFetching) {
            if (departments.length > 0) {
                return (
                    <div>
                        {departments.map((department) => (
                            <li>
                                <div className="flex-container">
                                    <div className="three">{department.name}</div>
                                    <div className="one">
                                        <Link
                                            to={
                                                "/company/" + company.id + "/edit/department/" + department.id
                                            }
                                        >
                                            {t("edit")}
                                        </Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={() => {
                                            if (department.canBeDeleted) {
                                                deletePosition(department.id);
                                            }
                                        }}
                                    >
                                        <a>{department.canBeDeleted ? t("delete") : ""}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("departments.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    _renderPositions() {
        const { t, positionsIsFetching, positions, company, deletePosition } = this.props;
        if (!positionsIsFetching) {
            if (positions.length > 0) {
                return (
                    <div>
                        {positions.map((position) => (
                            <li>
                                <div className="flex-container">
                                    <div className="three">{position.name}</div>
                                    <div className="one">
                                        <Link to={"/company/" + company.id + "/edit/position/" + position.id}>
                                            {t("edit")}
                                        </Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={() => {
                                            if (position.canBeDeleted) {
                                                deletePosition(position.id);
                                            }
                                        }}
                                    >
                                        <a>{position.canBeDeleted ? t("delete") : ""}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("positions.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    _renderActions() {
        const { t, company } = this.props;
        if (company.actions.length > 0) {
            return (
                <div className="info-block list">
                    <ul>
                        <li className="header">
                            <div className="flex-container">
                                <div className="two">{t("action.dueDate")}</div>
                                <div className="four">{t("action.description")}</div>
                            </div>
                        </li>
                        {company.actions.map((action) => (
                            <li>
                                <div className="flex-container">
                                    <div className="two">
                                        {moment(action.dueDate.date).format("DD-MM-YYYY")}
                                    </div>
                                    <div className="four">{action.description}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            );
        }
    }

    render() {
        const { t, onChange, companyIsFetching, company, match, tab } = this.props;

        if (!companyIsFetching && company != null) {
            return (
                <div className="container">
                    <div className="box dash">
                        <h1 className="center">{company.name}</h1>
                        <h3 className="center">Bedrijf #{match.params.id}.</h3>
                        <div className="info-block">
                            <div className="flex-container">
                                <div className="one">
                                    <b>{t("company.info.location")}</b>
                                    <p>{company.location}</p>
                                </div>
                                <div className="one">
                                    <b>{t("company.info.contactPerson")}</b>
                                    <p>{company.contactperson}</p>
                                </div>
                                <div className="one">
                                    <b>{t("company.info.email")}</b>
                                    <p>
                                        <a href={"mailto:" + company.email}>{company.email}</a>
                                    </p>
                                </div>
                                <div className="one">
                                    <b>{t("company.info.phoneNumber")}</b>
                                    <p>
                                        <a href={"tel:" + company.phonenumber}>{company.phonenumber}</a>
                                    </p>
                                </div>
                            </div>
                            <b>{t("company.info.description")}</b>
                            <p>{company.description}</p>
                        </div>
                        {this._renderActions()}
                        <Link to={"/edit/company/" + company.id}>
                            <button className="new">{t("edit")}</button>
                        </Link>
                        <div className="tabs">
                            <div
                                className={"tab " + (tab === "teams" ? "active" : "")}
                                onClick={() => {
                                    onChange("tab", "teams");
                                    this.setState({
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("company.tabs.teams")}
                            </div>
                            <div
                                className={"tab " + (tab === "departments" ? "active" : "")}
                                onClick={(event) => {
                                    onChange("tab", "departments");
                                    this.setState({
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("company.tabs.departments")}
                            </div>
                            <div
                                className={"tab " + (tab === "positions" ? "active" : "")}
                                onClick={() => {
                                    onChange("tab", "positions");
                                    this.setState({
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("company.tabs.positions")}
                            </div>
                            <div
                                className={"tab " + (tab === "users" ? "active" : "")}
                                onClick={() => {
                                    onChange("tab", "users");
                                    this.setState({
                                        hasLoaded: false,
                                    });
                                }}
                            >
                                {t("company.tabs.users")}
                            </div>
                        </div>
                        <section className={"tab-section gray " + (tab === "teams" ? "active" : "")}>
                            <h1 className="center">{t("teams.header")}</h1>
                            <Link to={"/company/" + match.params.id + "/new/team"}>
                                <button className="new">{t("team.new")}</button>
                            </Link>
                            <div className="list">
                                <ul>
                                    <li className="header">
                                        <div className="flex-container">
                                            <div className="four">{t("team.new")}</div>
                                            <div className="ll "></div>
                                        </div>
                                    </li>
                                    {this._renderTeams()}
                                </ul>
                            </div>
                        </section>
                        <section className={"tab-section gray " + (tab === "departments" ? "active" : "")}>
                            <h1 className="center">{t("departments.header")}</h1>
                            <Link to={"/company/" + match.params.id + "/new/department"}>
                                <button className="new">{t("department.new")}</button>
                            </Link>
                            <div className="list">
                                <ul>
                                    <li className="header">
                                        <div className="flex-container">
                                            <div className="four">{t("department.name")}</div>
                                            <div className="two"></div>
                                        </div>
                                    </li>
                                    {this._renderDepartments()}
                                </ul>
                            </div>
                        </section>
                        <section className={"tab-section gray " + (tab === "positions" ? "active" : "")}>
                            <h1 className="center">{t("positions.header")}</h1>
                            <Link to={"/company/" + match.params.id + "/new/position"}>
                                <button className="new">{t("position.new")}</button>
                            </Link>
                            <div className="list">
                                <ul>
                                    <li className="header">
                                        <div className="flex-container">
                                            <div className="four">{t("position.name")}</div>
                                            <div className="two"></div>
                                        </div>
                                    </li>
                                    {this._renderPositions()}
                                </ul>
                            </div>
                        </section>
                        <section className={"tab-section gray " + (tab === "users" ? "active" : "")}>
                            <h1 className="center">{t("users.header")}</h1>
                            <Link to={"/company/" + match.params.id + "/new/user"}>
                                <button className="new">{t("user.new")}</button>
                            </Link>
                            <div className="list">
                                <ul>
                                    <li className="header">
                                        <div className="flex-container">
                                            <div className="four">{t("user.name")}</div>
                                            <div className="two"></div>
                                        </div>
                                    </li>
                                    {this._renderPositions()}
                                </ul>
                            </div>
                        </section>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}

export default withTranslation("translation")(CompanyPage);
