import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { Radar } from "react-chartjs-2";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";

class EmployeeDefaultReport extends Component {
    state = {
        hasLoaded: false,
        answers: [],
        tab: "results",
    };
    arrayToFractileArray(array) {
        var newArray = [];
        for (var i = array.length - 1; i >= 0; i--) {
            newArray[i] = 1;
        }
        return newArray;
    }

    calculatePercentage(fractile, from) {
        return (fractile / from) * 100;
    }

    compareOrder(a, b) {
        return a.order - b.order;
    }

    truncate(str, num) {
        return str.length > num ? str.slice(0, num) + "..." : str;
    }

    getAnswers(report, question, all = false) {
        var answers = [];
        if (all) {
            for (var k = 0; k < report.parts.length; k++) {
                report.parts[k].session.scans.forEach((scan) => {
                    for (var i = 0; i < scan.categories.length; i++) {
                        for (var j = 0; j < scan.categories[i].questions.length; j++) {
                            if (scan.categories[i].questions[j].id === question.id) {
                                var newAnswers = scan.categories[i].questions[j].answers;
                                if (newAnswers.length > 0) {
                                    answers = [...answers, ...newAnswers];
                                }
                            }
                        }
                    }
                });
            }
        } else {
            for (var k = 0; k < report.parts.length; k++) {
                report.parts[k].subject.scans.forEach((scan) => {
                    for (var i = 0; i < scan.categories.length; i++) {
                        for (var j = 0; j < scan.categories[i].questions.length; j++) {
                            if (scan.categories[i].questions[j].id === question.id) {
                                var answerObject = scan.categories[i].questions[j].answer;
                                if (report.subject === report.parts[k].session.id) {
                                    answers.unshift(answerObject === null ? 0 : answerObject.answer);
                                } else {
                                    answers.push(answerObject === null ? 0 : answerObject.answer);
                                }
                            }
                        }
                    }
                });
            }
        }
        return answers;
    }

    componentWillMount() {
        let _this = this;
        if (!this.state.hasLoaded && this.props.report != null) {
            var newParts = [];
            var categories = [];

            for (var k = 0; k < this.props.report.parts.length; k++) {
                var newCategories = [];
                this.props.report.parts[k].subject.scans.forEach((scan) => {
                    scan.categories.sort(this.compareOrder);

                    for (var i = 0; i < scan.categories.length; i++) {
                        var newQuestions = [];
                        var newQuestionsWithReportAnswers = [];
                        scan.categories[i].questions.sort(this.compareOrder);

                        for (var j = 0; j < scan.categories[i].questions.length; j++) {
                            let answer =
                                _this
                                    .getAnswers(_this.props.report, scan.categories[i].questions[j], true)
                                    .filter((a) => {
                                        return a.respondentSession === _this.props.report.id;
                                    })[0] || null;
                            newQuestions.push({
                                id: scan.categories[i].questions[j].id,
                                question: scan.categories[i].questions[j].question,
                                answer: answer,
                                type: scan.type,
                            });
                            newQuestionsWithReportAnswers.push({
                                id: scan.categories[i].questions[j].id,
                                question: scan.categories[i].questions[j].question,
                                answer: answer,
                                type: scan.type,
                            });
                        }
                        newCategories.push({
                            id: scan.categories[i].id,
                            name: scan.categories[i].name,
                            questions: newQuestions,
                            type: scan.type,
                        });

                        if (categories.filter((e) => e.id === scan.categories[i].id).length === 0) {
                            categories.push({
                                id: scan.categories[i].id,
                                name: scan.categories[i].name,
                                questions: newQuestionsWithReportAnswers,
                                type: scan.type,
                            });
                        }
                    }
                });

                if (this.props.report.subject === this.props.report.parts[k].session.id) {
                    newParts.unshift({
                        respondent: this.props.report.parts[k].session,
                        categories: newCategories,
                    });
                } else {
                    newParts.push({
                        respondent: this.props.report.parts[k].session,
                        categories: newCategories,
                    });
                }
            }

            this.setState({
                parts: newParts,
                categories: categories,
                hasLoaded: true,
            });
        }
    }

    createAnswerData(questions) {
        var answers = {
            subject: [],
            respondent: [],
        };

        for (var j = 0; j < questions.length; j++) {
            var question = questions[j];
            var answer = this.getAnswers(this.props.report, question);

            answers.subject.push(parseInt(answer[0]));
            answers.respondent.push(parseInt(answer[1]));
        }
        return answers;
    }

    createDataSet(answers) {
        var datasets = [];

        for (var k = 0; k < this.state.parts.length; k++) {
            var part = this.state.parts[k];
            var fullname = part.respondent.employee.firstname + " " + part.respondent.employee.lastname;
            datasets.push({
                label: fullname === " " ? "No Name" : fullname,
                fill: true,
                backgroundColor: k === 0 ? "rgba(68, 216, 158, 0.4)" : "rgba(22, 145, 191, 0.4)",
                borderColor: k === 0 ? "#44d89e" : "#1691bf",
                lineTension: 0,
                data: k === 0 ? answers.subject : answers.respondent,
            });
        }

        return datasets;
    }

    renderCharts() {
        let types = [];
        let categories = this.state.parts[0].categories;
        for (let i = 0; i < categories.length; i++) {
            for (let j = 0; j < categories[i].questions.length; j++) {
                let question = categories[i].questions[j];
                if (!Array.isArray(types[question.type])) {
                    types[question.type] = [];
                }
                types[question.type].push(question);
            }
        }

        return (
            <div className="charts">
                {types.map((questions) => {
                    const answers = this.createAnswerData(questions);
                    const data = {
                        labels: Object.keys(answers.subject),
                        datasets: this.createDataSet(answers),
                    };

                    let options = {
                        scale: {
                            ticks: {
                                suggestedMin: 0,
                                beginAtZero: true,
                            },
                        },
                    };

                    return <Radar data={data} options={options} type="myLine" />;
                })}
            </div>
        );
    }

    renderCategory(category, categoryIndex) {
        const { t } = this.props;
        const scale = category.type === 0 ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6, 7];
        return (
            <div key={category.key}>
                <div className="input-container catagory">
                    <div className="input-group two">
                        <div className="catagory-name">{category.name}</div>
                    </div>
                    <div className="input-group input-container three flex-container direction-column">
                        <div className="flex-container no-wrap">
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.needsAttention")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.startIsMade")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.good")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.moreOnLevel")}
                            </div>
                            <div className="input-group" style={{ width: `${100 / 5}%` }}>
                                {t("scan.veryGood")}
                            </div>
                        </div>
                        <div className="flex-container">
                            {scale.map((scaleItem) => (
                                <div className="one">{scaleItem}</div>
                            ))}
                        </div>
                    </div>
                </div>
                {category.questions.map((question) => {
                    return <div className="grouped-answer">{this.renderQuestion(question)}</div>;
                })}
            </div>
        );
    }

    renderQuestion(question) {
        var answers = this.getAnswers(this.props.report, question);
        return (
            <div className="input-container report" key={question.question}>
                <div className="input-group two">
                    <div className="question">{question.question}</div>
                </div>
                <div className="input-group three">
                    {answers.map((answer, index) => {
                        var percentage = this.calculatePercentage(answer, question.type === 0 ? 5 : 7);
                        var style = { width: percentage + "%" };
                        return (
                            <div
                                key={answer + index}
                                className={"line " + (index === 0 ? "subject" : "respondent")}
                                style={style}
                            ></div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        const { isFetching, report, t } = this.props;
        if (this.props.isFetching || !this.state.hasLoaded) {
            if (this.props.report === null && !this.props.isFetching) {
                return (
                    <div className="absolute-center">
                        <h1 className="center">{t("report.doesNotExist")}</h1>
                    </div>
                );
            }
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }

        if (this.props.report === null || this.state.parts.length < 1) {
            return (
                <div className="absolute-center">
                    <h1 className="center">{t("report.doesNotExist")}</h1>
                </div>
            );
        }

        return (
            <div className="container">
                <div className="box scan">
                    <form method="post" onSubmit={(e) => this.handleSubmit(e)}>
                        <h1 className="center">{t("report.header")}</h1>
                        <h1 className="center big">{t("report.employeeDefault.header")}</h1>
                        <h2 className="center">{t("report.employeeDefault.description")}</h2>
                        <div className="participants">
                            {this.state.parts.map((part, index) => {
                                var fullname =
                                    part.respondent.employee.firstname +
                                    " " +
                                    part.respondent.employee.lastname;
                                return (
                                    <div className={index === 0 ? "subject" : "respondent"}>
                                        <div className="name">
                                            {fullname === " " ? "No Name" : fullname}
                                        </div>
                                        <div className="email">{part.respondent.employee.email}</div>
                                    </div>
                                );
                            })}
                        </div>
                        {this.renderCharts()}
                        {this.state.categories.map((category, index) => this.renderCategory(category, index))}
                    </form>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(EmployeeDefaultReport);
