import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScanExamplePage from '../../components/dashboard/ScanExamplePage';
import * as scanActions from '../../actions/scanActions';

class ScanExampleContainer extends Component {

  getSafe(fn) {
    try {
        return fn();
    } catch (e) {
        return '';
    }
  }

  render() {
    const {
      changeValue,
      create,
      name,
      email,
      message,
      ...props
    } = this.props;
        
    return (
        <ScanExamplePage
          {...props}
          onChange={changeValue}
          onCreate={(scan) => {
            create(scan, name, email, message).then( response => {
              this.props.history.push("/edit/scan/" + scan); 
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.scans.isFetching,
    scan: state.scans.selected,
    name: state.scans.name,
    email: state.scans.email,
    message: state.scans.message
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(scanActions.changeValue(name, value)),
    fetchScan: (id) => dispatch(scanActions.scan(id)),
    create: (scan, name, email, message) => dispatch(scanActions.sendExample(scan, name, email, message))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ScanExampleContainer);