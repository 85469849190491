import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { SingleDatePicker } from 'react-dates';
import { MAIN_COLOR }  from '../../../constants/constants' 
import { PulseLoader } from 'react-spinners';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import {stateFromHTML} from 'draft-js-import-html';

import parse from 'html-react-parser';
import Files from "react-butterfiles";

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import * as blogApi from '../../../api/blogApi.js';

class AddArticlePage extends Component {

  constructor(props) {
    super(props);

    this.state  = {
      images: [],
      errors: [],
      editorState: EditorState.createEmpty(),
      contentState: {},
      hasLoaded: false
    }
  }


  handleSubmit(e) {
    const { match, onUpdate, onCreate } = this.props;
    e.preventDefault();
    if(match.params.id != null){
      onUpdate(this.state.images);
    } else {
      onCreate(this.state.images);
    }
  }

  handleActionChange(e, idx, newAction) {
    const newimages = this.state.images.map((image, sidx) => {
      if (idx !== sidx) return image;
      return newAction;
    });
    this.setState({ images: newimages });
  }

  handleRemoveAction(e, idx){
    this.setState({ images: this.state.images.filter((s, sidx) => idx !== sidx) });
  }

  handleAddAction(){
    this.setState({ images: this.state.images.concat([{         
        id: null, 
        name: '',
        file: null
    }])});
  }

  handleSuccess(event, x){
    let value = event[0];
    let images = [...this.state.images] || [];
    images[x].file = value.src.file;
    images[x].base64 = value.src.base64;
    images[x].name = value.name;

    blogApi.uploadImage(value.src.base64).then((file) => {
      images[x].id = file.uid;
      images[x].url = file.url;
      this.setState({
        images: images
      });
    });
  }

  handleErrors(errors) {
    this.setState({
      errors: errors
    })
  }

  componentWillMount(){
    const { match, fetchArticle, onChange } = this.props;
    if(match.params.id != null){
      fetchArticle(match.params.id);
    } else {
      onChange('article', null);
      onChange('title', '');
      onChange('description', '');
      onChange('author', '');
      onChange('body', '');
    }
  }

  componentDidUpdate(){
    const { article, match, onChange } = this.props;
    if(!this.state.hasLoaded && article != null && match.params.id != null){
      onChange('title', article.title);
      onChange('author', article.author);
      onChange('description', article.description);
      onChange('body', article.body);

      this.setState({
        images: JSON.parse(JSON.stringify(article.images)).map ( (image) => {
          image.id = image.id;
          image.name = image.id;
          image.url = image.url;
          return image;
        }),
        hasLoaded: true,
        editorState: EditorState.createWithContent((stateFromHTML(parse(article.body))))
      });
    }
  }

  render() {
    const {
      t,
      onChange,
      match,
      title, 
      author, 
      description, 
      activeFrom, 
      isFetching,
    } = this.props;
   
    if(!isFetching){
      return (
        <div className="container">
          <div className="box dash">
            <form>
              <h1 className="center">{ (match.params.id != null ? "Update" : "Nieuw") + " artikel." }</h1>
              <div className="input-container">
                <div className="input-group one">
                  <input type="text" placeholder={t("form.label.title")} value={title} onChange={event => {
                    const { value } = event.target;
                    onChange('title', value, event);
                  }}/>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <input type="text" placeholder={t("form.label.author")} value={author} onChange={event => {
                    const { value } = event.target;
                    onChange('author', value, event);
                  }}/>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <textarea type="text" placeholder={t("form.label.description")} value={description} onChange={event => {
                    const { value } = event.target;
                    onChange('description', value, event);
                  }}>{description}</textarea>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <SingleDatePicker 
                      id={"datepicker_activeFromFocused"}
                      small={false}
                      block={false}
                      noBorder={true}
                      hideKeyboardShortcutsPanel={true}
                      showDefaultInputIcon={false}
                      date={activeFrom}
                      focused={this.state['activeFromFocused']}
                      onFocusChange={({ focused }) => {
                        this.setState({['activeFromFocused']:  focused })}
                      }
                      onDateChange={(startDate) => {
                        onChange('activeFrom', startDate, startDate);
                      }}
                    />
                </div>
              </div>
              {(this.state.images.length > 0 ? <h1 className="center">{t("form.label.image")}</h1> : null)}
              {this.state.images.map((image, idx) => (
                <div className="input-container" key={idx}>
                  <div className="input-group four">

                    <Files
                        convertToBase64
                        maxSize="10mb" 
                        accept={["application/pdf","image/jpg","image/jpeg"]}
                        onSuccess={(e) => this.handleSuccess(e, idx)}
                        onError={(e) => this.handleErrors(e)}
                    >
                        {({ browseFiles, getDropZoneProps }) => {
                            return (
                                <div>
                                    <label>Drag and drop files.</label>
                                    <div
                                        {...getDropZoneProps({
                                            style: {
                                                width: '100%',
                                                minHeight: 40,
                                                border: "2px lightgray dashed"
                                            }
                                        })}
                                    >
                                        <ol>
                                            <li key={image.name}>{image.name}</li>
                                            {this.state.errors.length > 0 ? this.state.errors.map(error => (
                                                <li key={error.id}>
                                                    {error.file ? (
                                                        <span>
                                                            {error.file.name} - {error.type}
                                                        </span>
                                                    ) : (
                                                        error.type
                                                    )}
                                                </li>
                                            )) : null}
                                        </ol>
                                    </div>
                                    <div>
                                        Dragging not convenient? Click{" "}
                                        <a onClick={browseFiles}>here</a> to select file.
                                    </div>
                                </div>
                            );
                        }}
                    </Files>
                  </div>
                  <div className="input-group one">
                    <button type="button" onClick={(e) => this.handleRemoveAction(e, idx)} className="delete"></button>
                  </div>
                </div>
              ))}
              <div className="input-container">
                <div className="input-group one">
                  <div className="add">
                    <button type="button" onClick={(e) => this.handleAddAction(e)} className="small">Afbeelding</button>
                  </div>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <Editor
                    editorState={this.state.editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="editor"
                    editorClassName="editor-input"
                    onContentStateChange={(newState) => {
                      this.setState({
                        contentState: newState
                      })
                    }}
                    onEditorStateChange={(newState) => {
                      onChange("body", stateToHTML(newState.getCurrentContent()));

                      this.setState({
                        editorState: newState
                      })
                    }}
                  />
                </div>
              </div>
              <div className="input-container margin-top">
                <div className="input-group one right">
                  <div className="submit">
                    <input type="submit" value={t("form.save")} onClick={(e) => this.handleSubmit(e)} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
			return (
				<div className='content-loader'>
				  <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
				</div> 
			);
    }

  }
}
export default withTranslation("translation")(AddArticlePage)