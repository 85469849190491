import React, { Component } from 'react';
import { connect } from 'react-redux';
import EmployeesPage from '../../components/dashboard/EmployeesPage';
import * as employeeActions from '../../actions/employeeActions';

class EmployeesContainer extends Component {

  render() {
    const {
      fetchEmployees,
      saveEmployees,
      ...props
    } = this.props;
  
    return (
        <EmployeesPage
          {...props}
          fetchEmployees={() => 
            fetchEmployees()
          }
          saveEmployees={(users) => {
            saveEmployees(users)
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    employees: state.employees.employees,
    isFetching: state.employees.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchEmployees: () => dispatch(employeeActions.employees()),
    saveEmployees: (users) => dispatch(employeeActions.save(users))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(EmployeesContainer);