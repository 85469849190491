import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class NotFound extends Component {
    render() {
		const { t } = this.props;
        return (
            <div className="container">
                <div className="notFound absolute-center">
                    <h1 className="center big">404 error</h1>
                    <hr />
                    <h1 className="center">Niet gevonden</h1>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(NotFound);