import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Cleave from "cleave.js/react";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";
import Select from "react-select";

class StartPage extends Component {
    constructor(props) {
        super(props);

        this.validator = null;
    }

    onSubmit(e) {
        const { onStart } = this.props;
        e.preventDefault();
        onStart();
    }

    componentDidMount() {
        const { onChange, onTry, match } = this.props;
        onChange("token", match.params.code);
        onTry(match.params.code);
    }

    render() {
        const options = [
            { value: "VMBO", label: "VMBO" },
            { value: "MBO", label: "MBO" },
            { value: "HBO", label: "HBO" },
            { value: "WO", label: "WO" },
            { value: "different", label: "Anders..." },
        ];

        const { t, isFetching, onChange } = this.props;

        if (!isFetching) {
            return (
                <div className="container">
                    <div className="box start absolute-center">
                        <form method="post">
                            <h1 className="center big">{t("start.header")}</h1>
                            <h2 className="center"></h2>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={`${t("form.label.firstName")}*`}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("firstname", value, event);
                                        }}
                                    />
                                </div>
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={`${t("form.label.lastName")}*`}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("lastname", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <Cleave
                                        placeholder={`${t("form.label.birthday")}*`}
                                        options={{ date: true, datePattern: ["d", "m", "Y"] }}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            var dateParts = value.split("/");
                                            onChange(
                                                "birthday",
                                                new Date(dateParts[2], dateParts[1], dateParts[0]),
                                                event
                                            );
                                        }}
                                    />
                                </div>
                                <div className="input-group one">
                                    <Select
                                        options={options}
                                        value={
                                            this.props.education.value === "" ? null : this.props.education
                                        }
                                        placeholder={t("form.label.workAndThinkLevel")}
                                        onChange={(event) => {
                                            onChange("education", event, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="message error">{this.props.error}</div>
                            </div>
                            <div className="input-container margin-top">
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input
                                            type="submit"
                                            value={t("form.next")}
                                            onClick={(e) => this.onSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(StartPage);
