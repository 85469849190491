import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";

class Employees extends Component {
    state = {
        members: [],
        hasFetched: false,
    };

    handleSubmit(e) {
        e.preventDefault();
        const { members } = this.state;
        const { saveEmployees } = this.props;
        saveEmployees(members);
    }

    handleMemberChange(e, idx, newMember) {
        const newMembers = this.state.members.map((member, sidx) => {
            if (idx !== sidx) return member;
            return newMember;
        });
        this.setState({ members: newMembers });
    }

    handleRemoveMember(e, idx) {
        this.setState({ members: this.state.members.filter((s, sidx) => idx !== sidx) });
    }

    handleAddMember() {
        const { members } = this.state;
        this.setState({
            members: members.concat([
                {
                    username: "",
                    email: "",
                },
            ]),
        });
    }

    componentWillMount() {
        this.props.fetchEmployees();
    }

    componentDidUpdate() {
        const { isFetching, employees } = this.props;
        const { hasFetched } = this.state;
        if (!isFetching && employees.length > 0 && !hasFetched) {
            this.setState({
                id: null,
                members: employees,
                hasFetched: true,
            });
        }
    }

    render() {
        const { t, isFetching } = this.props;
        if (!isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        <form>
                            <h1 className="center">{t("employees.header")}</h1>
                            {this.state.members.map((member, idx) => (
                                <div className="input-container">
                                    <div className="input-group three">
                                        <input
                                            type="text"
                                            placeholder={t("form.label.username")}
                                            value={member.username}
                                            onChange={(e) =>
                                                this.handleMemberChange(
                                                    e,
                                                    idx,
                                                    (function () {
                                                        member.username = e.target.value;
                                                        return member;
                                                    })()
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group three">
                                        <input
                                            type="text"
                                            placeholder={t("form.label.email")}
                                            value={member.email}
                                            onChange={(e) =>
                                                this.handleMemberChange(
                                                    e,
                                                    idx,
                                                    (function () {
                                                        member.email = e.target.value;
                                                        return member;
                                                    })()
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group one">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleRemoveMember(e, idx)}
                                            className="delete"
                                        ></button>
                                    </div>
                                </div>
                            ))}

                            <div className="input-container margin-top">
                                <div className="input-group one">
                                    <div className="add">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleAddMember(e)}
                                            className="small"
                                        >
                                            {t("form.add")}
                                        </button>
                                    </div>
                                </div>
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input
                                            type="submit"
                                            value={t("form.save")}
                                            onClick={(e) => this.handleSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(Employees);