import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    scans: [],
    page: 0,
    totalPages: 0,
    selected: null,
    name: '',
    company: null,
    position: null,
    description: '',
    categories: [],
};

export default function scans(state = initialState, action) {
    switch (action.type) {
        case types.SCAN_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.SCAN_GET_START:
            state = {
                ...state,
                isFetching: true,
                selected: null,
            };
            return state;
        case types.SCAN_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                selected: action.scan,
            };
            return state;
        case types.SCAN_FORM_ADD_START:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case types.SCAN_FORM_ADD_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                scan: action,
            };
            return state;
        case types.SCANS_GET_START:
            state = {
                ...state,
                isFetching: true,
                scans: [],
            };
            return state;
        case types.SCANS_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                scans: action.scans,
                totalPages: action.scans.length > 0 ? action.scans[0].totalResults / 20 : 0,
            };
            return state;
        default:
            return state;
    }
}
