import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as authActions from '../../actions/authActions.js';
import ForgotPage from '../../components/forgot/ForgotPage';

class ForgotContainer extends Component {

  render() {
    const {
      changeValue,
      resetRequest,
      ...props
    } = this.props;
  
    const { email } = props;

    return (
        <ForgotPage
          {...props}
          onChange={changeValue}
          onResetRequest={() => {
            resetRequest(email).then( response => {
              this.props.history.push('/reset/success');
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    error: state.auth.error,
    email: state.auth.email
  };
}

function mapDispatchToProps(dispatch) {
  return {
    resetRequest: (email) => dispatch(authActions.resetRequest(email)),
    changeValue: (name,value) => dispatch(authActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ForgotContainer);