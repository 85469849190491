import React, { Component } from 'react';
import { withTranslation } from "react-i18next"
import { PulseLoader } from 'react-spinners';
import { MAIN_COLOR }  from '../../constants/constants' 

class ScanExamplePage extends Component {

  state = {
    categories: [],
    hasLoaded: false
  }

  handleSubmit(e) {
    const { onCreate, match } = this.props;
    e.preventDefault();
    onCreate(match.params.id);
  }

  componentWillMount(){
    const { match, onChange, fetchScan } = this.props;
    if(match.params.id != null && !this.state.hasLoaded){
      onChange('name', '');
      onChange('description', '');
      fetchScan(match.params.id);
    } else {
      onChange('name', '');
      onChange('description', '');
      this.setState({
        hasLoaded: true
      })
    }
  }

  componentDidUpdate(){
    if(!this.state.hasLoaded && this.props.scan != null && this.props.match.params.id != null){
      var newCategories = [];
      this.props.scan.categories.sort(this.compareOrder)
      for(var i = 0; i < this.props.scan.categories.length; i++){
        var newQuestions = [];
        this.props.scan.categories[i].questions.sort(this.compareOrder)
        for(var j = 0; j < this.props.scan.categories[i].questions.length; j++){
          newQuestions.push(
            {id: this.props.scan.categories[i].questions[j].id, question: this.props.scan.categories[i].questions[j].question }
          );
        }
        newCategories.push({
          id: this.props.scan.categories[i].id,
          name: this.props.scan.categories[i].name,
          description: this.props.scan.categories[i].description,
          questions: newQuestions
        });
      }
      this.setState({
        categories: newCategories,
        hasLoaded: true,
        canBeDeleted: this.props.scan.canBeDeleted
      });
    }
  }

  render() {

    const {
      t, 
      name, 
      email, 
      message, 
      onChange, 
      isFetching
    } = this.props;

    if(!isFetching){
      return (
        <div className="container">
          <div className="box dash">
            <form onSubmit={(e) => this.handleSubmit(e)}>
              <h1 className="center">{t("scan.example.send")}</h1>
              <div className="input-container">
                <div className="input-group one">
                  <input type="text" placeholder={t("form.label.name")} value={name} onChange={event => {
                    const { value } = event.target;
                    onChange('name', value, event);
                  }}/>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <input type="text" placeholder={`E-mails (comma seperated)`} value={email} onChange={event => {
                    const { value } = event.target;
                    onChange('email', value, event);
                  }}/>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <textarea type="text" placeholder={t("form.label.message")} value={message} onChange={event => {
                    const { value } = event.target;
                    onChange('message', value, event);
                  }}></textarea>
                </div>
              </div>
              {this.state.categories.map((category, i) => 
                <div className="input-container">
                  <div className="input-group one">
                    <b>{category.name}</b> <br />
                    <i>{category.description}</i>
                    <ul>
                      {this.state.categories[i].questions.map((question) =>
                        <li>{question.question}</li>
                      )}
                    </ul>
                </div>
              </div>
              )}
              <div className="input-container margin-top">
                <div className="input-group one right">
                  <div className="submit">
                    <input type="submit" value={t("form.send")} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
        return (
            <div className='content-loader'>
                <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
            </div> 
        );
    }
  }
}
export default withTranslation("translation")(ScanExamplePage)