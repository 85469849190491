import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class FAQPage extends Component {
    state = {
        activeQuestion: "question5",
    };

    makeClickable = () => {
        const items = document.querySelectorAll(".item");
        items.forEach((item) => {
            item.addEventListener("click", (e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({
                    activeQuestion:
                        e.currentTarget.id === this.state.activeQuestion ? "" : e.currentTarget.id,
                });
            });
        });
    };

    componentDidMount() {
        this.makeClickable();

        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        this.makeClickable();

        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector(".banner");

        if (banner != null) {
            banner.style.transform = "translateY(" + top / 5 + "px)";
        }
    }

    render() {
        const { t } = this.props;
        const { activeQuestion } = this.state;
        return (
            <div>
                <section className="banner" style={{ backgroundImage: "url(/images/faq.jpg)" }}>
                    <div className="absolute-center">
                        <h1>{t("faq.header")}</h1>
                    </div>
                </section>
                <div className="faq">
                    <div className="container">
                        <h1>{t("faq.body.header")}</h1>
                        <h2 dangerouslySetInnerHTML={{ __html: t("faq.body.description") }}></h2>
                        <div>
                            <div
                                className={"item " + (activeQuestion === "question5" ? "active" : "")}
                                id="question5"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q1.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q1.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question6" ? "active" : "")}
                                id="question6"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q2.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q2.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question1" ? "active" : "")}
                                id="question1"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q3.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q3.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question2" ? "active" : "")}
                                id="question2"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q4.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q4.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question3" ? "active" : "")}
                                id="question3"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q5.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q5.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question4" ? "active" : "")}
                                id="question4"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q6.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q6.body") }}
                                ></div>
                            </div>
                            <div
                                className={"item " + (activeQuestion === "question7" ? "active" : "")}
                                id="question7"
                            >
                                <div
                                    className="question"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q7.header") }}
                                ></div>
                                <div
                                    className="answer"
                                    dangerouslySetInnerHTML={{ __html: t("faq.q7.body") }}
                                ></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(FAQPage);
