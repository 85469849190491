export const LOGIN_FORM_CHANGE_VALUE = 'LOGIN_FORM_CHANGE_VALUE';
export const LOGIN_FORM_LOGIN_STARTED = 'LOGIN_FORM_LOGIN_STARTED';
export const LOGIN_FORM_LOGIN_SUCCESS = 'LOGIN_FORM_LOGIN_SUCCESS';
export const LOGIN_FORM_LOGIN_FAILURE = 'LOGIN_FORM_LOGIN_FAILURE';

export const RESET_REQUEST_FORM_CHANGE_VALUE = 'RESET_REQUEST_FORM_CHANGE_VALUE';
export const RESET_REQUEST_FORM_RESET_REQUEST_STARTED = 'RESET_REQUEST_FORM_RESET_REQUEST_STARTED';
export const RESET_REQUEST_FORM_RESET_REQUEST_SUCCESS = 'RESET_REQUEST_FORM_RESET_REQUEST_SUCCESS';
export const RESET_REQUEST_FORM_RESET_REQUEST_FAILURE = 'RESET_REQUEST_FORM_RESET_REQUEST_FAILURE';

export const RECOVER_FORM_CHANGE_VALUE = 'RECOVER_FORM_CHANGE_VALUE';
export const RECOVER_FORM_RECOVER_STARTED = 'RECOVER_FORM_RECOVER_STARTED';
export const RECOVER_FORM_RECOVER_SUCCESS = 'RECOVER_FORM_RECOVER_SUCCESS';
export const RECOVER_FORM_RECOVER_FAILURE = 'RECOVER_FORM_RECOVER_FAILURE';

export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const SESSION_FORM_CHANGE_VALUE = 'SESSION_FORM_CHANGE_VALUE';
export const SESSION_FROM_SESSION_STARTED = 'SESSION_FORM_SESSION_STARTED';
export const SESSION_FORM_SESSION_SUCCESS = 'SESSION_FORM_SESSION_SUCCESS';
export const SESSION_FORM_SESSION_FAILURE = 'SESSION_FORM_SESSION_FAILURE';
export const SESSION_FORM_SESSION_FAILURE_SILENT = 'SESSION_FORM_SESSION_FAILURE_SILENT';

export const SESSION_STOP_STARTED = 'SESSION_STOP_STARTED';
export const SESSION_STOP_SUCCESS = 'SESSION_STOP_SUCCESS';
export const SESSION_STOP_FAILURE = 'SESSION_STOP_FAILURE';

export const COMPANY_FORM_CHANGE_VALUE = 'COMPANY_FORM_CHANGE_VALUE';
export const COMPANY_FORM_ADD_START = 'COMPANY_FORM_ADD_START';
export const COMPANY_FORM_ADD_SUCCESS = 'COMPANY_FORM_ADD_SUCCESS';
export const COMPANY_FORM_ADD_FAILURE = 'COMPANY_FORM_ADD_FAILURE';

export const COMPANIES_GET_START = 'COMPANIES_GET_START';
export const COMPANIES_GET_SUCCESS = 'COMPANIES_GET_SUCCESS';
export const COMPANIES_GET_FAILURE = 'COMPANIES_GET_FAILURE';

export const COMPANY_GET_START = 'COMPANY_GET_START';
export const COMPANY_GET_SUCCESS = 'COMPANY_GET_SUCCESS';
export const COMPANY_GET_FAILURE = 'COMPANY_GET_FAILURE';

export const TEAM_FORM_CHANGE_VALUE = 'TEAM_FORM_CHANGE_VALUE';
export const TEAM_FORM_ADD_START = 'TEAM_FORM_ADD_START';
export const TEAM_FORM_ADD_SUCCESS = 'TEAM_FORM_ADD_SUCCESS';
export const TEAM_FORM_ADD_FAILURE = 'TEAM_FORM_ADD_FAILURE';

export const TEAMS_GET_START = 'TEAMS_GET_START';
export const TEAMS_GET_SUCCESS = 'TEAMS_GET_SUCCESS';
export const TEAMS_GET_FAILURE = 'TEAMS_GET_FAILURE';

export const TEAM_GET_START = 'TEAM_GET_START';
export const TEAM_GET_SUCCESS = 'TEAM_GET_SUCCESS';
export const TEAM_GET_FAILURE = 'TEAM_GET_FAILURE';

export const EMPLOYEE_FORM_CHANGE_VALUE = 'EMPLOYEE_FORM_CHANGE_VALUE';
export const EMPLOYEE_FORM_ADD_START = 'EMPLOYEE_FORM_ADD_START';
export const EMPLOYEE_FORM_ADD_SUCCESS = 'EMPLOYEE_FORM_ADD_SUCCESS';
export const EMPLOYEE_FORM_ADD_FAILURE = 'EMPLOYEE_FORM_ADD_FAILURE';

export const EMPLOYEES_GET_START = 'EMPLOYEES_GET_START';
export const EMPLOYEES_GET_SUCCESS = 'EMPLOYEES_GET_SUCCESS';
export const EMPLOYEES_GET_FAILURE = 'EMPLOYEES_GET_FAILURE';

export const REPORT_FORM_CHANGE_VALUE = 'REPORT_FORM_CHANGE_VALUE';
export const REPORT_FORM_ADD_START = 'REPORT_FORM_ADD_START';
export const REPORT_FORM_ADD_SUCCESS = 'REPORT_FORM_ADD_SUCCESS';
export const REPORT_FORM_ADD_FAILURE = 'REPORT_FORM_ADD_FAILURE';

export const REPORTS_GET_START = 'REPORTS_GET_START';
export const REPORTS_GET_SUCCESS = 'REPORTS_GET_SUCCESS';
export const REPORTS_GET_FAILURE = 'REPORTS_GET_FAILURE';

export const REPORT_GET_START = 'REPORT_GET_START';
export const REPORT_GET_SUCCESS = 'REPORT_GET_SUCCESS';
export const REPORT_GET_FAILURE = 'REPORT_GET_FAILURE';

export const POSITION_FORM_CHANGE_VALUE = 'POSITION_FORM_CHANGE_VALUE';
export const POSITION_FORM_ADD_START = 'POSITION_FORM_ADD_START';
export const POSITION_FORM_ADD_SUCCESS = 'POSITION_FORM_ADD_SUCCESS';
export const POSITION_FORM_ADD_FAILURE = 'POSITION_FORM_ADD_FAILURE';

export const POSITIONS_GET_START = 'POSITIONS_GET_START';
export const POSITIONS_GET_SUCCESS = 'POSITIONS_GET_SUCCESS';
export const POSITIONS_GET_FAILURE = 'POSITIONS_GET_FAILURE';

export const POSITION_GET_START = 'POSITION_GET_START';
export const POSITION_GET_SUCCESS = 'POSITION_GET_SUCCESS';
export const POSITION_GET_FAILURE = 'POSITION_GET_FAILURE';

export const DEPARTMENT_FORM_CHANGE_VALUE = 'DEPARTMENT_FORM_CHANGE_VALUE';
export const DEPARTMENT_FORM_ADD_START = 'DEPARTMENT_FORM_ADD_START';
export const DEPARTMENT_FORM_ADD_SUCCESS = 'DEPARTMENT_FORM_ADD_SUCCESS';
export const DEPARTMENT_FORM_ADD_FAILURE = 'DEPARTMENT_FORM_ADD_FAILURE';

export const DEPARTMENTS_GET_START = 'DEPARTMENTS_GET_START';
export const DEPARTMENTS_GET_SUCCESS = 'DEPARTMENTS_GET_SUCCESS';
export const DEPARTMENTS_GET_FAILURE = 'DEPARTMENTS_GET_FAILURE';

export const DEPARTMENT_GET_START = 'DEPARTMENT_GET_START';
export const DEPARTMENT_GET_SUCCESS = 'DEPARTMENT_GET_SUCCESS';
export const DEPARTMENT_GET_FAILURE = 'DEPARTMENT_GET_FAILURE';

export const SCAN_FORM_CHANGE_VALUE = 'SCAN_FORM_CHANGE_VALUE';
export const SCAN_FORM_ADD_START = 'SCAN_FORM_ADD_START';
export const SCAN_FORM_ADD_SUCCESS = 'SCAN_FORM_ADD_SUCCESS';
export const SCAN_FORM_ADD_FAILURE = 'SCAN_FORM_ADD_FAILURE';

export const SCAN_GET_START = 'SCAN_GET_START';
export const SCAN_GET_SUCCESS = 'SCAN_GET_SUCCESS';
export const SCAN_GET_FAILURE = 'SCAN_GET_FAILURE';

export const SCAN_ANSWER_START = 'SCAN_ANSWER_START';
export const SCAN_ANSWER_SUCCESS = 'SCAN_ANSWER_SUCCESS';
export const SCAN_ANSWER_FAILURE = 'SCAN_ANSWER_FAILURE';

export const SCANS_GET_START = 'SCANS_GET_START';
export const SCANS_GET_SUCCESS = 'SCANS_GET_SUCCESS';
export const SCANS_GET_FAILURE = 'SCANS_GET_FAILURE';

export const SESSION_FORM_ADD_START = 'SESSION_FORM_ADD_START';
export const SESSION_FORM_ADD_SUCCESS = 'SESSION_FORM_ADD_SUCCESS';
export const SESSION_FORM_ADD_FAILURE = 'SESSION_FORM_ADD_FAILURE';

export const SESSION_GET_START = 'SESSION_GET_START';
export const SESSION_GET_SUCCESS = 'SESSION_GET_SUCCESS';
export const SESSION_GET_FAILURE = 'SESSION_GET_FAILURE';

export const SESSIONS_GET_START = 'SESSIONS_GET_START';
export const SESSIONS_GET_SUCCESS = 'SESSIONS_GET_SUCCESS';
export const SESSIONS_GET_FAILURE = 'SESSIONS_GET_FAILURE';

export const SESSION_RESEND_EMAIL_START = 'SESSION_RESEND_EMAIL_START';
export const SESSION_RESEND_EMAIL_SUCCESS = 'SESSION_RESEND_EMAIL_SUCCESS';
export const SESSION_RESEND_EMAIL_FAILURE = 'SESSION_RESEND_EMAIL_FAILURE';

export const BLOG_FORM_CHANGE_VALUE = 'ARTICLE_FORM_CHANGE_VALUE';

export const ARTICLE_FORM_CHANGE_VALUE = 'ARTICLE_FORM_CHANGE_VALUE';
export const ARTICLE_FORM_ADD_START = 'ARTICLE_FORM_ADD_START';
export const ARTICLE_FORM_ADD_SUCCESS = 'ARTICLE_FORM_ADD_SUCCESS';
export const ARTICLE_FORM_ADD_FAILURE = 'ARTICLE_FORM_ADD_FAILURE';

export const ARTICLES_GET_START = 'ARTICLES_GET_START';
export const ARTICLES_GET_SUCCESS = 'ARTICLES_GET_SUCCESS';
export const ARTICLES_GET_FAILURE = 'ARTICLES_GET_FAILURE';

export const ARTICLE_GET_START = 'ARTICLE_GET_START';
export const ARTICLE_GET_SUCCESS = 'ARTICLE_GET_SUCCESS';
export const ARTICLE_GET_FAILURE = 'ARTICLE_GET_FAILURE';