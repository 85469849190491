import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    teams: [],
    selected: null,
    company: null,
    name: '',
    members: [],
};

export default function teams(state = initialState, action) {
    switch (action.type) {
        case types.TEAM_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.TEAM_GET_START:
            state = {
                ...state,
                selected: null,
                isFetching: true,
            };
            return state;
        case types.TEAM_GET_SUCCESS:
            state = {
                ...state,
                selected: action.team,
                isFetching: false,
            };
            return state;
        case types.TEAMS_GET_START:
            state = {
                ...state,
                isFetching: true,
                teams: [],
            };
            return state;
        case types.TEAMS_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                teams: action.teams,
            };
            return state;
        default:
            return state;
    }
}
