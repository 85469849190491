import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Price extends Component {

	componentDidMount(){
		window.scrollTo(0, 0);

		window.addEventListener('scroll', this.handleScroll);
	}

	componentWillUnmount() {
		window.addEventListener('scroll', this.handleScroll);
	}

	handleScroll(e) {
		const top  = window.pageYOffset || document.documentElement.scrollTop,
		banner = document.querySelector(".banner");
        if (banner === null) return;
		banner.style.transform = "translateY(" + top / 5 + "px)";
	}
	
	render(){
		const { t } = this.props;
		return (
			<div>
				<section className="banner price-banner" style={{backgroundImage: "url(/images/sparks.jpg)"}}>
					<div className="absolute-center">
						<h1>{t("pricing.header")}</h1>
                        <h2></h2>
					</div>
				</section>
				<section className="pricing" id="price">
                    <div className="container">
                        <div className="prices">
                            <div className="price-block">
                                <h1>{t("pricing.quickscan.header")}</h1>
                                <hr />
                                <div className="price">
                                    <label>{t("pricing.quickscan.per")}</label>
                                    <div className="value">{t("pricing.quickscan.cost")}</div>
                                </div>
                                <p>{t("pricing.quickscan.description")}</p>
                            </div>
                            <div className="price-block">
                                <h1>{t("pricing.dataAnalyse.header")}</h1>
                                <hr />
                                <div className="price">
                                    <label>{t("pricing.dataAnalyse.per")}</label>
                                    <div className="value">{t("pricing.dataAnalyse.cost")}</div>
                                </div>
                                <p>{t("pricing.dataAnalyse.description")}</p>
                            </div>
                        </div>
                    </div>
				</section>
				<section className="cta">
					<div className="container">
						<h1>{t("pricing.cta.header")}</h1>
						<p>{t("pricing.cta.description")}</p>
						<Link to="/demo"><div className="button"><button>{t("pricing.cta.button")}</button></div></Link> 
					</div>
				</section>
			</div>
		);
	}
}
export default withTranslation('translation')(Price);