import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompaniesPage from '../../components/dashboard/CompaniesPage';
import * as companyActions from '../../actions/companyActions';

class CompaniesContainer extends Component {

  render() {
    const {
      changeValue,
      fetchCompanies,
      searchCompanies,
      ...props
    } = this.props;
  
    return (
        <CompaniesPage
          {...props}
          onChange={changeValue}
          fetchCompanies={(page) => 
            fetchCompanies(page)
          }
          searchCompanies={(query, page) => 
            searchCompanies(query, page)
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    page: state.companies.page,
    totalPages: state.companies.totalPages,
    companies: state.companies.companies,
    isFetching: state.companies.isFetching
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(companyActions.changeValue(name, value)),
    fetchCompanies: (page) => dispatch(companyActions.companies(page)),
    searchCompanies: (query, page) => dispatch(companyActions.search(query, page))
  };
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(CompaniesContainer);