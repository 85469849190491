import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddCompanyPage from '../../../components/dashboard/new/AddCompanyPage';
import * as companyActions from '../../../actions/companyActions';

class AddCompanyContainer extends Component {

  render() {
    const {
      changeValue,
      fetchCompany,
      create,
      update,
      ...props
    } = this.props;
    
    const {name, location, description, contactperson, email, phonenumber} = props;

    return (
        <AddCompanyPage
          {...props}
          onChange={changeValue}
          fetchCompany={(id) => 
            fetchCompany(id)
          }
          onCreate={(actions) => {
            create(name, location, description, contactperson, email, phonenumber, actions).then( response => {
              this.props.history.push('/companies');
            })
          }}
          onUpdate={(actions) => {
            update(this.props.match.params.id, name, location, description, contactperson, email, phonenumber, actions).then( response => {
              this.props.history.push('/company/' + response.id);
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.companies.isFetching,
    company: state.companies.selected,
    name: state.companies.name,
    location: state.companies.location,
    description: state.companies.description, 
    contactperson: state.companies.contactperson, 
    email: state.companies.email, 
    phonenumber: state.companies.phonenumber
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompany: (id) => dispatch(companyActions.company(id)), 
    create: (name, location, description, contactperson, email, phonenumber, actions) => dispatch(companyActions.add(name, location, description, contactperson, email, phonenumber, actions)),
    update: (id, name, location, description, contactperson, email, phonenumber, actions) => dispatch(companyActions.update(id, name, location, description, contactperson, email, phonenumber, actions)),
    changeValue: (name, value) => dispatch(companyActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddCompanyContainer);