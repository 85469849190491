import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";
import _ from "lodash";

import Pager from "../ui/Pager";
import { MAIN_COLOR } from "../../constants/constants";

class ArticlesPage extends Component {
    state = {
        query: "",
    };

    componentDidMount() {
        this.load(0, this.state.query);
    }

    load(page, query) {
        const { onChange, searchArticles, fetchArticles } = this.props;
        onChange("page", page);
        if (query != "") {
            searchArticles(query, page);
        } else {
            fetchArticles(page);
        }
    }

    renderArticles() {
        const { t, isFetching, articles, deleteArticle } = this.props;
        if (!isFetching) {
            if (articles.length > 0) {
                return (
                    <div>
                        {articles.map((article) => (
                            <li className="" key={article.id}>
                                <div className="flex-container">
                                    <div className="two">{article.title}</div>
                                    <div className="two">{_(article.description).truncate(20)}</div>
                                    <div className="one">
                                        {moment(article.createdAt.date).format("MMMM Do YYYY")}
                                    </div>
                                    <div className="one">
                                        <Link to={"/edit/article/" + article.id}>{t("edit")}</Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={() => {
                                            deleteArticle(article.id);
                                        }}
                                    >
                                        <a>{t("delete")}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("articles.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t, page, articles } = this.props;
        const { query } = this.state;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">{t("articles.header")}</h1>
                    <Link to="/new/article">
                        <button className="new">{t("article.new")}</button>
                    </Link>
                    <div className="filter">
                        <div className="search">
                            <input
                                type="text"
                                placeholder="Zoek...."
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.setState({
                                        query: value,
                                    });
                                    this.load(query === "" ? 0 : page);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="two">{t("article.title")}</div>
                                    <div className="two">{t("article.description")}</div>
                                    <div className="one">{t("article.creationDate")}</div>
                                    <div className="one"></div>
                                    <div className="one"></div>
                                </div>
                            </li>
                            {this.renderArticles()}
                        </ul>
                    </div>
                </div>
                <Pager
                    {...this.props}
                    items={articles}
                    onReload={(page) => {
                        this.load(page, query);
                    }}
                />
            </div>
        );
    }
}
export default withTranslation("translation")(ArticlesPage);
