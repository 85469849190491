import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as blogActions from '../../../actions/blogActions';
import AddArticlePage from '../../../components/dashboard/new/AddArticlePage';

class AddArticleContainer extends Component {

  render() {
    const {
      changeValue,
      fetchArticle,
      create,
      update,
      ...props
    } = this.props;
    
    const {title, author, description, active, activeFrom, images, body} = props;

    return (
        <AddArticlePage
          {...props}
          onChange={changeValue}
          fetchArticle={(id) => 
            fetchArticle(id)
          }
          onCreate={(images) => {
            create(title, author, description, active, activeFrom, images, body).then( response => {
              this.props.history.push('/articles');
            })
          }}
          onUpdate={(images) => {
            update(this.props.match.params.id, title, author, description, active, activeFrom, images, body).then( response => {
              this.props.history.push('/articles');
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.blogs.isFetching,
    article: state.blogs.article,
    title: state.blogs.title,
    author: state.blogs.author,
    description: state.blogs.description,
    active: state.blogs.active,
    activeFrom: state.blogs.activeFrom,
    images: state.blogs.images,
    body: state.blogs.body
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(blogActions.changeValue(name, value)),
    fetchArticle: (id) => dispatch(blogActions.article(id)), 
    create: (title, author, description, active, activeFrom, images, body) => dispatch(blogActions.add(title, author, description, active, activeFrom, images, body)),
    update: (id, title, author, description, active, activeFrom, images, body) => dispatch(blogActions.update(id, title, author, description, active, activeFrom, images, body)),
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddArticleContainer);