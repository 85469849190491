import React from "react";
import { PulseLoader } from "react-spinners";
import parse from "html-react-parser";

export default class Article extends React.Component {
    componentDidMount() {
        const { fetchArticle, match } = this.props;
        fetchArticle(match.params.id);
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector(".banner");

        if (banner != null) {
            banner.style.transform = "translateY(" + top / 5 + "px)";
        }
    }

    render() {
        const { article, isFetching } = this.props;

        if (!isFetching && article) {
            return (
                <div>
                    <section
                        className="banner"
                        style={
                            article.images[0]
                                ? {
                                      backgroundImage:
                                          "url(" +
                                          process.env.REACT_APP_API_URL +
                                          article.images[0].url +
                                          ")",
                                  }
                                : { backgroundImage: "url(/images/wij.jpg)" }
                        }
                    >
                        <div className="absolute-center">
                            <h1>{article.title}</h1>
                            <h2>{article.description}</h2>
                        </div>
                    </section>
                    <section className="about">
                        <div
                            className="container"
                            dangerouslySetInnerHTML={{ __html: parse(article.body) }}
                        ></div>
                    </section>
                </div>
            );
        }
        return (
            <div className="content-loader">
                <PulseLoader className="spinner" color={"#ffffff"} size={25} />
            </div>
        );
    }
}
