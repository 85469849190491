import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom";

import Pager from "../ui/Pager";
import { MAIN_COLOR } from "../../constants/constants";

class ReportsPage extends Component {
    state = {
        query: "",
    };

    componentDidMount() {
        this.load();
    }

    load(page = 0, query = "") {
        const { onChange, searchReports, fetchReports } = this.props;
        onChange("page", page);
        if (query != "") {
            searchReports(query, page);
        } else {
            fetchReports(page);
        }
    }

    renderReports() {
        const { t, isFetching, reports, removeReport } = this.props;
        if (!isFetching) {
            if (reports.length > 0) {
                return (
                    <div>
                        {reports.map((report) => (
                            <li key={report.id}>
                                <div className="flex-container">
                                    <div className="four">{report.name === null ? "" : report.name}</div>
                                    <div className="two">{moment(report.date.date).format("D-M-Y")}</div>
                                    <div className="one">
                                        <a
                                            onClick={(e) => {
                                                removeReport(report.id);
                                            }}
                                        >
                                            {t("delete")}
                                        </a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("reports.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t, reports, page } = this.props;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">{t("reports.header")}</h1>
                    <Link to="/new/report">
                        <button className="new">{t("report.new")}</button>
                    </Link>
                    <div className="filter">
                        <div className="search">
                            <input
                                type="text"
                                placeholder={t("form.label.search")}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.setState({
                                        query: value,
                                    });
                                    this.load(this.state.query === "" ? 0 : page, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="four">{t("report.name")}</div>
                                    <div className="two">{t("report.creationDate")}</div>
                                    <div className="one"></div>
                                </div>
                            </li>
                            {this.renderReports()}
                        </ul>
                    </div>
                </div>
                <Pager
                    {...this.props}
                    items={reports}
                    onReload={(page) => {
                        this.load(page, this.state.query);
                    }}
                />
            </div>
        );
    }
}
export default withTranslation("translation")(ReportsPage);
