import * as types from '../constants/actionTypes.js';
import { CACHE_DATA } from '../constants/constants.js';
import * as cache from '../utils/cache.js';

export const ROLE_ADMIN = 'admin';

const logoutState = {
    isTryingToLogin: false,
    isLoggedIn: false,
    isFetching: false,
    success: false,
    sessionId: '',
    email: '',
    password: '',
    passwordrepeat: '',
    token: '',
    error: '',
    redirect: '/sessions',
};

const initialState = (cache.read(CACHE_DATA) || {}).auth || logoutState;

export default function auth(state = initialState, action) {
    let cacheData = cache.read(CACHE_DATA) || {};

    switch (action.type) {
        case types.LOGIN_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.LOGIN_FORM_LOGIN_STARTED:
            state = {
                ...state,
                isTryingToLogin: true,
            };
            return state;
        case types.LOGIN_FORM_LOGIN_FAILURE:
            state = {
                ...state,
                isTryingToLogin: false,
                isLoggedIn: false,
                password: '',
                error: 'E-mail en/of wachtwoord is onjuist.',
            };
            return state;
        case types.RESET_REQUEST_FORM_RESET_REQUEST_STARTED:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case types.RESET_REQUEST_FORM_RESET_REQUEST_FAILURE:
            state = {
                ...state,
                isFetching: false,
                error: 'E-mail niet gevonden',
            };
            return state;
        case types.RESET_REQUEST_FORM_RESET_REQUEST_SUCCESS:
            state = {
                ...state,
                success: true,
                isFetching: false,
            };
        case types.LOGIN_FORM_LOGIN_SUCCESS:
            state = {
                ...state,
                isTryingToLogin: false,
                isLoggedIn: action.logged_in,
                sessionId: action.sessionId,
                email: '',
                password: '',
            };

            cacheData.auth = state;
            cache.write(CACHE_DATA, cacheData);
            return state;
        case types.LOGOUT_SUCCESS:
            cache.clear(CACHE_DATA);
            return logoutState;
        default:
            return state;
    }
}
