import i18next from "i18next";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        const { t, i18n, match, isLoggedIn, hasSession } = this.props;
        var footerBlueLinks = ["/", "/producten", "/wat-het-kost"];
        var classes = "";
        if (footerBlueLinks.includes(match.path)) {
            classes += "blue";
        }

        const copyright = () => {
            return (
                <div className="two">
                    &copy; <a href="https://Actional.nl">Actional</a> 2018 - {new Date().getFullYear()} | {t("footer.allRightsReserved")} | <Link to="/privacy">Privacy statement</Link> |{" "}
                    <span className="to-right">
                        A                         <a target="_tab" href="https://kolmans.com">
                            Kolmans B.V.
                        </a> company. Made with <span className="red">&#10084;</span> by{" "} <a target="_tab" href="https://n0name.eu">
                            No Name B.V.
                        </a>
                    </span>
                </div>
            );
        };
        const language = () => {
            return (
                <>
                    {i18n.language !== "nl" && (
                        <div
                            className="as-link"
                            onClick={(e) => {
                                e.preventDefault();
                                i18n.changeLanguage("nl");
                            }}
                        >
                            Nederlands
                        </div>
                    )}
                    {i18n.language !== "en" && (
                        <div
                            className="as-link"
                            onClick={(e) => {
                                e.preventDefault();
                                i18n.changeLanguage("en");
                            }}
                        >
                            English
                        </div>
                    )}
                    |
                </>
            );
        };

        if (isLoggedIn) {
            return (
                <footer className={classes}>
                    <div className="container flex-container">
                        {copyright()}
                        <div className="one links">
                            {language()}

            <Link to="/logout">{t("footer.logout")}</Link>
                        </div>
                    </div>
                </footer>
            );
        } else if (hasSession) {
            return (
                <footer className={classes}>
                    <div className="container flex-container">
                        {copyright()}
                        <div className="one links">
                            {language()}
                            <Link to="/stop">{t("footer.stopSession")}</Link> | <Link to="/contact">{t("footer.contact")}</Link>
                        </div>
                    </div>
                </footer>
            );
        }
        return (
            <footer className={classes}>
                <div className="container flex-container">
                    {copyright()}
                    <div className="one links">
                        {language()}
                        <Link to="/login">{t("footer.login")}</Link> | <Link to="/contact">{t("footer.contact")}</Link>
                    </div>
                </div>
            </footer>
        );
    }
}

function mapStateToProps(state, ownProps) {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        hasSession: state.auth.hasSession,
        ...ownProps,
    };
}

function mapDispatchToProps() {
    return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation("translation")(Footer));
