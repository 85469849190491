import * as types from '../constants/actionTypes.js';
import * as sessionApi from '../api/sessionApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.SESSION_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.SESSION_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.SESSION_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.SESSION_FORM_ADD_FAILURE,
    ...error
});

export const add = (name, company, team, type, instruction, startDate, endDate, positions) => {
    return (dispatch) => {
        dispatch(startAdd());
        const promise = sessionApi.addSession(name, company, team, type, instruction, startDate, endDate, positions);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const update = (id, name, type, instruction, startDate, endDate, scans) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = sessionApi.updateSession(id, name, type, instruction, startDate, endDate, scans);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = sessionApi.deleteSession(id);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const invite = (id) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = sessionApi.inviteSession(id);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
}

export const startSession = () => ({
    type: types.SESSION_FROM_SESSION_STARTED
});

export const sessionSuccess = (result) => ({
    type: types.SESSION_FORM_SESSION_SUCCESS,
    ...result
});

export const sessionFailure = (error) => ({
    type: types.SESSION_FORM_SESSION_FAILURE,
    ...error
});

export const sessionFailureSilent = (error) => ({
    type: types.SESSION_FORM_SESSION_FAILURE_SILENT,
    ...error
});

export const start = (token, firstname, lastname, birthday, education, supervisor_email) => {
    return (dispatch) => {
        dispatch(startSession());

        const promise = sessionApi.startSession(token, firstname, lastname, birthday, education, supervisor_email);
        
        promise.then(result => {
            dispatch(sessionSuccess(result))
        }).catch(error => {
            dispatch(sessionFailure(error))
        });

        return promise;
    }
}

export const tryStart = (token) => {
    return (dispatch) => {
        dispatch(startSession());

        const promise = sessionApi.tryStartSession(token);
        
        promise.then(result => {
            dispatch(sessionSuccess(result))
        }).catch(error => {
            dispatch(sessionFailureSilent(error))
        });

        return promise;
    }
}


/**
 * Stop session actions
 */
export const startStop = () => ({
    type: types.SESSION_STOP_STARTED
});

export const stopSuccess = (result) => ({
    type: types.SESSION_STOP_SUCCESS,
    ...result
});

export const stopFailure = (error) => ({
    type: types.SESSION_STOP_FAILURE,
    ...error
});

export const stop = () => {
    return (dispatch) => {
        dispatch(startStop());

        const promise = sessionApi.stopSession();
        
        promise.then(result => {
            dispatch(stopSuccess(result))
        }).catch(error => {
            dispatch(stopFailure(error))
        });

        return promise;
    }
}

export const startGet = () => ({
    type: types.SESSION_GET_START
});

export const getSuccess = (result) => ({
    type: types.SESSION_GET_SUCCESS,
    session: result
});

export const getFailure = (error) => ({
    type: types.SESSION_GET_FAILURE,
    ...error
});

export const session = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = sessionApi.getSession(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}

export const startSessionsGet = () => ({
    type: types.SESSIONS_GET_START
});

export const getSessionsSuccess = (result) => ({
    type: types.SESSIONS_GET_SUCCESS,
    sessions: [...result]
});

export const getSessionsFailure = (error) => ({
    type: types.SESSIONS_GET_FAILURE,
    ...error
});

export const sessions = (page = 0) => {
    return (dispatch) => {
        dispatch(startSessionsGet());

        const promise = sessionApi.getSessions(page + 1);
        
        promise.then(result => {
            dispatch(getSessionsSuccess(result))
        }).catch(error => {
            dispatch(getSessionsFailure(error))
        });

        return promise;
    }
}

export const search = (query = '', page = 0) => {
    return (dispatch) => {
        dispatch(startSessionsGet());

        const promise = sessionApi.searchSessions(query, page + 1);
        
        promise.then(result => {
            dispatch(getSessionsSuccess(result))
        }).catch(error => {
            dispatch(getSessionsFailure(error))
        });

        return promise;
    }
}

export const teamSessions = () => {
    return (dispatch) => {
        dispatch(startSessionsGet());

        const promise = sessionApi.getTeamSessions();
        
        promise.then(result => {
            dispatch(getSessionsSuccess(result))
        }).catch(error => {
            dispatch(getSessionsFailure(error))
        });

        return promise;
    }
}