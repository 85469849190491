import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    reports: [],
    page: 0,
    totalPages: 0,
    selected: null,
    startDate: Date(),
    endDate: Date(),
};

export default function positions(state = initialState, action) {
    switch (action.type) {
        case types.REPORT_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.REPORT_GET_START:
            state = {
                ...state,
                selected: null,
                isFetching: true,
            };
            return state;
        case types.REPORT_GET_FAILURE:
            state = {
                ...state,
                isFetching: false,
                selected: null,
            };
            return state;
        case types.REPORT_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                selected: action.report,
            };
            return state;
        case types.REPORTS_GET_START:
            state = {
                ...state,
                isFetching: true,
                reports: [],
            };
            return state;
        case types.REPORTS_GET_FAILURE:
            state = {
                ...state,
                isFetching: false,
                reports: [],
            };
            return state;
        case types.REPORTS_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                reports: action.reports,
                totalPages: action.reports.length > 0 ? action.reports[0].totalResults / 20 : 0,
            };
            return state;
        case types.REPORT_FORM_ADD_START:
            state = {
                ...state,
                isFetching: true,
            };
            return state;
        case types.REPORT_FORM_ADD_FAILURE:
            state = {
                ...state,
                isFetching: false,
            };
            return state;
        case types.REPORT_FORM_ADD_SUCCESS:
            state = {
                ...state,
                isFetching: false,
            };
            return state;
        default:
            return state;
    }
}
