import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { Link } from "react-router-dom";
import moment from "moment";

import Pager from "../ui/Pager";
import { MAIN_COLOR } from "../../constants/constants";

class Companies extends Component {
    state = {
        query: "",
    };
    componentDidMount() {
        this.load(0, this.state.query);
    }

    load(page, query) {
        const { onChange, searchCompanies, fetchCompanies } = this.props;
        onChange("page", page);
        if (query !== "") {
            searchCompanies(query, page);
        } else {
            fetchCompanies(page);
        }
    }

    renderCompanies() {
        const { t, isFetching, companies } = this.props;
        if (!isFetching) {
            if (companies.length > 0) {
                return (
                    <div>
                        {companies.map((company) => (
                            <Link to={"/company/" + company.id} key={company.id}>
                                <li className="clickable">
                                    <div className="flex-container">
                                        <div className="two">{company.name}</div>
                                        <div className="two">{company.location}</div>
                                        <div className="two">
                                            <a href={"mailto:" + company.email}>{company.email}</a>
                                        </div>
                                        <div className="one">
                                            {moment(company.created.date).format("MMMM Do YYYY")}
                                        </div>
                                    </div>
                                </li>
                            </Link>
                        ))}
                    </div>
                );
            }
            return <li>{t("companies.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t, page, companies } = this.props;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">{t("companies.header")}</h1>
                    <Link to="/new/company">
                        <button className="new">{t("company.new")}</button>
                    </Link>
                    <div className="filter">
                        <div className="search">
                            <input
                                type="text"
                                placeholder={t("form.label.search")}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.setState({
                                        query: value,
                                    });
                                    this.load(this.state.query === "" ? 0 : page, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="two">{t("company.name")}</div>
                                    <div className="two">{t("company.location")}</div>
                                    <div className="two">{t("company.contactEmail")}</div>
                                    <div className="one">{t("company.creationDate")}</div>
                                </div>
                            </li>
                            {this.renderCompanies()}
                        </ul>
                    </div>
                </div>
                <Pager
                    {...this.props}
                    items={companies}
                    onReload={(page) => {
                        this.load(page, this.state.query);
                    }}
                />
            </div>
        );
    }
}
export default withTranslation("translation")(Companies);
