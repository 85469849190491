import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditSessionPage from '../../../components/dashboard/edit/EditSessionPage';
import * as sessionActions from '../../../actions/sessionActions';

class EditSessionContainer extends Component {

  render() {
    const {
      changeValue,
      invite,
      update,
      fetch,
      ...props
    } = this.props;
    
    return (
        <EditSessionPage
          {...props}
          onChange={changeValue}
          onFetch={(id) => {
            fetch(id)
          }}
          onUpdate={() => {
            update(
              this.props.session.id, 
              this.props.name, 
              this.props.type ? this.props.type.value : 0,
              this.props.instruction,
              new Date(this.props.startDate), 
              new Date(this.props.endDate), 
              this.props.scans.map((scan) => scan.value),
            ).then( response => {
              this.props.history.push('/sessions');
            });
          }}
          onInvite={() => {
            invite(
              this.props.session.id
            ).then();
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetchingSession: state.sessions.isFetching,
    name: state.sessions.name,
    type: state.sessions.type,
    instruction: state.sessions.instruction,
    startDate: state.sessions.startDate,
    endDate: state.sessions.endDate,
    session: state.sessions.selected,
    scans: state.sessions.scans,
    company: state.sessions.company,
    position: state.sessions.position
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetch: (id) => dispatch(sessionActions.session(id)),
    invite: (id) => dispatch(sessionActions.invite(id)),
    update: (id, name, type, instruction, startDate, endDate, scan) => dispatch(sessionActions.update(id, name, type, instruction, startDate, endDate, scan)),
    changeValue: (name, value) => dispatch(sessionActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(EditSessionContainer);