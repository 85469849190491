import * as types from '../constants/actionTypes.js';
import * as employeeApi from '../api/employeeApi';

export const startAdd = () => ({
    type: types.EMPLOYEE_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.EMPLOYEE_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.EMPLOYEE_FORM_ADD_FAILURE,
    ...error
});

export const save = (employees) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = employeeApi.saveEmployees(employees);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};


export const startPositionsEmployeesGet = () => ({
    type: types.EMPLOYEES_GET_START
});

export const getPositionsEmployeesSuccess = (result) => ({
    type: types.EMPLOYEES_GET_SUCCESS,
    employees: [...result]
});

export const getPositionsEmployeesFailure = (error) => ({
    type: types.EMPLOYEES_GET_FAILURE,
    ...error
});

export const employees = () => {
    return (dispatch) => {
        dispatch(startPositionsEmployeesGet());

        const promise = employeeApi.getEmployees();
        
        promise.then(result => {
            dispatch(getPositionsEmployeesSuccess(result))
        }).catch(error => {
            dispatch(getPositionsEmployeesFailure(error))
        });

        return promise;
    }
}