import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Product extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    handleScroll(e) {
        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector('.banner');

        if (banner != null) {
            banner.style.transform = 'translateY(' + top / 5 + 'px)';
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <section className="banner product-banner" style={{ backgroundImage: 'url(/images/products.jpg)' }}>
                    <div className="absolute-center">
                        <h1>{t('products.top.header')}</h1>
                        <h2>{t('products.top.description')}</h2>
                    </div>
                </section>
                <section className="product-page" id="product-page">
                    <div className="products">
                        <div className="flex-container container">
                            <div className="product one">
                                <img src="/images/levels.svg" />
                                <h1 className="number">1</h1>
                                <h2 className="center">{t('products.quickscan.header')}</h2>
                                <p className="left">{t('products.quickscan.description')}</p>
                            </div>
                            <div className="product-example one right">
                                <img src="/images/3d-questions.png" />
                            </div>
                        </div>
                        <div className="flex-container container padding middle">
                            <div className="product-example one left">
                                <img src="/images/3d-data-analyses.png" />
                            </div>
                            <div className="product one">
                                <img src="/images/data.svg" />
                                <h1 className="number">2</h1>
                                <h2 className="center">{t('products.dataAnalyses.header')}</h2>
                                <p className="left">{t('products.dataAnalyses.description')}</p>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="cta">
                    <div className="container">
                        <h1>{t('products.cta.header')}</h1>
                        <p>{t('products.cta.description')}</p>
                        <Link to="/hoe-het-werkt">
                            <div className="button">
                                <button>{t('products.cta.button')}</button>
                            </div>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation('translation')(Product);
