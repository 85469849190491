import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddSessionPage from '../../../components/dashboard/new/AddSessionPage';
import * as sessionActions from '../../../actions/sessionActions';

class AddSessionContainer extends Component {

  render() {
    const {
      changeValue,
      create,
      update,
      ...props
    } = this.props;
    
    return (
        <AddSessionPage
          {...props}
          onChange={changeValue}
          onCreate={(positions) => {
            create(
              this.props.name, 
              (this.props.company != null) ? this.props.company.value : "", 
              (this.props.team != null) ? this.props.team.value : "", 
              this.props.type ? this.props.type.value : 0,
              this.props.instruction,
              this.props.startDate, 
              this.props.endDate, 
              positions
            ).then( response => {
              this.props.history.push('/sessions');
            })
          }}
          onUpdate={(positions) => {
            update(
              this.props.session.id, 
              this.props.name, 
              (this.props.company != null) ? this.props.company.value : "", 
              (this.props.team != null) ? this.props.team.value : "", 
              this.props.type ? this.props.type.value : 0,
              this.props.instruction,
              this.props.startDate, 
              this.props.endDate, 
              positions
            ).then( response => {
              this.props.history.push('/sessions');
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetchingSession: state.sessions.isFetching,
    name: state.sessions.name,
    session: state.sessions.selected,
    company: state.sessions.company,
    team: state.sessions.team,
    type: state.sessions.type,
    instruction: state.sessions.instruction,
    startDate: state.sessions.startDate,
    endDate: state.sessions.endDate,
    positions: state.sessions.positions
  };
}

function mapDispatchToProps(dispatch) {
  return {
    create: (name, company, team, type, instruction, startDate, endDate, positions) => dispatch(sessionActions.add(name, company, team, type, instruction, startDate, endDate, positions)),
    update: (id, name, company, team, type, instruction, startDate, endDate, positions) => dispatch(sessionActions.update(id, name ,company, team, type, instruction, startDate, endDate, positions)),
    changeValue: (name, value) => dispatch(sessionActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddSessionContainer);