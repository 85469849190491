import Promise from "promise";
import handleError from "../utils/responseMiddleware";

const env = process.env;

export function getArticle(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(env.REACT_APP_API_URL + "/article/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getArticles(page, blog = "default") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(env.REACT_APP_API_URL + "/articles/" + blog + "/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchArticles(query, page, blog = "default") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(env.REACT_APP_API_URL + "/search/articles/" + blog + "/" + query + "/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addArticle(
    title = " ",
    author = " ",
    description = " ",
    active = true,
    activeFrom = new Date(),
    images = [],
    body = " "
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(env.REACT_APP_API_URL + "/article")
            .set("Accept", "application/json")
            .withCredentials()
            .field("blog", "default")
            .field("title", title)
            .field("author", author)
            .field("description", description)
            .field("active", active)
            .field("activeFrom", activeFrom.toISOString())
            .field("images", JSON.stringify(images.map((image) => image.url)))
            .field("body", body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function updateArticle(
    id,
    title = "",
    author = "",
    description = "",
    active = true,
    activeFrom = new Date(),
    images = [],
    body = ""
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(env.REACT_APP_API_URL + "/article/update")
            .set("Accept", "application/json")
            .withCredentials()
            .field("blog", "default")
            .field("id", id)
            .field("title", title)
            .field("author", author)
            .field("description", description)
            .field("active", active)
            .field("activeFrom", activeFrom.toISOString())
            .field("images", JSON.stringify(images.map((image) => image.url)))
            .field("body", body)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function deleteArticle(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(env.REACT_APP_API_URL + "/article/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function uploadImage(base64) {
    var byteCharacters = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ""));
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], {
        type: undefined,
    });

    var formData = new FormData();
    formData.append("picture", blob);
    return new Promise((resolve, reject) => {
        window.superagent
            .post(env.REACT_APP_API_URL + "/upload/image")
            .send(formData)
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res.text));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
