import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function startSession(
    token = "",
    firstname = "",
    lastname = "",
    birthday = new Date(),
    education = "",
    supervisor_email = ""
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/start")
            .set("Accept", "application/json")
            .withCredentials()
            .field("token", token)
            .field("firstname", firstname)
            .field("lastname", lastname)
            .field("birthday", birthday.toISOString())
            .field("education", education)
            .field("supervisor_email", supervisor_email)
            .field("startDate", new Date().toISOString())
            .end(function (err, res) {
                var json = JSON.parse(res.text);
                if (err || !res.ok || !json.success) {
                    reject(handleError(res));
                } else {
                    resolve(json);
                }
            });
    });
}

export function tryStartSession(token = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/try/start")
            .set("Accept", "application/json")
            .withCredentials()
            .field("token", token)
            .end(function (err, res) {
                var json = JSON.parse(res.text);
                if (err || !res.ok || !json.success) {
                    reject(handleError(res));
                } else {
                    resolve(json);
                }
            });
    });
}

export function stopSession() {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/stop")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getSessions(page = 0) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/sessions/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchSessions(query = "", page = 1) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/sessions/search/" + query + "/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    return reject(res.text);
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
export function getTeamSessions() {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/team/sessions")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getSession(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/session/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function deleteSession(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/session/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function inviteSession(id) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/session/" + id + "/invite")
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addSession(
    name = "",
    company = null,
    team = null,
    type = 0,
    instruction = "",
    startDate = new Date(),
    endDate = new Date(),
    positions = []
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/session")
            .set("Accept", "application/json")
            .withCredentials()
            .field("name", name)
            .field("company", company === null ? "" : company)
            .field("team", team === null ? "" : team)
            .field("type", type)
            .field("instruction", instruction)
            .field("startDate", startDate.toISOString())
            .field("endDate", endDate.toISOString())
            .field("positions", JSON.stringify(positions))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
export function updateSession(
    id = "",
    name = "",
    type = 0,
    instruction = "",
    startDate = Date(),
    endDate = Date(),
    scans = []
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/update/session")
            .set("Accept", "application/json")
            .withCredentials()
            .field("id", id)
            .field("name", name)
            .field("type", type)
            .field("instruction", instruction)
            .field("startDate", startDate.toISOString())
            .field("endDate", endDate.toISOString())
            .field("scans", JSON.stringify(scans))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
