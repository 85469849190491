import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';import Select from 'react-select';
import { MAIN_COLOR }  from '../../../constants/constants' 
import { PulseLoader } from 'react-spinners';
import AsyncSelect  from 'react-select/lib/Async';
import * as companyApi from '../../../api/companyApi';

export const  typeOptions = [
  { value: 0, label: '1-5 schaal'}, 
  { value: 1, label: '1-7 schaal' }
];

class AddScanPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      hasLoaded: false,
      defaultCompanies: [],
      defaultPositions: [],
      defaultCompany: null,
      defaultPosition: null,
      canBeDeleted: true
    }
    this.selectedCompany = null;
  }

  renderWarning(){
    const { t } = this.props;
    if(!this.state.canBeDeleted){
      return ( <div className="warning"><b>{t("warning")}</b> {t("scan.inUse")}</div> );
    }
  }

  handleSubmit(e, onSuccess) {
    e.preventDefault();
    const {categories} = this.state;
    if(this.props.match.params.id != null){
      this.props.onUpdate(categories, onSuccess);
    } else {
      this.props.onCreate(categories, onSuccess);
    }
  }

  handleCategoryChange(e, idx, newCategorie) {
    const newCategories = this.state.categories.map((category, sidx) => {
      if (idx !== sidx) return category;
      return newCategorie;
    });
    this.setState({ members: newCategories });
  }

  handleRemoveCategory(e, idx){
    this.setState({ categories: this.state.categories.filter((s, sidx) => idx !== sidx) });
  }

  handleAddCategory(){
    this.setState({ 
      categories: this.state.categories.concat([{
        id: null,         
        name: '',
        questions: [{
          id: null,
          question: ''
        }]
      }])
    });
  }

  handleQuestionChange(e, idx, newCategory, cdx, newQuestion){
    const newCategories = this.state.categories.map((category, sidx) => {
      if (idx !== sidx) return category;
      const newQuestions = newCategory.questions.map((question, cidx) => {
        if(cdx !== cidx) return question
        return newQuestion
      })
      newCategory.questions = newQuestions
      return newCategory;
    });
    this.setState({ members: newCategories });
  }

  handleAddQuestion(e, idx, newCategory){
    let questionsElement = document.getElementById("category-"+idx);
    if(!questionsElement.classList.contains("active")){
      questionsElement.classList.add("active");
    }
    const newCategories = this.state.categories.map((category, sidx) => {
      if (idx !== sidx) return category;
      newCategory.questions = newCategory.questions.concat([{
        id: null,
        question: ''
      }]);

      return newCategory;
    });
    this.setState({ members: newCategories });
  }

  handleRemoveQuestion(e, idx, cdx, newCategory){
    const newCategories = this.state.categories.map((category, sidx) => {
      if (idx !== sidx) return category;
      newCategory.questions = newCategory.questions.filter((s, qidx) => cdx !== qidx)
      return newCategory;
    });
    this.setState({ members: newCategories });
  }

  handleShowQuestions(e, idx){
      let questionsElement = document.getElementById("category-"+idx);
      if(questionsElement.classList.contains("active")){
        questionsElement.classList.remove("active");
      } else {
        questionsElement.classList.add("active");
      }
  }

  compareOrder(a, b) {
    return a.order - b.order;
  }

  componentWillMount(){
    if(this.props.match.params.id != null && !this.state.hasLoaded){
      this.props.fetchScan(this.props.match.params.id);
    } else {
      this.props.onChange('scan', null);
      this.props.onChange('name', '');
      this.props.onChange('description', '');
      this.props.onChange('company', null);
      this.props.onChange('position', null)
      this.setState({
        hasLoaded: true
      })
    }
  }

  componentDidUpdate(){
    if(!this.state.hasLoaded && this.props.scan != null && this.props.match.params.id != null){

      this.props.onChange('name', this.props.scan.name);
      this.props.onChange('description', this.props.scan.description);
      this.props.onChange('position', this.props.scan.position);
      this.props.onChange('type', typeOptions[this.props.scan.type]);
      this.selectedCompany = this.props.scan.company;
      var _this = this;
      if(this.selectedCompany != null){
        this.refs.selectPosition.loadOptions("", function(values) {
          _this.setState({
            defaultPositions: values
          });
        });
      }
      companyApi.getCompany(this.props.scan.company).then(response => {
        this.props.onChange('company', {value: this.props.scan.company, label: response.name});

      });
      companyApi.getPosition(this.props.scan.position).then(response => {
        this.props.onChange('position', {value: this.props.scan.position, label: response.name});
      });

      var newCategories = [];
      this.props.scan.categories.sort(this.compareOrder)
      for(var i = 0; i < this.props.scan.categories.length; i++){
        var newQuestions = [];
        this.props.scan.categories[i].questions.sort(this.compareOrder)
        for(var j = 0; j < this.props.scan.categories[i].questions.length; j++){
          newQuestions.push(
            {id: this.props.scan.categories[i].questions[j].id, question: this.props.scan.categories[i].questions[j].question }
          );
        }
        newCategories.push({
          id: this.props.scan.categories[i].id,
          name: this.props.scan.categories[i].name,
          description: this.props.scan.categories[i].description,
          questions: newQuestions
        });
      }
      this.setState({
        categories: newCategories,
        hasLoaded: true,
        canBeDeleted: this.props.scan.canBeDeleted
      });
    }
  }

  componentDidMount(){
    var _this = this;
    this.refs.selectCompany.loadOptions("", function(values) {
      _this.setState({
        defaultCompanies: values,
        defaultPositions: []
      });
      this.selectedCompany = null;
    });
  }

  renderTypeSelect() {
    const {
      t,
			onChange
    } = this.props;

    return (
      <div className="input-container">
        <div className="input-group one">
          <Select
            placeholder="Selecteer schaal"
            isDisabled={!this.state.canBeDeleted}
            value={this.props.type}
            defaultValue={this.props.type || typeOptions[1]}
            onChange={event => {
              onChange('type', event, event);
            }}
            options={typeOptions}
            defaultOptions={typeOptions}
          />
        </div>
      </div>
    );
  }
  
  render() {

    const {
      t,
      onChange,
      isFetching,
    } = this.props;

    if(!isFetching){

      return (
        <div className="container">
          <div className="box dash">
            { this.renderWarning() }
            <form className="new-scan">
              <h1 className="center">{ (this.props.match.params.id != null ? t("update") : t("new")) + " scan." }</h1>
              <button className="new" onClick={(e) => {
                e.preventDefault();
                this.handleSubmit(e, () => {
                  this.props.history.push('/scan/' + this.props.scan.id + '/example');
                });
              }}>{t("form.sendExample")}</button>
              <div className="input-container">
                <div className="input-group one">
                  <input type="text" placeholder={`Naam`} value={this.props.name} onChange={event => {
                    const { value } = event.target;
                    onChange('name', value, event);
                  }}/>
                </div>
              </div>
              <div className="input-container">
                <div className="input-group one">
                  <AsyncSelect 
                    ref="selectCompany"
                    placeholder={this.state.defaultCompany != null ? this.state.defaultCompany : t("form.label.selectCompany")}
                    onChange={event => {
                      const { value } = event;
                      this.selectedCompany = value;
                      var _this = this;
                      this.refs.selectPosition.loadOptions("", function(values) {
                        _this.setState({
                          defaultPositions: values, 
                          defaultPosition: null,
                          defaultCompany: null
                        });
                      });
                      onChange('company', event, event);
                      onChange('position', null);
                    }}
                    value={this.props.company}
                    loadOptions = { (inputValue, callback) => {
                      if(inputValue === ''){
                        companyApi.getCompanies().then(response => {
                          var values = [];
                          for(var i = 0; i < response.length; i++){
                            values.push({
                              'value': response[i].id, 'label': response[i].name
                            });
                          }
                          callback(values)
                        })   
                      } else {
                        companyApi.searchCompanies(inputValue).then(response => {
                          var values = [];
                          for(var i = 0; i < response.length; i++){
                            values.push({
                              'value': response[i].id, 'label': response[i].name
                            });
                          }
                          callback(values)
                        })
                      }
 
                    }}
                    defaultOptions={this.state.defaultCompanies}
                    autoload={true}
                  />
                </div>
                <div className="input-group one">
                  <AsyncSelect
                    ref="selectPosition"
                    placeholder={this.state.defaultPosition != null ? this.state.defaultPosition : t("form.label.selectPosition") }
                    isDisabled ={(this.props.company === null)}
                    onChange={event => {
                      onChange('position', event, event);
                    }}
                    loadOptions = { (inputValue, callback) => {
                      if(inputValue === ''){
                        companyApi.getPositions(this.selectedCompany).then(response => {
                          var values = [];
                          for(var i = 0; i < response.length; i++){
                            values.push({
                              'value': response[i].id, 'label': response[i].name
                            });
                          }
                          callback(values)
                        })
                      } else {
                        companyApi.searchPositions(this.props.company.value, inputValue).then(response => {
                          var values = [];
                          for(var i = 0; i < response.length; i++){
                            values.push({
                              'value': response[i].id, 'label': response[i].name
                            });
                          }
                          callback(values)
                        })
                      }
                    }}
                    value={this.props.position}
                    defaultOptions={this.state.defaultPositions}
                    autoload={true}
                  />
                </div>
              </div>
              {this.renderTypeSelect()}
              <div className="input-container">
                <div className="input-group one">
                  <textarea type="text" placeholder={`Beschrijving`} value={this.props.description}  onChange={event => {
                    const { name, value } = event.target;
                    onChange('description', value, event);
                  }}/>
                </div>
              </div>
              {this.state.categories.map((category, idx) => (
                <div key={idx}>
                  <div className="input-container category active" id={"category-" + idx}>
                    <div className="input-group five">
                      <textarea tabindex={1 + (idx * 1000)} type="text" placeholder={t("form.label.categoryName")} value={category.name} onChange={(e) => this.handleCategoryChange(e, idx, function(){
                        category.name = e.target.value
                        return category;
                      }())} />
                      <textarea tabindex={1 + (idx * 1000)} type="text" placeholder={t("form.label.categoryDescription")} value={category.description} onChange={(e) => this.handleCategoryChange(e, idx, function(){
                        category.description = e.target.value
                        return category;
                      }())} />
                    </div>
                    <div className="input-group one">
                      <button type="button" onClick={(e) => this.handleRemoveCategory(e, idx)} className="delete"></button>
                    </div>
                    <div className="input-group one">
                      <button type="button" onClick={(e) => this.handleAddQuestion(e, idx, category)} className="add"></button>
                    </div>
                    <div className="input-group one">
                      <button type="button" onClick={(e) => this.handleShowQuestions(e, idx)} className="children"></button>
                    </div>
                  </div>
                  <div className="questions">
                    {category.questions.map((question, cdx) => (
                      <div className="input-container" key={cdx}>
                        <div className="input-group four">
                          <textarea tabIndex={1 + (idx * 1000) + cdx} type="text" placeholder={t("form.label.question")} value={question.question} onChange={(e) => this.handleQuestionChange(e, idx, category, cdx, function(){
                            question.question = e.target.value
                            return question;
                          }())} />
                        </div>
                        <div className="input-group one">
                          <button type="button" onClick={(e) => this.handleRemoveQuestion(e, idx, cdx, category)} className="delete"></button>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}

              <div className="input-container margin-top">
                <div className="input-group one">
                  <div className="add">
                    <button type="button" onClick={(e) => this.handleAddCategory(e)} className="small">{t("category")}</button>
                  </div>
                </div>
                <div className="input-group one right">
                  <div className="submit">
                    <input type="submit" value={t("form.save")} onClick={(e) => this.handleSubmit(e, () => {
                      this.props.history.push('/scans');
                    })} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      );
    } else {
			return (
				<div className='content-loader'>
				  <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
				</div> 
			);
    }
  }
}
export default withTranslation("translation")(AddScanPage);