import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import _ from "underscore";
import { Line } from "react-chartjs-2";
import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";
import moment from "moment";

class EmployeeDevelopReport extends Component {
    state = {
        hasLoaded: false,
        answers: [],
        parts: [],
        responders: [],
        tab: "results",
    };

    arrayToFractileArray(array) {
        var newArray = [];
        for (var i = array.length - 1; i >= 0; i--) {
            newArray[i] = 1;
        }
        return newArray;
    }

    calculatePercentage(fractile, from) {
        return (fractile / from) * 100;
    }

    compareOrder(a, b) {
        return a.order - b.order;
    }

    truncate(str, num) {
        return str.length > num ? str.slice(0, num) + "..." : str;
    }

    average(arr) {
        return arr.reduce((p, c) => p + c, 0) / arr.length;
    }

    getAnswers(question, responder) {
        const parts = _.sortBy(this.state.parts, (part) => part.respondent.experationDate.date);
        let answers = [];
        for (let k = 0; k < parts.length; k++) {
            parts[k].session.scans.forEach((scan) => {
                scan.categories.sort(this.compareOrder);
                for (let i = 0; i < scan.categories.length; i++) {
                    scan.categories[i].questions.sort(this.compareOrder);
                    for (let j = 0; j < scan.categories[i].questions.length; j++) {
                        if (scan.categories[i].questions[j].id === question.id) {
                            const answersObject = scan.categories[i].questions[j].answers;
                            const answer = answersObject.find((answer) =>
                                responder.sessions
                                    .map((session) => session.id)
                                    .includes(answer.respondentSession)
                            );
                            if (this.props.report.subject === parts[k].session.id) {
                                answers.unshift(answer === null ? 0 : answer.answer);
                            } else {
                                answers.push(answer === null ? 0 : answer.answer);
                            }
                        }
                    }
                }
            });
        }
        return answers;
    }

    renderAdvice(advice, questions) {
        if (questions.length > 0) {
            return (
                <div>
                    <div className="advice">{advice}</div>
                    {questions.map(function (question, index) {
                        return <div className="question">{question.question}</div>;
                    })}
                </div>
            );
        }
    }

    createAnswerData(responder) {
        var answers = {
            subject: [],
            respondent: [],
        };

        var categories = this.state.parts[0].categories;
        for (var i = 0; i < categories.length; i++) {
            for (var j = 0; j < categories[i].questions.length; j++) {
                var question = categories[i].questions[j];
                var answer = this.getAnswers(question, responder);

                answers.subject.push(Math.round(parseFloat(answer[0])));
                answers.respondent.push(Math.round(parseFloat(answer[1])));
            }
        }
        return answers;
    }

    hexToRGB(hex, alpha) {
        var r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
        if (alpha) {
            return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
        } else {
            return "rgb(" + r + ", " + g + ", " + b + ")";
        }
    }

    createDataSet(responder, firstColor = "#f68800", secondColor = "#94c13c") {
        var answers = this.createAnswerData(responder);
        var datasets = [];
        for (var k = 0; k < this.state.parts.length; k++) {
            var part = this.state.parts[k];
            var fullname =
                responder.employee.firstname +
                " " +
                responder.employee.lastname +
                " | " +
                moment(part.respondent.experationDate.date, "YYYY-MM-DD HH:mm:ss.SSS").format("DD-MM-YYYY");
            datasets.push({
                label: fullname === " " ? "Geen naam" : fullname,
                fill: true,
                backgroundColor: k === 0 ? this.hexToRGB(firstColor, 0.4) : this.hexToRGB(secondColor, 0.4),
                borderColor: k === 0 ? firstColor : secondColor,
                lineTension: 0,
                data: k === 0 ? answers.subject : answers.respondent,
            });
        }
        return datasets;
    }

    componentDidUpdate() {}

    componentWillMount() {
        if (!this.state.hasLoaded && this.props.report != null) {
            let parts = [];
            let responders = [];
            let type = 0;
            for (let k = 0; k < this.props.report.parts.length; k++) {
                let part = this.props.report.parts[k];
                part.session.scans.forEach((scan) => {
                    let newCategories = [];
                    type = scan.type;
                    scan.categories.sort(this.compareOrder);
                    for (let i = 0; i < scan.categories.length; i++) {
                        let newQuestions = [];
                        scan.categories[i].questions.sort(this.compareOrder);
                        for (let j = 0; j < scan.categories[i].questions.length; j++) {
                            newQuestions.push({
                                id: scan.categories[i].questions[j].id,
                                question: scan.categories[i].questions[j].question,
                                answer: scan.categories[i].questions[j].answer,
                                type: scan.type,
                            });
                        }
                        newCategories.push({
                            id: scan.categories[i].id,
                            name: scan.categories[i].name,
                            questions: newQuestions,
                            type: type,
                        });
                    }
                    if (this.props.report.subject === part.session.id) {
                        parts.unshift({
                            respondent: part.session,
                            categories: newCategories,
                            session: part.session,
                        });
                    } else {
                        parts.push({
                            respondent: part.session,
                            categories: newCategories,
                            session: part.session,
                        });
                    }
                });
                const responderSessions = responders[part.session.employee.id]
                    ? [...(responders[part.session.employee.id].sessions || [])]
                    : [];
                responderSessions.push(part.session);
                responders[part.session.employee.id] = {
                    employee: part.session.employee,
                    sessions: responderSessions,
                };
            }

            this.setState({
                parts: _.sortBy(parts, (part) => part.respondent.experationDate.date),
                responders: responders,
                tab: Object.values(responders)[0].employee.id,
                hasLoaded: true,
                type: type,
            });
        }
    }

    renderCategory(
        category,
        responder,
        firstColor = "#f68800",
        secondColor = "#94c13c",
        poor = "Voor verbetering vatbaar",
        alright = "Voldoende vaardig",
        good = "Zeer vaardig"
    ) {
        var _this = this;
        const scale = category.type === 0 ? [1, 2, 3, 4, 5] : [1, 2, 3, 4, 5, 6, 7];
        return (
            <div key={category.key}>
                <div className="input-container catagory">
                    <div className="input-group three">
                        <div className="catagory-name">{category.name}</div>
                    </div>
                    <div className="input-group input-container three flex-container direction-column">
                        <div className="flex-container">
                            <div className="input-group two">{poor}</div>
                            <div className="input-group two">{alright}</div>
                            <div className="input-group one">{good}</div>
                        </div>
                        <div className="flex-container">
                            {scale.map((scaleItem) => (
                                <div className="one">{scaleItem}</div>
                            ))}
                        </div>
                    </div>
                </div>
                {category.questions.map(function (question, index) {
                    return <div>{_this.renderQuestion(question, responder, firstColor, secondColor)}</div>;
                })}
            </div>
        );
    }

    renderQuestion(question, responder, firstColor = "#f68800", secondColor = "#94c13c") {
        var _this = this;
        var answers = this.getAnswers(question, responder);
        return (
            <div className="input-container report" key={question.question}>
                <div className="input-group three">
                    <div className="question">{question.question}</div>
                </div>
                <div className="input-group three">
                    {answers.map(function (answer, index) {
                        var percentage = _this.calculatePercentage(answer, question.type === 0 ? 5 : 7);
                        var style = {
                            width: percentage + "%",
                            backgroundColor: index === 0 ? firstColor : secondColor,
                        };
                        return (
                            <div
                                key={answer + index}
                                className={"line " + (index === 0 ? "subject" : "respondent")}
                                style={style}
                            ></div>
                        );
                    })}
                </div>
            </div>
        );
    }

    render() {
        if (this.props.isFetching || !this.state.hasLoaded) {
            if (this.props.report === null && !this.props.isFetching) {
                return (
                    <div className="absolute-center">
                        <h1 className="center">Deze rapportage bestaat niet</h1>
                    </div>
                );
            }
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
        if (this.props.report === null || this.state.parts.length < 1) {
            return (
                <div className="absolute-center">
                    <h1 className="center">Deze rapportage bestaat niet</h1>
                </div>
            );
        }

        const labels = [];
        this.state.parts[0].categories.forEach((category) => {
            _.sortBy(category.questions, (o) => o.order).forEach((question) => {
                labels.push(this.truncate(question.question, 25));
            });
        });

        var options = {
            scales: {
                yAxes: [
                    {
                        display: true,
                        ticks: {
                            suggestedMin: 1,
                            beginAtZero: true,
                            max: this.state.type === 0 ? 5 : 7,
                            stepSize: 1,
                            min: 1,
                        },
                    },
                ],
                xAxes: [
                    {
                        stacked: true,
                        maxBarThickness: 10,

                        ticks: {
                            autoSkip: false,
                            maxRotation: 45,
                            minRotation: 45,
                        },
                    },
                ],
            },
        };

        const subjectEmployee = this.state.parts[0].respondent.employee;
        const responders = _.sortBy(Object.values(this.state.responders), (responder) => {
            return subjectEmployee.id != responder.employee.id;
        });

        return (
            <div className="container">
                <div className="box scan">
                    <form method="post" onSubmit={(e) => this.handleSubmit()}>
                        <h1 className="center big">Rapportage ontwikkeling.</h1>
                        <h2 className="center">
                            Een overzicht van de ontwikkeling van:{" "}
                            {`${subjectEmployee.firstname} ${subjectEmployee.lastname}`}
                        </h2>

                        <div class="flex-container sideview">
                            {responders.map((responder, index) => {
                                const firstColor = index === 0 ? "#f68800" : "#2c3bef";
                                const secondColor = index === 0 ? "#94c13c" : "#de3434";

                                const data = {
                                    labels: labels,
                                    datasets: this.createDataSet(responder, firstColor, secondColor),
                                };

                                return (
                                    <div className={"tab-section active"}>
                                        <div className="tabs sideview">
                                            <div
                                                className={"tab disabled"}
                                                onClick={(event) => {
                                                    this.setState({
                                                        tab: responder.employee.id,
                                                    });
                                                }}
                                            >
                                                {subjectEmployee.id === responder.employee.id
                                                    ? `${responder.employee.firstname} ${responder.employee.lastname}`
                                                    : `Ingevuld ten behoeve van ${subjectEmployee.firstname} ${subjectEmployee.lastname} door ${responder.employee.firstname} ${responder.employee.lastname}`}
                                            </div>
                                        </div>
                                        <div className="participants">
                                            {this.state.parts.map((part, index) => {
                                                var fullname = `${responder.employee.firstname} ${responder.employee.lastname}`;
                                                return (
                                                    <div
                                                        className={index === 0 ? "subject" : "respondent"}
                                                        style={{
                                                            backgroundColor:
                                                                index === 0 ? firstColor : secondColor,
                                                        }}
                                                    >
                                                        <div className="name">
                                                            {fullname === " " ? "Geen naam" : fullname} <br />
                                                            {moment(
                                                                part.respondent.experationDate.date,
                                                                "YYYY-MM-DD HH:mm:ss.SSS"
                                                            ).format("DD-MM-YYYY")}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {this.state.parts[0].categories.map((category) => {
                                            return this.renderCategory(
                                                category,
                                                responder,
                                                firstColor,
                                                secondColor
                                            );
                                        })}
                                        <div className="summation">
                                            <h1 className="center">Overzicht</h1>
                                            <Line data={data} options={options} type="myLine" />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(EmployeeDevelopReport);
