import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";

class AddPositionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hasLoaded: false,
            canBeDeleted: true,
        };
    }

    renderWarning() {
        const { t } = this.props;
        if (!this.state.canBeDeleted) {
            return (
                <div className="warning">
                    <b>{t("warning")}</b> {t("position.inUse")}
                </div>
            );
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.match.params.positionId != null) {
            this.props.onUpdate();
        } else {
            this.props.onCreate();
        }
    }

    componentWillMount() {
        if (this.props.match.params.positionId != null) {
            this.props.fetchPosition(this.props.match.params.positionId);
        } else {
            this.props.onChange("position", null);
            this.props.onChange("name", "");
        }
    }

    componentDidMount() {
        this.props.onChange("company", this.props.match.params.id);
    }

    componentDidUpdate() {
        if (
            !this.state.hasLoaded &&
            this.props.position != null &&
            this.props.match.params.positionId != null
        ) {
            this.props.onChange("name", this.props.position.name);
            this.setState({
                hasLoaded: true,
                canBeDeleted: this.props.position.canBeDeleted,
            });
        }
    }

    render() {
        const { t, onChange, isFetching } = this.props;

        if (!isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        {this.renderWarning()}
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <h1 className="center">
                                {(this.props.match.params.positionId != null ? t("update") : t("new")) +
                                    " functie."}
                            </h1>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={`Naam`}
                                        value={this.props.name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container margin-top">
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input type="submit" value={t("form.save")} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(AddPositionPage);
