import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddScanPage from '../../../components/dashboard/new/AddScanPage';
import * as scanActions from '../../../actions/scanActions';

class AddScanContainer extends Component {

  render() {
    const {
      fetchScan,
      changeValue,
      create,
      update,
      ...props
    } = this.props;
    
    return (
        <AddScanPage
          {...props}
          onChange={changeValue}
          onCreate={(categories, onSuccess) => {
            create(
              this.props.name, 
              (this.props.company != null) ? this.props.company.value : "", 
              (this.props.position != null) ? this.props.position.value : "", 
              (this.props.type != null) ? this.props.type.value : 0,
              this.props.description, categories)
            .then( response => {
              onSuccess();
            })
          }}
          onUpdate={(categories, onSuccess) => {
            update(
              this.props.match.params.id, 
              this.props.name, 
              (this.props.company != null) ? this.props.company.value : "", 
              (this.props.position != null) ? this.props.position.value : "", 
              (this.props.type != null) ? this.props.type.value : 0,
              this.props.description, 
              categories)
            .then( response => {
              onSuccess();
            })
          }}
          fetchScan={() => 
            fetchScan(this.props.match.params.id)
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.scans.isFetching,
    scan: state.scans.selected,
    name: state.scans.name,
    company: state.scans.company,
    position: state.scans.position,
    description: state.scans.description,
    type: state.scans.type
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchScan: (id) => dispatch(scanActions.scan(id)),
    create: (name, company, position, type, description, categories) => dispatch(scanActions.add(name, company, position, type, description, categories)),
    update: (id, name, company, position, type, description, categories) => dispatch(scanActions.update(id, name, company, position, type, description, categories)),
    changeValue: (name, value) => dispatch(scanActions.changeValue(name, value))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddScanContainer);