import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";
import AsyncSelect from "react-select/lib/Async";
import * as companyApi from "../../../api/companyApi";

class AddTeamPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [
                {
                    id: null,
                    email: "",
                    position: null,
                    positionSelectorvalue: null,
                    isLeader: false,
                },
            ],
            hasLoaded: false,
            defaultPositions: [],
            canBeDeleted: true,
        };
    }

    renderWarning() {
        const { t } = this.props;
        if (!this.state.canBeDeleted) {
            return (
                <div className="warning">
                    <b>{t("warning")}</b> {t("team.inUse")}
                </div>
            );
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { members } = this.state;

        if (this.props.match.params.teamId != null) {
            this.props.onUpdate(members);
        } else {
            this.props.onCreate(members);
        }
    }

    handleMemberChange(e, idx, newMember) {
        const newMembers = this.state.members.map((member, sidx) => {
            if (idx !== sidx) return member;
            return newMember;
        });
        this.setState({ members: newMembers });
    }

    handleRemoveMember(e, idx) {
        this.setState({ members: this.state.members.filter((s, sidx) => idx !== sidx) });
    }

    handleAddMember() {
        this.setState({
            members: this.state.members.concat([
                {
                    id: null,
                    email: "",
                    position: null,
                    isLeader: false,
                    canBeDeleted: false,
                },
            ]),
        });
    }

    componentDidMount() {
        this.props.onChange("company", this.props.match.params.id);
    }

    componentWillMount() {
        companyApi.getPositions(this.props.match.params.id).then((response) => {
            var values = [];
            for (var i = 0; i < response.length; i++) {
                values.push({
                    value: response[i].id,
                    label: response[i].name,
                });
            }
            this.setState({
                defaultPositions: values,
            });
        });
        if (this.props.match.params.teamId != null) {
            this.props.fetchTeam();
        } else {
            this.props.onChange("team", null);
            this.props.onChange("name", "");
        }
    }

    componentDidUpdate() {
        if (
            !this.state.hasLoaded &&
            this.props.team != null &&
            this.props.match.params.teamId != null &&
            this.state.defaultPositions != []
        ) {
            this.props.onChange("name", this.props.team.name);
            var members = JSON.parse(JSON.stringify(this.props.team.employees));
            for (var i = 0; i < members.length; i++) {
                var member = members[i];
                members[i].positionSelectorvalue = this.state.defaultPositions.filter(function (value) {
                    return value.value === member.position;
                });
            }
            this.setState({
                members: members,
                hasLoaded: true,
                canBeDeleted: this.props.team.canBeDeleted,
            });
        }
    }

    render() {
        const { t, onChange, isFetching } = this.props;
        if (!isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        {this.renderWarning()}
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <h1 className="center">
                                {(this.props.match.params.teamId != null ? "Update" : "Nieuw") + " team."}
                            </h1>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.name")}
                                        value={this.props.name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            {this.state.members.map((member, idx) => (
                                <div className="input-container" key={idx}>
                                    <div className="input-group one">
                                        <h2 className="center">{idx + 1}</h2>
                                    </div>
                                    <div className="input-group three">
                                        <input
                                            type="text"
                                            placeholder={t("form.label.email")}
                                            value={member.email}
                                            onChange={(e) =>
                                                this.handleMemberChange(
                                                    e,
                                                    idx,
                                                    (function () {
                                                        member.email = e.target.value;
                                                        return member;
                                                    })()
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group three">
                                        <AsyncSelect
                                            ref="selectPosition"
                                            placeholder={
                                                this.state.defaultPosition != null
                                                    ? this.state.defaultPosition
                                                    : t("form.label.selectPosition")
                                            }
                                            isDisabled={this.props.company === null}
                                            onChange={(event) => {
                                                this.handleMemberChange(
                                                    event,
                                                    idx,
                                                    (function () {
                                                        if (!Array.isArray(event)) {
                                                            member.position = event.value;
                                                            member.positionSelectorvalue = event;
                                                        } else {
                                                            member.position = null;
                                                            member.positionSelectorvalue = [];
                                                        }
                                                        return member;
                                                    })()
                                                );
                                            }}
                                            loadOptions={(inputValue, callback) => {
                                                companyApi
                                                    .searchPositions(this.props.company, inputValue)
                                                    .then((response) => {
                                                        var values = [];
                                                        for (var i = 0; i < response.length; i++) {
                                                            values.push({
                                                                value: response[i].id,
                                                                label: response[i].name,
                                                            });
                                                        }
                                                        callback(values);
                                                    });
                                            }}
                                            value={member.positionSelectorvalue}
                                            defaultOptions={this.state.defaultPositions}
                                            autoload={true}
                                        />
                                    </div>
                                    <div className="input-group two">
                                        <label className="checkbox-container">
                                            {t("form.label.teamLeader")}
                                            <input
                                                type="checkbox"
                                                checked={member.isLeader === 1}
                                                onChange={(event) => {
                                                    this.handleMemberChange(
                                                        event,
                                                        idx,
                                                        (function () {
                                                            member.isLeader = event.target.checked ? 1 : 0;
                                                            return member;
                                                        })()
                                                    );
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                        </label>
                                    </div>
                                    <div className="input-group one">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleRemoveMember(e, idx)}
                                            className="delete"
                                        ></button>
                                    </div>
                                </div>
                            ))}

                            <div className="input-container margin-top">
                                <div className="input-group one">
                                    <div className="add">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleAddMember(e)}
                                            className="small"
                                        >
                                            {t("form.label.add")}
                                        </button>
                                    </div>
                                </div>
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input
                                            type="submit"
                                            value={t("form.save")}
                                            onClick={(e) => this.handleSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(AddTeamPage);