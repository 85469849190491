import React, { Component } from 'react';
import { connect } from 'react-redux';
import CompanyPage from '../../components/dashboard/CompanyPage';
import * as companyActions from '../../actions/companyActions';
import * as positionActions from '../../actions/positionActions';
import * as departmentActions from '../../actions/departmentActions';
import * as teamActions from '../../actions/teamActions';

class CompanyContainer extends Component {

  render() {
    
    const {
      changeValue,
      fetchCompany,
      fetchPositions,
      searchPositions,
      deletePosition,
      fetchDepartments,
      searchDepartments,
      deleteDepartment,
      fetchTeams,
      searchTeams,
      deleteTeam,
      ...props
    } = this.props;
  
    return (
        <CompanyPage
          {...props}
          onChange={changeValue}
          fetchCompany={(id) => 
            fetchCompany(id)
          }
          fetchPositions={(company) =>
            fetchPositions(company)
          }
          searchPositions={(company, query) =>
           searchPositions(company, query)
          }
          deletePosition={(id) => 
            deletePosition(id).then(response => {
              fetchPositions(this.props.match.params.id);
            })
          }
          fetchDepartments={(company) =>
            fetchDepartments(company)
          }
          searchDepartments={(company, query) =>
           searchDepartments(company, query)
          }
          deleteDepartment={(id) => 
            deleteDepartment(id).then(response => {
              fetchDepartments(this.props.match.params.id);
            })
          }
          fetchTeams={(company) => 
            fetchTeams(company)
          }
          searchTeams={(company, query) => 
            searchTeams(company, query)
          }
          deleteTeam={(id) => 
            deleteTeam(id).then(response => {
              fetchTeams(this.props.match.params.id);
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    tab: state.companies.tab,
    companyIsFetching: state.companies.isFetching,
    positionsIsfetching: state.positions.isFetching,
    departmentsIsFetching: state.departments.isFetching, 
    teamsIsFetching: state.teams.isFetching,
    company: state.companies.selected,
    positions: state.positions.positions,
    departments: state.departments.departments,
    teams: state.teams.teams
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(companyActions.changeValue(name, value)),
    fetchCompany: (id) => dispatch(companyActions.company(id)), 
    fetchPositions: (company) => dispatch(positionActions.positions(company)),
    searchPositions: (company, query) => dispatch(positionActions.search(company, query)),
    deletePosition: (id) => dispatch(positionActions.remove(id)),
    fetchDepartments: (company) => dispatch(departmentActions.departments(company)),
    searchDepartments: (company, query) => dispatch(departmentActions.search(company, query)),
    deleteDepartment: (id) => dispatch(departmentActions.remove(id)),
    fetchTeams: (company) => dispatch(teamActions.teams(company)),
    searchTeams: (company, query) => dispatch(teamActions.search(company, query)),
    deleteTeam: (id) => dispatch(teamActions.remove(id))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(CompanyContainer);