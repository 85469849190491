import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddReportPage from '../../../components/dashboard/new/AddReportPage';
import * as reportActions from '../../../actions/reportActions';

class AddReportContainer extends Component {

  getSafe(fn) {
    try {
        return fn();
    } catch (e) {
        return '';
    }
  }

  render() {
    const {
      changeValue,
      create,
      ...props
    } = this.props;
    
    const { name, type, level, startDate, endDate, company, department, team, employee } = this.props;
    

    return (
        <AddReportPage
          {...props}
          onChange={changeValue}
          onCreate={(emails) => {
            create(
              name,
              emails,
              this.getSafe(() => type.value), 
              this.getSafe(() => level.value), 
              startDate, 
              endDate, 
              this.getSafe(() => company.value),
              this.getSafe(() => department.value),
              this.getSafe(() => team.value),
              this.getSafe(() => employee.value)
            ).then( response => {
              this.props.history.push('/reports');
            })
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.reports.isFetching,
    session: state.reports.selected,
    name: state.reports.name,
    type: state.reports.type,
    level: state.reports.level,
    startDate: state.reports.startDate,
    endDate: state.reports.endDate,
    company: state.reports.company,
    team: state.reports.team,
    department: state.reports.department,
    employee: state.reports.employee
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeValue: (name, value) => dispatch(reportActions.changeValue(name, value)),
    create: (name, emails, type, level, startDate, endDate, company, department, team, employee) => dispatch(reportActions.add(name, emails, type, level, startDate, endDate, company, department, team, employee))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddReportContainer);