import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { SingleDatePicker } from "react-dates";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";
class AddCompanyPage extends React.Component {
    state = {
        hasLoaded: false,
        actions: [],
    };
    handleSubmit(e) {
        e.preventDefault();
        if (this.props.match.params.id != null) {
            this.props.onUpdate(this.state.actions);
        } else {
            this.props.onCreate(this.state.actions);
        }
    }

    handleActionChange(e, idx, newAction) {
        const newactions = this.state.actions.map((action, sidx) => {
            if (idx !== sidx) return action;
            return newAction;
        });
        this.setState({ actions: newactions });
    }

    handleRemoveAction(e, idx) {
        this.setState({ actions: this.state.actions.filter((s, sidx) => idx !== sidx) });
    }

    handleAddAction() {
        this.setState({
            actions: this.state.actions.concat([
                {
                    id: null,
                    description: "",
                    date: moment(),
                    dueDate: moment(),
                },
            ]),
        });
    }

    componentWillMount() {
        if (this.props.match.params.id != null) {
            this.props.fetchCompany(this.props.match.params.id);
        } else {
            this.props.onChange("company", null);
            this.props.onChange("name", "");
            this.props.onChange("location", "");
            this.props.onChange("contactperson", "");
            this.props.onChange("email", "");
            this.props.onChange("phonenumber", "");
            this.props.onChange("description", "");
        }
    }

    componentDidUpdate() {
        if (!this.state.hasLoaded && this.props.company != null && this.props.match.params.id != null) {
            this.props.onChange("name", this.props.company.name);
            this.props.onChange("location", this.props.company.location);
            this.props.onChange("contactperson", this.props.company.contactperson);
            this.props.onChange("email", this.props.company.email);
            this.props.onChange("phonenumber", this.props.company.phonenumber);
            this.props.onChange("description", this.props.company.description);
            this.setState({
                actions: JSON.parse(JSON.stringify(this.props.company.actions)).map((action) => {
                    action.date = moment(action.date.date);
                    action.dueDate = moment(action.dueDate.date);
                    return action;
                }),
                hasLoaded: true,
            });
        }
    }

    render() {
        const {
            t,
            onChange,
            isFetching,
            match,
            name,
            location,
            contactperson,
            email,
            phonenumber,
            description,
        } = this.props;

        if (!this.props.isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        <form>
                            <h1 className="center">
                                {(match.params.id != null ? t("update") : t("new")) + " " + t("company")}
                            </h1>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.name")}
                                        value={this.props.name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.location")}
                                        value={this.props.location}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("location", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.contactPerson")}
                                        value={this.props.contactperson}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("contactperson", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.email")}
                                        value={this.props.email}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("email", value, event);
                                        }}
                                    />
                                </div>
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.phoneNumber")}
                                        value={this.props.phonenumber}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("phonenumber", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <textarea
                                        type="text"
                                        placeholder={t("form.label.description")}
                                        value={this.props.description}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("description", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            {this.state.actions.length > 0 ? <h1 className="center">{t("actions.header")}</h1> : null}
                            {this.state.actions.map((action, idx) => (
                                <div className="input-container" key={idx}>
                                    <div className="input-group two">
                                        <SingleDatePicker
                                            id={"datepicker_" + idx}
                                            small={false}
                                            block={false}
                                            noBorder={true}
                                            hideKeyboardShortcutsPanel={true}
                                            showDefaultInputIcon={false}
                                            date={action.dueDate}
                                            focused={this.state["focused" + idx]}
                                            onFocusChange={({ focused }) => {
                                                this.setState({ ["focused" + idx]: focused });
                                            }}
                                            onDateChange={(dueDate) => {
                                                this.handleActionChange(
                                                    dueDate,
                                                    idx,
                                                    (function () {
                                                        action.dueDate = dueDate;
                                                        return action;
                                                    })()
                                                );
                                            }}
                                        />
                                    </div>
                                    <div className="input-group four">
                                        <textarea
                                            className="small"
                                            placeholder={t("form.label.description")}
                                            value={action.description}
                                            onChange={(e) =>
                                                this.handleActionChange(
                                                    e,
                                                    idx,
                                                    (function () {
                                                        action.description = e.target.value;
                                                        return action;
                                                    })()
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="input-group one">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleRemoveAction(e, idx)}
                                            className="delete"
                                        ></button>
                                    </div>
                                </div>
                            ))}
                            <div className="input-container margin-top">
                                <div className="input-group one">
                                    <div className="add">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleAddAction(e)}
                                            className="small"
                                        >
                                            {t("action")}
                                        </button>
                                    </div>
                                </div>
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input
                                            type="submit"
                                            value={t("form.save")}
                                            onClick={(e) => this.handleSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(AddCompanyPage);
