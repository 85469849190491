import React from 'react';

export default class Pager extends React.Component {

    _renderFirst(){ 
        if (this.props.page > 0){
            return (
                <div className="item first" onClick={(e) => {
                    this.props.onReload(0);
                }}>Eerste</div>
            )
        }
    }
    _renderLast(){ 
        if (this.props.items.length > 0  && (this.props.page + 1) === Math.round(this.props.totalPages)){
            return (
                <div className="item last" onClick={(e) => {
                    this.props.onReload(Math.round(this.props.totalPages));
                }}>Laatste</div>
            )
        }
    }

    _renderPrevious(){
        let previous = [];

        for(let i = 0; i < 3; i++) {
            if (((this.props.page + 1)- i) > 0 && (this.props.page - i) < this.props.page){
                previous.push((i - this.props.page) + 1);
            }
        }
        return (
            <div className="prev">
                {previous.map((prev) => {
                    return (
                        <div className="item" key={prev} onClick={(e) => {
                            this.props.onReload(prev - 1);
                        }}>
                            {prev}
                        </div>
                    )
                })}
            </div>
        )
    }

    _renderUpcoming() {
        let upcoming = [];

        if (this.props.items.length > 0){
            for(let i = 0; i < 3; i++) {
                if (((i + 1) + this.props.page) <= this.props.totalPages){
                    upcoming.push((i + 2) + this.props.page);
                }
            }
        }
        return (
            <div className="upcoming">
                {upcoming.map((coming) => {
                    return (
                        <div className="item" key={coming} onClick={(e) => {
                            this.props.onReload(coming -1);
                        }}>
                            {coming}
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        if (this.props.page === null || this.props.items === 'undefined') return null;

        if (this.props.items.length > 0 
            && this.props.page <= Math.floor(this.props.totalPages) 
            && Math.floor(this.props.totalPages) > 0){
            return (
                <div className="pager">
                    {this._renderFirst()}
                    {this._renderPrevious()}
                    <div className="current">{this.props.page + 1}</div>
                    {this._renderUpcoming()}
                    {this._renderLast()}
                </div>
            )
        }
        return null;
    }
}