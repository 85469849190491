import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    page: 0,
    totalPages: 0,
    article: null,
    articles: [],
    title: '',
    author: '',
    description: '',
    body: '',
};

export default function blogs(state = initialState, action) {
    switch (action.type) {
        case types.BLOG_FORM_CHANGE_VALUE:
        case types.ARTICLE_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.ARTICLE_GET_START:
            return {
                ...state,
                article: null,
                isFetching: true,
            };
        case types.ARTICLE_GET_SUCCESS:
            return {
                ...state,
                article: action.article,
                isFetching: false,
            };

        case types.ARTICLES_GET_START:
            return {
                ...state,
                isFetching: true,
                articles: [],
            };
        case types.ARTICLES_GET_SUCCESS:
            return {
                ...state,
                isFetching: false,
                articles: action.articles,
                totalPages: action.articles.length > 0 ? action.articles[0].totalResults / 20 : 0,
            };
        default:
            return state;
    }
}
