import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";
import AsyncSelect from "react-select/lib/Async";
import * as companyApi from "../../../api/companyApi";

class AddDepartmentPage extends Component {
    state = {
        hasLoaded: false,
        canBeDeleted: true,
        teams: [],
        defaultTeams: [],
    };

    renderWarning() {
        const { t } = this.props;
        if (!this.state.canBeDeleted) {
            return (
                <div className="warning">
                    <b>{t("warning")}</b> {t("department.inUse")}
                </div>
            );
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        if (this.props.match.params.departmentId != null) {
            this.props.onUpdate(this.state.teams);
        } else {
            this.props.onCreate(this.state.teams);
        }
    }

    handleTeamChange(e, idx, newTeam) {
        const newteams = this.state.teams.map((team, sidx) => {
            if (idx !== sidx) return team;
            return newTeam;
        });
        this.setState({ teams: newteams });
    }

    handleRemoveTeam(e, idx) {
        this.setState({ teams: this.state.teams.filter((s, sidx) => idx !== sidx) });
    }

    handleAddTeam() {
        this.setState({
            teams: this.state.teams.concat([
                {
                    id: null,
                    team: "",
                },
            ]),
        });
    }

    componentWillMount() {
        if (this.props.match.params.departmentId != null) {
            this.props.fetchDepartment();
        } else {
            this.props.onChange("department", null);
            this.props.onChange("name", "");
        }
    }

    componentDidMount() {
        this.props.onChange("company", this.props.match.params.id);
        let _this = this;
        companyApi.getTeams(this.props.match.params.id).then((response) => {
            var values = [];
            for (var i = 0; i < response.length; i++) {
                values.push({
                    value: response[i].id,
                    label: response[i].name,
                });
            }
            _this.setState({
                defaultTeams: values,
            });
        });
    }

    componentDidUpdate() {
        if (
            !this.state.hasLoaded &&
            this.props.department != null &&
            this.props.match.params.departmentId != null
        ) {
            this.props.onChange("name", this.props.department.name);
            var teams = [];
            for (var i = 0; i < this.props.department.teams.length; i++) {
                let team = this.props.department.teams[i];
                teams.push({
                    id: team.id,
                    value: team.team.id,
                    team: team.team.id,
                    label: team.team.name,
                });
            }
            this.setState({
                teams: teams,
                hasLoaded: true,
                canBeDeleted: this.props.department.canBeDeleted,
            });
        }
    }

    render() {
        const { t, isFetching, match, onChange } = this.props;

        if (!isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        {this.renderWarning()}
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <h1 className="center">
                                {(match.params.departmentId != null ? t("update") : t("new")) + " afdeling."}
                            </h1>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.name")}
                                        value={this.props.name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            {this.state.teams.map((team, idx) => (
                                <div className="input-container" key={idx}>
                                    <div className="input-group one">
                                        <h2 className="center">{idx + 1}</h2>
                                    </div>
                                    <div className="input-group four">
                                        <AsyncSelect
                                            ref="selectTeam"
                                            placeholder={t("form.label.selectTeam")}
                                            value={team.team != "" ? team : null}
                                            defaultOptions={this.state.defaultTeams}
                                            onChange={(e) =>
                                                this.handleTeamChange(
                                                    e,
                                                    idx,
                                                    (function () {
                                                        team.team = e.value;
                                                        team.value = e.value;
                                                        team.label = e.label;
                                                        return team;
                                                    })()
                                                )
                                            }
                                            loadOptions={(inputValue, callback) => {
                                                if (inputValue === "") {
                                                    companyApi
                                                        .getTeams(this.props.match.params.id)
                                                        .then((response) => {
                                                            var values = [];
                                                            for (var i = 0; i < response.length; i++) {
                                                                values.push({
                                                                    value: response[i].id,
                                                                    label: response[i].name,
                                                                });
                                                            }
                                                            callback(values);
                                                        });
                                                } else {
                                                    companyApi
                                                        .searchTeams(this.props.match.params.id, inputValue)
                                                        .then((response) => {
                                                            var values = [];
                                                            for (var i = 0; i < response.length; i++) {
                                                                values.push({
                                                                    value: response[i].id,
                                                                    label: response[i].name,
                                                                });
                                                            }
                                                            callback(values);
                                                        });
                                                }
                                            }}
                                        />
                                    </div>
                                    <div className="input-group one">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleRemoveTeam(e, idx)}
                                            className="delete"
                                        ></button>
                                    </div>
                                </div>
                            ))}
                            <div className="input-container margin-top">
                                <div className="input-group one">
                                    <div className="add">
                                        <button
                                            type="button"
                                            onClick={(e) => this.handleAddTeam(e)}
                                            className="small"
                                        >
                                            {t("team")}
                                        </button>
                                    </div>
                                </div>
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input
                                            type="submit"
                                            value={t("form.save")}
                                            onClick={(e) => this.handleSubmit(e)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(AddDepartmentPage);
