import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { MAIN_COLOR }  from '../../constants/constants' 
import { PulseLoader } from 'react-spinners';

class ForgotResetPage extends Component {

	onSubmit(e){
		const { onRecover } = this.props;
		e.preventDefault();
		onRecover()
	}

	componentDidMount() {
		const { onChange, match } = this.props;
		onChange("token", match.params.key)
	}

	render(){
		const {
			t,
			isFetching,
			password,
			passwordrepeat,
			error,
			onChange
		} = this.props;

		if(isFetching){
			return (
				<div className='loader'>
				  <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
				</div> 
			  )
		}

		return (
			<div className="container">
				<div className="box login absolute-center">
					<form method="post">
						<h1 className="center big">Reset.</h1>
						<h2 className="center">Reset je wachtwoord.</h2>
						<div className="input-container">
							<div className="input-group one">
								<input type="password" value={password} placeholder="Wachtwoord" onChange={event => {
									const { value } = event.target;
									onChange('password', value, event);
								}}/>
							</div>
						</div>
						<div className="input-container">
							<div className="input-group one">
								<input type="password" value={passwordrepeat} placeholder="Wachtwoord herhalen" onChange={event => {
									const { value } = event.target;
									onChange('repeatpassword', value, event);
								}}/>
							</div>
						</div>
						<div className="input-container">
							<div className="message error">{error}</div>
						</div>

						<div className="input-group right">
							<div className="submit">
								<input type="submit" value="Resetten" onClick={(e) => this.onSubmit(e)} />
							</div>
						</div>
					</form>
				</div>
			</div>
		)
	}
}
export default withTranslation("translation")(ForgotResetPage)