import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";

import * as contactApi from "../../api/contactApi";
import { MAIN_COLOR } from "../../constants/constants";

class DemoRequest extends Component {
    state = {
        message: "",
        error: "",
        isSending: false,
    };

    onSubmit(e) {
        e.preventDefault();
        const { t } = this.props;
        this.setState({
            isSending: true,
        });
        var values = [];
        var inputs = document.getElementById("demo").elements;
        for (var i = 0; i < inputs.length; i++) {
            if (inputs[i].type != "submit") {
                if (inputs[i].value === undefined || inputs[i].value === "") {
                    this.setState({
                        isSending: false,
                        error: inputs[i].placeholder.replace("*", "") + " is verplicht om in te vullen",
                    });
                    return;
                } else {
                    values.push(inputs[i].value);
                }
            }
        }
        contactApi
            .demo(...values)
            .then((response) => {
                this.setState({
                    isSending: false,
                    error: "",
                    message: "Dankjewel voor je aanvraag.",
                });
            })
            .catch((error) => {
                this.setState({
                    isSending: false,
                    error: "Sorry, er is iets mis gegaan.",
                });
            });
    }

    isEmail(value) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
    }

    onEmailChange(e) {}

    onRequiredFieldChange(e) {}

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    renderDemoForm() {
        const { t } = this.props;
        if (this.state.isSending) {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
        if (this.state.message != "") {
            return (
                <div className="absolute-center">
                    <h1>{this.state.message}</h1>
                </div>
            );
        }
        return (
            <form method="post" id="demo" onSubmit={(e) => this.onSubmit(e)}>
                <h1 className="center">{t("demo.header")}</h1>
                <h3 className="center">{t("demo.description")}</h3>
                <div className="input-container">
                    <div className="input-group one">
                        <input type="text" placeholder={`${t("form.label.firstName")}*`} />
                    </div>
                    <div className="input-group one">
                        <input type="text" placeholder={`${t("form.label.lastName")}*`} />
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-group one">
                        <input type="e-mail" placeholder={`${t("form.label.email")}*`} />
                    </div>
                    <div className="input-group one">
                        <input type="text" placeholder={`${t("form.label.phoneNumber")}*`} />
                    </div>
                </div>
                <div className="input-container margin-top">
                    <div className="input-group one">
                        <input type="text" placeholder={`${t("form.label.companyName")}`} />
                    </div>
                </div>
                <div className="input-container">
                    <div className="input-group one">
                        <input type="text" placeholder={`${t("form.label.title")}`} />
                    </div>
                    <div className="input-group one">
                        <input type="number" placeholder={`${t("form.label.numberOfEmployees")}`} />
                    </div>
                </div>
                <div className="input-container">
                    <div className="message error">{this.state.error}</div>
                </div>
                <div className="input-group right margin-top">
                    <div className="submit">
                        <input type="submit" value={t("form.request")} />
                    </div>
                </div>
            </form>
        );
    }

    render() {
        return (
            <div>
                <div className="demo">
                    <div className="container">
                        <div className="flex-container">
                            <div className="one">{this.renderDemoForm()}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(DemoRequest);
