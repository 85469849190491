import React, { Component } from 'react';
import { connect } from 'react-redux';
import AddDepartmentPage from '../../../components/dashboard/new/AddDepartmentPage';
import * as departmentActions from '../../../actions/departmentActions';

class AddDepartmentContainer extends Component {

  render() {
    const {
      fetchDepartment,
      changeValue,
      create,
      update,
      ...props
    } = this.props;
    
    const {company, name} = props;

    return (
        <AddDepartmentPage
          {...props}
          onChange={changeValue}
          onCreate={(teams) => {
            create(company, name, teams).then( response => {
              this.props.history.push('/company/'+company);
            })
          }}
          onUpdate={(teams) => {
            update(this.props.match.params.departmentId, company, name, teams).then( response => {
              this.props.history.push('/company/'+company);
            })
          }}
          fetchDepartment={() => {
            fetchDepartment(this.props.match.params.departmentId)
          }}

        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isFetching: state.departments.isFetching,
    department: state.departments.selected,
    company: state.departments.company,
    name: state.departments.name
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchDepartment: (id) => dispatch(departmentActions.department(id)),
    create: (company, name, teams) => dispatch(departmentActions.add(company, name, teams)),
    update: (id, company, name, teams) => dispatch(departmentActions.update(id, company, name, teams)),
    changeValue: (name, value) => dispatch(departmentActions.changeValue(name, value))

  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(AddDepartmentContainer);