import React, { Component } from "react";
import { DateRangePicker } from "react-dates";
import { Link } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/lib/Async";
import { PulseLoader } from "react-spinners";
import moment from "moment";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";
import parse from 'html-react-parser';

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { MAIN_COLOR } from "../../../constants/constants";
import * as scanApi from "../../../api/scanApi";
import { withTranslation } from "react-i18next";

const typeOptions = [
    { value: 0, label: "Normale sessie" },
    { value: 1, label: "Training sessie" },
];

class EditSessionPage extends Component {
    state = {
        startDate: null,
        endDate: null,
        focused: null,
        defaultScans: [],
        hasLoaded: false,
    };

    handleSubmit(e) {
        const { onUpdate } = this.props;
        e.preventDefault();
        onUpdate();
    }

    componentWillMount() {
        const { onFetch, match } = this.props;
        onFetch(match.params.id);
    }

    componentDidUpdate() {
        const { session, onChange } = this.props;
        if (session != null && !this.state.hasLoaded) {
            this.setState({ hasLoaded: true });

            onChange("name", session.name);
            onChange("type", typeOptions.filter((option) => option.value === session.type)[0]);
            onChange("instruction", session.instruction);
            onChange("startDate", moment(session.startDate.date).format("YYYY-MM-DD"));
            onChange("endDate", moment(session.experationDate.date).format("YYYY-MM-DD"));

            if (session.scans.length > 0) {
                let values = [];
                let scan = session.scans[0];
                let scanIds = session.scans.map((scan) => scan.id);

                scanApi.getScansWithCompany(scan.company).then((response) => {
                    var scans = [];
                    for (var i = 0; i < response.length; i++) {
                        if (scanIds.includes(response[i].id)) {
                            scans.push({ value: response[i].id, label: response[i].name });
                        }
                        values.push({
                            value: response[i].id,
                            label: response[i].name,
                        });
                    }

                    this.setState({
                        defaultScans: values,
                        editorState: EditorState.createWithContent((stateFromHTML(parse(session.instruction))))
                    });

                    onChange("scans", scans);
                    onChange("company", { value: scan.company });
                    onChange("position", scan.position);
                });
            } else {
                onChange("scans", []);
                this.setState({
                    hasLoaded: true,
                    editorState: EditorState.createWithContent((stateFromHTML(parse(session.instruction))))
                });
            }
        }
    }

    renderWarning() {
        const { t } = this.props;
        if (!this.state.canBeDeleted) {
            return (
                <div className="warning">
                    <b>{t("warning")}</b> {t("session.inUse")}
                </div>
            );
        }
    }

    renderEmailButton() {
        const { session, onInvite } = this.props;
        if (
            (!session.completed && new Date(session.experationDate.date) >= new Date()) ||
            (session.scans.length > 0 && new Date(session.experationDate.date) >= new Date())
        ) {
            return (
                <Link to="">
                    <button
                        className="new"
                        onClick={(e) => {
                            e.preventDefault();
                            onInvite();
                        }}
                    >
                        Opnieuw uitnodigen
                    </button>
                </Link>
            );
        }
    }

    _scanSelect() {
        const { onChange, session, scans, company } = this.props;

        if (session.scans.length < 1) return;

        return (
            <div className="input-container">
                <div className="input-group one">
                    <AsyncSelect
                        isMulti
                        ref={"selectScan" + session.id}
                        placeholder="Selecteer een scan..."
                        defaultOptions={this.state.defaultScans}
                        value={scans}
                        onChange={(event) => {
                            onChange("scans", event, event);
                        }}
                        loadOptions={(inputValue, callback) => {
                            if (inputValue === "") {
                                scanApi.getScansWithCompany(company.value).then((response) => {
                                    var values = [];
                                    for (var i = 0; i < response.length; i++) {
                                        values.push({
                                            value: response[i].id,
                                            label: response[i].name,
                                        });
                                    }
                                    callback(values);
                                });
                            } else {
                                scanApi.searchScansWithCompany(company.value, inputValue).then((response) => {
                                    var values = [];
                                    for (var i = 0; i < response.length; i++) {
                                        values.push({
                                            value: response[i].id,
                                            label: response[i].name,
                                        });
                                    }
                                    callback(values);
                                });
                            }
                        }}
                    />
                </div>
            </div>
        );
    }

    render() {
        const {
            t,
            onChange,
            isFetchingSession,
            name,
            instruction,
            session,
            startDate,
            endDate,
            type,
        } = this.props;

        if (!isFetchingSession && this.state.hasLoaded) {
            return (
                <div className="container">
                    <div className="box dash">
                        {this.renderWarning()}
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <h1 className="center">Update Sessie.</h1>
                            {this.renderEmailButton()}
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.name")}
                                        value={name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <Editor
                                        placeholder={t("form.label.instruction")}
                                        editorState={this.state.editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="editor"
                                        editorClassName="editor-input"
                                        onContentStateChange={(newState) => {
                                            this.setState({
                                                contentState: newState,
                                            });
                                        }}
                                        onEditorStateChange={(newState) => {
                                            onChange(
                                                "instruction",
                                                stateToHTML(newState.getCurrentContent())
                                            );

                                            this.setState({
                                                editorState: newState,
                                            });
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={`Employee`}
                                        value={session.employee.email}
                                        disabled
                                    />
                                </div>
                            </div>
                            {this._scanSelect()}
                            <div className="input-container">
                                <div className="input-group one datepicker">
                                    <DateRangePicker
                                        startDatePlaceholderText={t("form.label.startDate")}
                                        endDatePlaceholderText={t("form.label.endDate")}
                                        startDate={moment(startDate)}
                                        startDateId="startDate"
                                        endDate={moment(endDate)}
                                        endDateId="endDate"
                                        onDatesChange={({ startDate, endDate }) => {
                                            this.setState({ startDate, endDate });
                                            onChange("startDate", startDate);
                                            onChange("endDate", endDate);
                                        }}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                                        hideKeyboardShortcutsPanel={true}
                                        small={false}
                                        block={false}
                                        noBorder={true}
                                        showDefaultInputIcon={false}
                                        displayFormat="DD/M/Y"
                                        isOutsideRange={(day) => false}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <Select
                                        placeholder="Selecteer het type sessie"
                                        defaultValue={type}
                                        value={type}
                                        onChange={(event) => {
                                            onChange("type", event, event);
                                        }}
                                        options={typeOptions}
                                    />
                                </div>
                            </div>
                            <div className="input-container margin-top">
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input type="submit" value={t("form.save")} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(EditSessionPage);
