import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function contact(firstname = "", lastname = "", email = "", message = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/contact")
            .set("Accept", "application/json")
            .field("firstname", firstname)
            .field("lastname", lastname)
            .field("email", email)
            .field("message", message)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function demo(
    firstname = "",
    lastname = "",
    email = "",
    phone = "",
    company = "",
    titel = "",
    employees = ""
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/demo")
            .set("Accept", "application/json")
            .field("firstname", firstname)
            .field("lastname", lastname)
            .field("email", email)
            .field("phone", phone)
            .field("company", company)
            .field("titel", titel)
            .field("employees", employees)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
