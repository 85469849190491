import Promise from "promise";
import handleError from "../utils/responseMiddleware";

export function getCompanies(page = 1) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/companies/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchCompanies(query = "", page = 1) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/companies/search/" + query + "/" + page)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getCompany(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/company/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addCompany(
    name = "",
    location = "",
    description = "",
    contactperson = "",
    email = "",
    phonenumber = "",
    actions = []
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/company")
            .set("Accept", "application/json")
            .withCredentials()
            .field("name", name)
            .field("description", description)
            .field("location", location)
            .field("contactperson", contactperson)
            .field("email", email)
            .field("phonenumber", phonenumber)
            .field("actions", JSON.stringify(actions))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function updateCompany(
    id = "",
    name = "",
    location = "",
    description = "",
    contactperson = "",
    email = "",
    phonenumber = "",
    actions = []
) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/update/company")
            .set("Accept", "application/json")
            .withCredentials()
            .field("id", id)
            .field("name", name)
            .field("description", description)
            .field("location", location)
            .field("contactperson", contactperson)
            .field("email", email)
            .field("phonenumber", phonenumber)
            .field("actions", JSON.stringify(actions))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function deleteCompany(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/company/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getTeams(company) {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/teams/" + company)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchTeams(company = "", query = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/teams/search/" + company + "/" + query)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getTeam(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/team/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addTeam(name, company, members) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/team")
            .set("Accept", "application/json")
            .withCredentials()
            .field("name", name)
            .field("company", company)
            .field("members", JSON.stringify(members))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function updateTeam(id, name, company, members) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/update/team")
            .set("Accept", "application/json")
            .withCredentials()
            .field("id", id)
            .field("name", name)
            .field("company", company)
            .field("members", JSON.stringify(members))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function deleteTeam(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/team/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getPositions(company = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/positions/" + company)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchPositions(company = "", query = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/positions/search/" + company + "/" + query)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getPosition(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/position/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addPosition(company = "", name = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/position")
            .set("Accept", "application/json")
            .withCredentials()
            .field("company", company)
            .field("name", name)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function updatePosition(id = "", company = "", name = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/update/position")
            .set("Accept", "application/json")
            .withCredentials()
            .field("id", id)
            .field("company", company)
            .field("name", name)
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(res.body);
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
export function deletePosition(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/position/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getDepartments(company = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/departments/" + company)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function searchDepartments(company = "", query = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/departments/search/" + company + "/" + query)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function getDepartment(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .get(process.env.REACT_APP_API_URL + "/department/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function addDepartment(company = "", name = "", teams = []) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/department")
            .set("Accept", "application/json")
            .withCredentials()
            .field("company", company)
            .field("name", name)
            .field("teams", JSON.stringify(teams))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}

export function updateDepartment(id = "", company = "", name = "", teams = []) {
    return new Promise((resolve, reject) => {
        window.superagent
            .post(process.env.REACT_APP_API_URL + "/update/department")
            .set("Accept", "application/json")
            .withCredentials()
            .field("id", id)
            .field("company", company)
            .field("name", name)
            .field("teams", JSON.stringify(teams))
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
export function deleteDepartment(id = "") {
    return new Promise((resolve, reject) => {
        window.superagent
            .delete(process.env.REACT_APP_API_URL + "/department/" + id)
            .set("Accept", "application/json")
            .withCredentials()
            .end(function (err, res) {
                if (err || !res.ok) {
                    reject(handleError(res));
                } else {
                    resolve(JSON.parse(res.text));
                }
            });
    });
}
