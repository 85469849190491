import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { MAIN_COLOR } from "../../constants/constants";
import { PulseLoader } from "react-spinners";

class LoginPage extends Component {
    onSubmit(e) {
        const { onLogin } = this.props;
        e.preventDefault();
        try {
            onLogin();
        } catch (exception) {}
    }

    render() {
        const { t, isTryingToLogin, isLoggedIn, onChange, error, email, password } = this.props;

        if (isTryingToLogin || isLoggedIn) {
            return (
                <div className="loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }

        return (
            <div className="container">
                <div className="box login absolute-center">
                    <form method="post">
                        <h1 className="center big">{t("login.header")}</h1>
                        <h2 className="center">{t("login.description")}</h2>
                        <div className="input-container">
                            <div className="input-group one">
                                <input
                                    type="text"
                                    value={email}
                                    placeholder={t("form.label.email")}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange("email", value, event);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <div className="input-group one">
                                <input
                                    type="password"
                                    value={password}
                                    placeholder={t("form.label.password")}
                                    onChange={(event) => {
                                        const { value } = event.target;
                                        onChange("password", value, event);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="input-container">
                            <Link className="message-right" to="/reset">
                                {t("login.forgotPassword")}
                            </Link>
                        </div>
                        <div className="input-container">
                            <div className="message error">{error}</div>
                        </div>
                        <div className="input-container margin-top">
                            <div className="input-group one right">
                                <div className="submit">
                                    <input
                                        type="submit"
                                        value={t("form.login")}
                                        onClick={(e) => this.onSubmit(e)}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(LoginPage);
