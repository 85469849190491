import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

class SuccessPage extends Component {
    renderNext() {
        const { t, isSupervisor } = this.props;
        if (isSupervisor) {
            return (
                <div className="previous">
                    <Link to="/team">
                        <button type="button" className="small">
                            {t("scan.success.backToTeam")}
                        </button>
                    </Link>
                </div>
            );
        }
        return (
            <h3>
                <i>{t("scan.success.closePage")}</i>
            </h3>
        );
    }
    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="box success absolute-center">
                    <h1 className="center big">{t("scan.success.header")}</h1>
                    <h2 className="center">{t("scan.success.description")}</h2>
                    <p className="center">{t("scan.success.body")}</p>
                    {this.renderNext()}
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(SuccessPage);
