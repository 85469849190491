import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ArticlePreview from "../blog/ArticlePreview";

class Home extends Component {
    componentDidMount() {
        const { fetchArticles } = this.props;
        fetchArticles(0);

        this.handleScroll();
        window.scrollTo(0, 0);
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        function getPosition(element) {
            var xPosition = 0;
            var yPosition = 0;

            while (element) {
                xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
                yPosition += element.offsetTop - element.scrollTop + element.clientTop;
                element = element.offsetParent;
            }

            return { x: xPosition, y: yPosition };
        }
        var addRule = ((style) => {
            var sheet = document.head.appendChild(style).sheet;
            return (selector, css) => {
                var propText =
                    typeof css === "string"
                        ? css
                        : Object.keys(css)
                              .map((p) => p + ":" + (p === "content" ? "'" + css[p] + "'" : css[p]))
                              .join(";");
                sheet.insertRule(selector + "{" + propText + "}", sheet.cssRules.length);
            };
        })(document.createElement("style"));

        const top = window.pageYOffset || document.documentElement.scrollTop,
            banner = document.querySelector(".home"),
            homeContainer = document.querySelector(".home .flex-container"),
            forImage = document.querySelector(".for img"),
            forText = document.querySelector(".for .two"),
            forImagePosition = getPosition(forImage);

        if (banner != null) {
            banner.style.transform = "translateY(" + top / 5 + "px)";
        }
        if (homeContainer != null) {
            addRule(".home .person::after", {
                content: "''",
                position: "absolute",
                bottom: "38%",
                right: "150px",
                transform: "translate(-30%, calc(5% + " + top / 5 + "px))",
                background: "#f68800",
                borderRadius: "50%",
                height: "180px",
                width: "180px",
                zIndex: "-1",
            });
            homeContainer.style.transform = "translateY(-" + top / 4 + "px)";
        }

        if (window.screen.width > 960 && forImage != null && forText != null) {
            forImage.style.transform = "translateX(" + (top - forImagePosition.y) / 20 + "px)";
            forText.style.transform = "translateX(" + -((top - forImagePosition.y) / 20) + "px)";
        }
    }

    _renderArticles() {
        const { t, articles } = this.props;
        if (articles.length > 0) {
            return (
                <div className="container">
                    <h1 className="big center no-margin-top">{t("articles.header")}</h1>
                    <div className="articles">
                        {articles.map((article) => (
                            <ArticlePreview article={article} />
                        ))}
                    </div>
                </div>
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <section className="home" style={{ backgroundImage: "url(/images/building.jpg)" }}>
                    <div className="container absolute-center">
                        <div className="flex-container">
                            <div className="five">
                                <h1>{t("home.top.header")}</h1>
                                <h2>{t("home.top.description")}</h2>
                                <Link to="/producten">
                                    <div className="button">
                                        <button>{t("home.top.button")}</button>
                                    </div>
                                </Link>
                            </div>
                            <div className="three"></div>
                        </div>
                    </div>
                </section>
                <section className="why" id="why">
                    <div className="container">
                        <div className="flex-container points">
                            <Link to="/producten" className="one point">
                                <h1>{t("home.usp.first.header")}</h1>
                                <img src="/images/energy.svg" />
                                <p>{t("home.usp.first.description")}</p>
                            </Link>
                            <Link to="/producten" className="one point focus">
                                <h1>{t("home.usp.second.header")}</h1>
                                <img src="/images/cube.svg" />
                                <p>{t("home.usp.second.description")}</p>
                            </Link>
                            <Link to="/producten" className="one point">
                                <h1>{t("home.usp.third.header")}</h1>
                                <img src="/images/talking.svg" />
                                <p>{t("home.usp.third.description")}</p>
                            </Link>
                        </div>
                    </div>
                </section>
                <section className="for">
                    <div className="container">
                        <div className="flex-container">
                            <div className="one align-center">
                                <img src="/images/working_person.jpg" />
                            </div>
                            <div className="two align-center">
                                <h1>{t("home.target.header")}</h1>
                                <p>{t("home.target.description")}</p>
                            </div>
                        </div>
                    </div>
                </section>
                {this._renderArticles()}
                <section className="cta">
                    <div className="container">
                        <h1>{t("home.cta.header")}</h1>
                        <p>{t("home.cta.description")}</p>
                        <Link to="/producten">
                            <div className="button">
                                <button>{t("home.cta.button")}</button>
                            </div>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}
export default withTranslation("translation")(Home);
