import React, { Component } from 'react';
import { connect } from 'react-redux';
import ScanPage from '../../components/scan/ScanPage';

import * as scanActions from '../../actions/scanActions';

class ScanContainer extends Component {

  render() {
    const {
      fetchSessionScan,
      fetchScansFromSession,
      saveAnswer,
      ...props
    } = this.props;
  

    return (
        <ScanPage
          {...props}
          onSessionFetch={() => 
            fetchSessionScan()
          }
          onFetchScansFromSession={(session) => {
            fetchScansFromSession(session)
          }}
          answer={(session, question, answer, description) => {
            saveAnswer(session, question, answer, description)
          }}
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    isSupervisor: state.sessions.isSupervisor,
    isFetching: state.scans.isFetching,
    session: state.sessions.selected,
    scans: state.scans.selected,
    ...ownProps
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSessionScan: () => dispatch(scanActions.fetchScanFromSession()),
    fetchScansFromSession: (session) => dispatch(scanActions.scansFromSession(session)),
    saveAnswer: (session, question, answer, description) => dispatch(scanActions.answer(session, question, answer, description))
  }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(ScanContainer);