import prodSettings from './configureStore.prod';
import devSettings from './configureStore.dev';

let configureStore = null
if (process.env.NODE_ENV === 'production') {
  configureStore = prodSettings;
} else {
  configureStore = devSettings;
}

export default configureStore;