import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import Select from "react-select";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import { MAIN_COLOR } from "../../../constants/constants";
import { PulseLoader } from "react-spinners";
import AsyncSelect from "react-select/lib/Async";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/style.css";

import * as companyApi from "../../../api/companyApi";

class AddReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: null,
            level: null,
            hasLoaded: false,
            hasLoadedTeams: false,
            hasLoadedDepartments: false,
            hasLoadedCompanies: false,
            emails: [],
        };
        this.selectedCompany = null;
    }

    handleSubmit(e) {
        const { onCreate } = this.props;
        e.preventDefault();
        onCreate(this.state.emails);
    }

    componentWillMount() {
        const { match, fetchReport, onChange } = this.props;
        if (match.params.positionId != null) {
            fetchReport(match.params.id);
        } else {
            onChange("report", null);
            onChange("name", "");
        }
    }

    componentDidMount() {
        const { onChange, match } = this.props;
        onChange("company", match.params.id);
    }

    componentDidUpdate() {
        if (
            !this.state.hasLoaded &&
            this.props.position != null &&
            this.props.match.params.positionId != null
        ) {
            this.props.onChange("name", this.props.position.name);
            this.setState({
                hasLoaded: true,
                canBeDeleted: this.props.position.canBeDeleted,
            });
        }
    }

    renderDateSelector() {
        const { t } = this.props;
        if (this.state.type === 1) {
            const { onChange } = this.props;

            return (
                <div className="input-container">
                    <div className="input-group one datepicker">
                        <DateRangePicker
                            startDatePlaceholderText={t("form.label.starDate")}
                            endDatePlaceholderText={t("form.label.endDate")}
                            disabled={false}
                            startDate={this.state.startDate}
                            startDateId="startDate"
                            endDate={this.state.endDate}
                            endDateId="endDate"
                            onDatesChange={({ startDate, endDate }) => {
                                this.setState({ startDate, endDate });
                                onChange("startDate", startDate);
                                onChange("endDate", endDate);
                            }}
                            focusedInput={this.state.focusedInput}
                            onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                            hideKeyboardShortcutsPanel={true}
                            small={false}
                            block={false}
                            noBorder={true}
                            showDefaultInputIcon={false}
                            displayFormat="DD/M/Y"
                            numberOfMonths={4}
                            isOutsideRange={(day) => false}
                        />
                    </div>
                </div>
            );
        }
    }

    renderCompanySelector() {
        const { t, onChange } = this.props;

        var _this = this;
        if (!this.state.hasLoadedCompanies) {
            companyApi.getCompanies().then((response) => {
                var values = [];
                for (var i = 0; i < response.length; i++) {
                    values.push({
                        value: response[i].id,
                        label: response[i].name,
                    });
                }
                _this.setState({
                    defaultCompanies: values,
                    hasLoadedCompanies: true,
                });
                this.selectedCompany = null;
            });
        }

        return (
            <AsyncSelect
                ref="selectCompany"
                placeholder={t("form.label.selectCompany")}
                value={this.props.company}
                defaultOptions={this.state.defaultCompanies}
                autoload={true}
                onChange={(event) => {
                    const { value } = event;
                    this.selectedCompany = value;

                    this.setState({
                        hasLoadedTeams: false,
                        hasLoadedDepartments: false,
                    });

                    onChange("team", null);
                    onChange("department", null);
                    onChange("employee", null);
                    onChange("company", event, event);
                }}
                loadOptions={(inputValue, callback) => {
                    if (inputValue === "") {
                        companyApi.getCompanies().then((response) => {
                            var values = [];
                            for (var i = 0; i < response.length; i++) {
                                values.push({
                                    value: response[i].id,
                                    label: response[i].name,
                                    company: response[i],
                                });
                            }
                            callback(values);
                        });
                    } else {
                        companyApi.searchCompanies(inputValue).then((response) => {
                            var values = [];
                            for (var i = 0; i < response.length; i++) {
                                values.push({
                                    value: response[i].id,
                                    label: response[i].name,
                                    company: response[i],
                                });
                            }
                            callback(values);
                        });
                    }
                }}
            />
        );
    }

    renderTeamSelector() {
        const { t, onChange } = this.props;

        let _this = this;
        if (this.selectedCompany && !this.state.hasLoadedTeams) {
            companyApi.getTeams(this.selectedCompany).then((response) => {
                var values = [];
                for (var i = 0; i < response.length; i++) {
                    values.push({
                        value: response[i].id,
                        label: response[i].name,
                        team: response[i],
                    });
                }
                _this.setState({
                    defaultTeams: values,
                    hasLoadedTeams: true,
                });
            });
        }

        return (
            <AsyncSelect
                ref="selectTeam"
                placeholder={t("form.label.selectCompany")}
                isDisabled={this.props.company === null}
                value={this.props.team}
                defaultOptions={this.state.defaultTeams}
                onChange={(event) => {
                    var values = [];
                    for (var i = 0; i < event.team.employees.length; i++) {
                        var employee = event.team.employees[i];
                        values.push({
                            value: employee.id,
                            label: employee.email,
                            employee: employee,
                        });
                    }
                    onChange("employee", null);

                    this.setState({
                        employees: values,
                    });

                    onChange("team", event, event);
                }}
                loadOptions={(inputValue, callback) => {
                    if (inputValue === "") {
                        companyApi.getTeams(this.selectedCompany).then((response) => {
                            var values = [];
                            for (var i = 0; i < response.length; i++) {
                                values.push({
                                    value: response[i].id,
                                    label: response[i].name,
                                    team: response[i],
                                });
                            }
                            callback(values);
                        });
                    } else {
                        companyApi.searchTeams(this.props.company.value, inputValue).then((response) => {
                            var values = [];
                            for (var i = 0; i < response.length; i++) {
                                values.push({
                                    value: response[i].id,
                                    label: response[i].name,
                                    team: response[i],
                                });
                            }
                            callback(values);
                        });
                    }
                }}
            />
        );
    }

    renderEmployeeSelector() {
        const { t, onChange } = this.props;

        return (
            <Select
                ref="selectEmployee"
                placeholder={t("form.label.selectEmployee")}
                isDisabled={this.props.team === null}
                value={this.props.employee}
                options={this.state.employees}
                onChange={(event) => {
                    onChange("employee", event, event);
                }}
            />
        );
    }

    renderEmployee() {
        const { t } = this.props;
        return (
            <div>
                <h2 className="center">{t("report.employeeSettings")}</h2>
                {this.renderDateSelector()}
                <div className="input-container">
                    <div className="input-group one">{this.renderCompanySelector()}</div>
                    <div className="input-group one">{this.renderTeamSelector()}</div>
                </div>
                <div className="input-container">
                    <div className="input-group one">{this.renderEmployeeSelector()}</div>
                </div>
            </div>
        );
    }

    renderTeam() {
        const { t } = this.props;
        return (
            <div>
                <h2 className="center">{"report.teamSettings"}</h2>
                {this.renderDateSelector()}
                <div className="input-container">
                    <div className="input-group one">{this.renderCompanySelector()}</div>
                    <div className="input-group one">{this.renderTeamSelector()}</div>
                </div>
            </div>
        );
    }

    renderDepartment() {
        const { t, onChange } = this.props;
        var _this = this;
        if (this.selectedCompany != null && !this.state.hasLoadedDepartments) {
            companyApi.getDepartments(this.selectedCompany).then((response) => {
                var values = [];
                for (var i = 0; i < response.length; i++) {
                    values.push({
                        value: response[i].id,
                        label: response[i].name,
                    });
                }
                _this.setState({
                    defaultDepartments: values,
                    hasLoadedDepartments: true,
                });
            });
        }

        return (
            <div>
                <h2 className="center">{t("report.departmentSettings")}</h2>
                {this.renderDateSelector()}
                <div className="input-container">
                    <div className="input-group one">{this.renderCompanySelector()}</div>
                    <div className="input-group one">
                        <AsyncSelect
                            ref="selectDepartment"
                            placeholder={t("form.label.selectDepartment")}
                            isDisabled={this.props.company === null}
                            value={this.props.department}
                            defaultOptions={this.state.defaultDepartments}
                            onChange={(event) => {
                                onChange("department", event, event);
                            }}
                            loadOptions={(inputValue, callback) => {
                                if (inputValue === "") {
                                    companyApi.getDepartments(this.selectedCompany).then((response) => {
                                        var values = [];
                                        for (var i = 0; i < response.length; i++) {
                                            values.push({
                                                value: response[i].id,
                                                label: response[i].name,
                                            });
                                        }
                                        callback(values);
                                    });
                                } else {
                                    companyApi
                                        .searchDepartments(this.props.company.value, inputValue)
                                        .then((response) => {
                                            var values = [];
                                            for (var i = 0; i < response.length; i++) {
                                                values.push({
                                                    value: response[i].id,
                                                    label: response[i].name,
                                                });
                                            }
                                            callback(values);
                                        });
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }

    renderCompany() {
        const { t } = this.props;
        return (
            <div>
                <h2 className="center">{"Bedrijf instellingen"}</h2>
                {this.renderDateSelector()}
                <div className="input-container">
                    <div className="input-group one">{this.renderCompanySelector()}</div>
                </div>
            </div>
        );
    }

    renderDependingOnLevel() {
        switch (this.state.level) {
            case 0:
                return this.renderEmployee();
            case 1:
                return this.renderTeam();
            case 2:
                return this.renderDepartment();
            case 3:
                return this.renderCompany();
        }
    }

    render() {
        const { t, onChange } = this.props;

        let levelOptions = [
            { value: 0, label: t("level.employee") },
            { value: 1, label: t("level.team") },
            { value: 2, label: t("level.department") },
            { value: 3, label: t("level.company") },
        ];

        let typeOptions = [
            { value: 0, label: t("reportType.normal") },
            { value: 1, label: t("reportType.develop") },
            { value: 4, label: "Afwijking rapportage" },
            { value: 2, label: t("reportType.future") },
        ];

        if (!this.props.isFetching) {
            return (
                <div className="container">
                    <div className="box dash">
                        <form onSubmit={(e) => this.handleSubmit(e)}>
                            <h1 className="center">
                                {(this.props.match.params.id != null ? t("update") : t("new")) + " rapport."}
                            </h1>
                            <div className="input-container">
                                <div className="input-group one">
                                    <input
                                        type="text"
                                        placeholder={t("form.label.name")}
                                        value={this.props.name}
                                        onChange={(event) => {
                                            const { value } = event.target;
                                            onChange("name", value, event);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <ReactMultiEmail
                                        placeholder="E-mails"
                                        emails={this.state.emails}
                                        onChange={(_emails) => {
                                            this.setState({ emails: _emails });
                                        }}
                                        validateEmail={(email) => {
                                            return isEmail(email); // return boolean
                                        }}
                                        getLabel={(email, index, removeEmail) => {
                                            return (
                                                <div data-tag key={index}>
                                                    {email}
                                                    <span data-tag-handle onClick={() => removeEmail(index)}>
                                                        ×
                                                    </span>
                                                </div>
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="input-container">
                                <div className="input-group one">
                                    <Select
                                        placeholder="Selecteer het type rapport"
                                        onChange={(event) => {
                                            onChange("type", event, event);
                                            this.setState({
                                                type: event.value,
                                            });
                                        }}
                                        options={typeOptions}
                                    />
                                </div>
                                <div className="input-group one">
                                    <Select
                                        placeholder="Selecteer het level rapport"
                                        onChange={(event) => {
                                            onChange("level", event, event);
                                            this.setState({
                                                level: event.value,
                                            });
                                        }}
                                        options={levelOptions}
                                    />
                                </div>
                            </div>
                            {this.renderDependingOnLevel()}
                            <div className="input-container margin-top">
                                <div className="input-group one right">
                                    <div className="submit">
                                        <input type="submit" value={t("form.save")} />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </div>
            );
        }
    }
}
export default withTranslation("translation")(AddReportPage);
