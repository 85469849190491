import React, { Component } from 'react';
import { connect } from 'react-redux';
import LogoutPage from '../../components/logout/LogoutPage';
import * as authActions from '../../actions/authActions.js';

class LogoutContainer extends Component {

  render() {
    const {
      logout,
      ...props
    } = this.props;

    return (
        <LogoutPage
          {...props}
          onLogout={() => 
            logout().then( response => {
              this.props.history.push('/')
            })
          }
        />
    );
  }
}

function mapStateToProps(state, ownProps) {
    return {
        error: state.auth.error, 
        ...ownProps
  } ;
}

function mapDispatchToProps(dispatch) {
    return {
        logout: () => dispatch(authActions.logout()),
    }
}
export default connect(
  mapStateToProps, mapDispatchToProps
)(LogoutContainer);