import { combineReducers } from 'redux';

import auth from './auth.js';
import companies from './companies.js';
import scans from './scans.js';
import sessions from './sessions';
import employees from './employees';
import reports from './reports';
import positions from './positions';
import departments from './departments';
import teams from './teams';
import blogs from './blogs';

export default combineReducers({
    auth,
    companies,
    scans,
    sessions,
    employees,
    reports,
    positions,
    departments,
    teams,
    blogs,
});
