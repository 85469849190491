import * as types from '../constants/actionTypes.js';
import * as companyApi from '../api/companyApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.DEPARTMENT_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.DEPARTMENT_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.DEPARTMENT_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.DEPARTMENT_FORM_ADD_FAILURE,
    ...error
});

export const add = (name, company, teams) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.addDepartment(name, company, teams);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const update = (id, name, company, teams) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.updateDepartment(id, name, company, teams);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.deleteDepartment(id);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
}

export const startGet = () => ({
    type: types.DEPARTMENT_GET_START
});

export const getSuccess = (result) => ({
    type: types.DEPARTMENT_GET_SUCCESS,
    department: result
});

export const getFailure = (error) => ({
    type: types.DEPARTMENT_GET_FAILURE,
    ...error
});

export const department = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = companyApi.getDepartment(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}



export const startDepartmentsGet = () => ({
    type: types.DEPARTMENTS_GET_START
});

export const getDepartmentsSuccess = (result) => ({
    type: types.DEPARTMENTS_GET_SUCCESS,
    departments: [...result]
});

export const getDepartmentsFailure = (error) => ({
    type: types.DEPARTMENTS_GET_FAILURE,
    ...error
});

export const departments = (company) => {
    return (dispatch) => {
        dispatch(startDepartmentsGet());

        const promise = companyApi.getDepartments(company);
        
        promise.then(result => {
            dispatch(getDepartmentsSuccess(result))
        }).catch(error => {
            dispatch(getDepartmentsFailure(error))
        });

        return promise;
    }
}

export const search = (company, query = '') => {
    return (dispatch) => {
        dispatch(startDepartmentsGet());

        const promise = companyApi.searchDepartments(company, query);
        
        promise.then(result => {
            dispatch(getDepartmentsSuccess(result))
        }).catch(error => {
            dispatch(getDepartmentsFailure(error))
        });

        return promise;
    }
}