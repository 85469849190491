import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import moment from "moment";

import { MAIN_COLOR } from "../../constants/constants";

class TeamPage extends Component {
    componentWillMount() {
        const { fetchSessions } = this.props;
        fetchSessions();
    }

    onSubmit(e) {
        e.preventDefault();
    }

    renderSessions() {
        const { t, onChange, isFetching, sessions, history } = this.props;

        if (!isFetching) {
            if (sessions.length > 0) {
                return (
                    <div>
                        {sessions.map((session) => (
                            <a
                                key={session.id}
                                onClick={(e) => {
                                    onChange("selected", session);
                                    history.push("/scan");
                                }}
                            >
                                <li className="clickable">
                                    <div className="flex-container">
                                        <div className="three">{session.employee.email}</div>
                                        <div className="two">{session.employee.position.name}</div>
                                        <div className="two">
                                            {moment(session.experationDate.date).format("MMMM Do YYYY")}
                                        </div>
                                        <div className="one">
                                            <div
                                                className={
                                                    "status " +
                                                    (session.completed ? "completed" : "incomplete")
                                                }
                                            >
                                                {session.completedPercentage + "%"}
                                            </div>{" "}
                                        </div>
                                        <div className="two">
                                            <div
                                                className={
                                                    "status " +
                                                    (session.originalCompleted ? "completed" : "incomplete")
                                                }
                                            >
                                                {session.originalCompletedPercentage + "%"}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </a>
                        ))}
                    </div>
                );
            }
            return <li>{t("supervisor.sessions.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center big">{t("supervisor.sessions.header")}</h1>
                    <h2 className="center">{t("supervisor.sessions.description")}</h2>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="three">{t("supervisor.sessions.email")}</div>
                                    <div className="two">{t("supervisor.sessions.position")}</div>
                                    <div className="two">{t("supervisor.sessions.endDate")}</div>
                                    <div className="one">{t("supervisor.sessions.ownStatus")}</div>
                                    <div className="two">{t("supervisor.sessions.employeeStatus")}</div>
                                </div>
                            </li>
                            {this.renderSessions()}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
export default withTranslation("translation")(TeamPage);
