import * as types from '../constants/actionTypes.js';

const initialState = {
    isFetching: false,
    employees: [],
    page: 0,
    totalPages: 0,
    username: '',
    email: '',
    permission: 0,
};

export default function employees(state = initialState, action) {
    switch (action.type) {
        case types.EMPLOYEE_FORM_CHANGE_VALUE:
            return {
                ...state,
                error: '',
                [action.name]: action.value,
            };
        case types.EMPLOYEES_GET_START:
            state = {
                ...state,
                isFetching: true,
                employees: [],
            };
            return state;
        case types.EMPLOYEES_GET_SUCCESS:
            state = {
                ...state,
                isFetching: false,
                employees: action.employees,
            };
            return state;
        default:
            return state;
    }
}
