import * as types from '../constants/actionTypes.js';
import * as companyApi from '../api/companyApi';

/**
 * company actions
 */
export const changeValue = (name, value) => ({
    type: types.TEAM_FORM_CHANGE_VALUE,
    name,
    value
});

export const startAdd = () => ({
    type: types.TEAM_FORM_ADD_START
});

export const addSuccess = (result) => ({
    type: types.TEAM_FORM_ADD_SUCCESS,
    ...result
});

export const addFailure = (error) => ({
    type: types.TEAM_FORM_ADD_FAILURE,
    ...error
});

export const add = (name, company, members) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.addTeam(name, company, members);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const update = (id, name, company, members) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.updateTeam(id, name, company, members);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
};

export const remove = (id) => {
    return (dispatch) => {
        dispatch(startAdd());

        const promise = companyApi.deleteTeam(id);
        
        promise.then(result => {
            dispatch(addSuccess(result))
        }).catch(error => {
            dispatch(addFailure(error))
        });

        return promise;
    }
}

export const startGet = () => ({
    type: types.TEAM_GET_START
});

export const getSuccess = (result) => ({
    type: types.TEAM_GET_SUCCESS,
    team: result
});

export const getFailure = (error) => ({
    type: types.TEAM_GET_FAILURE,
    ...error
});

export const team = (id) => {
    return (dispatch) => {
        dispatch(startGet());

        const promise = companyApi.getTeam(id);
        
        promise.then(result => {
            dispatch(getSuccess(result))
        }).catch(error => {
            dispatch(getFailure(error))
        });

        return promise;
    }
}



export const startTeamsGet = () => ({
    type: types.TEAMS_GET_START
});

export const getTeamsSuccess = (result) => ({
    type: types.TEAMS_GET_SUCCESS,
    teams: [...result]
});

export const getTeamsFailure = (error) => ({
    type: types.TEAMS_GET_FAILURE,
    ...error
});

export const teams = (company) => {
    return (dispatch) => {
        dispatch(startTeamsGet());

        const promise = companyApi.getTeams(company);
        
        promise.then(result => {
            dispatch(getTeamsSuccess(result))
        }).catch(error => {
            dispatch(getTeamsFailure(error))
        });

        return promise;
    }
}

export const search = (company, query = '') => {
    return (dispatch) => {
        dispatch(startTeamsGet());

        const promise = companyApi.searchTeams(company, query);
        
        promise.then(result => {
            dispatch(getTeamsSuccess(result))
        }).catch(error => {
            dispatch(getTeamsFailure(error))
        });

        return promise;
    }
}