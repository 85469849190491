import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { PulseLoader } from "react-spinners";
import { Link } from "react-router-dom";

import Pager from "../ui/Pager";
import { MAIN_COLOR } from "../../constants/constants";

class Scans extends Component {
    state = {
        query: "",
    };
    componentDidMount() {
        this.load(0);
    }

    load(page = 0, query = "") {
        const { onChange, searchScans, fetchScans } = this.props;

        onChange("page", page);
        query != "" ? searchScans(query, page) : fetchScans(page);
    }

    renderScans() {
        const { t, isFetching, scans, deleteScan } = this.props;
        if (!isFetching) {
            if (scans.length > 0) {
                return (
                    <div>
                        {scans.map((scan) => (
                            <li key={scan.id}>
                                <div className="flex-container">
                                    <div className="four">{scan.name}</div>
                                    <div className="one">
                                        <Link to={"/edit/scan/" + scan.id}>{t("edit")}</Link>
                                    </div>
                                    <div
                                        className="one"
                                        onClick={() => {
                                            if (scan.canBeDeleted) {
                                                deleteScan(scan.id);
                                            }
                                        }}
                                    >
                                        <a>{scan.canBeDeleted ? t("delete") : ""}</a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </div>
                );
            }
            return <li>{t("scans.notFound")}</li>;
        } else {
            return (
                <li className="content-loader">
                    <PulseLoader className="spinner" color={MAIN_COLOR} size={25} />
                </li>
            );
        }
    }

    render() {
        const { t, page, scans } = this.props;
        return (
            <div className="container">
                <div className="box dash">
                    <h1 className="center">{t("scans.header")}</h1>
                    <Link to="/new/scan">
                        <button className="new">{t("scan.new")}</button>
                    </Link>
                    <div className="filter">
                        <div className="search">
                            <input
                                type="text"
                                placeholder={t("form.label.search")}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    this.setState({
                                        query: value,
                                    });
                                    this.load(this.state.query === "" ? 0 : page, value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="list">
                        <ul>
                            <li className="header">
                                <div className="flex-container">
                                    <div className="four">{t("scan.name")}</div>
                                    <div className="two"></div>
                                </div>
                            </li>
                            {this.renderScans()}
                        </ul>
                    </div>
                </div>
                <Pager
                    {...this.props}
                    items={scans}
                    onReload={(page) => {
                        this.load(page, this.state.query);
                    }}
                />
            </div>
        );
    }
}
export default withTranslation("translation")(Scans);
